import { Space, Table } from 'antd';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { formatDateToDisplay } from 'utils/utils';
const { Column } = Table;

type Props = {
	holidayData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	isLoading: boolean;
	showDeleteModal: any;
	setDeleteHolidayId: any;
	showAddHolidayManuallyModal: any;
};
dayjs.extend(isSameOrBefore);

const HolidayTable = (props: Props) => {
	const {
		holidayData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		setEditData,
		showAddHolidayManuallyModal,
		setDeleteHolidayId,
		showDeleteModal,
		isLoading,
	} = props;
	const permissions = useContext(PermissionContext);

	// Edit user data handler
	const editDataHandler = (holidayObj: any) => {
		setEditData(holidayObj);
		showAddHolidayManuallyModal();
	};

	// Delete user data handler

	const deleteDataHandler = (id: string) => {
		setDeleteHolidayId(id);
		showDeleteModal();
	};

	const isPastOrToday = (date: string) => {
		return dayjs(date).isSameOrBefore(dayjs(), 'day');
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={holidayData}
			  scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Date"
					dataIndex="holidayDate"
					key="holidayDate"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDateToDisplay(value);
					}}
					width="35%"
				/>
				<Column
					title="Holiday Name"
					dataIndex="holidayName"
					key="holidayName"
					className="bg-white"
					width="45%"
				/>
				<Column
					title="Action"
					key="action"
					width="20%"
					className="bg-white"
					render={(_: any, data: any) => (
						<Space size={20}>
							{permissions.allowedPermissions.includes(
								'Edit_Holidays'
							) && !isPastOrToday(data.holidayDate) ? (
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => editDataHandler(data)}
								>
									<EditActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed  flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</div>
							)}
							{permissions.allowedPermissions.includes(
								'Delete_Holidays'
							) && !isPastOrToday(data.holidayDate) ? (
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => deleteDataHandler(data.id)}
								>
									<DeleteActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</div>
							)}
						</Space>
					)}
				/>
			</Table>
		</div>
	);
};

export default HolidayTable;
