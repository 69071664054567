import { Button, Form, Input, List, Select } from 'antd';
import { getApi, postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import AddModal from '../AddModal';
import { useDispatch, useSelector } from 'react-redux';
import { currencyData } from 'constants/CurrencyData';
import { CloseOutlined } from '@ant-design/icons';
import { toastText } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import { AppDispatch } from 'redux/store';

const AddPayGroupBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>();

	const {
		closeDrawerByAnimation,
		fetchPaygroup,
		editSelected,
		branchOptions,
	} = props;

	const [selectedBranch, setSelectedBranch] = useState<string[]>(
		editSelected?.branches || []
	);
	const [selectedDepartment, setSelectedDepartment] = useState<any[]>(
		editSelected?.departments || []
	);
	const [payGroupName, setPayGroupName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>();
	const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = useState<any>(
		editSelected ? editSelected?.currency : null
	);
	const [isEmployeesLoading, setIsEmployeesLoading] =
		useState<boolean>(false);
	const [employees, setEmployees] = useState<any[]>(
		editSelected?.employees || []
	);

	const [totalEmployees, setTotalEmployees] = useState<number>(0);
	const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);
	const [selectedEmployee, setSelectedEmployee] = useState<string[]>([]);
	const dispatch = useDispatch<AppDispatch>();
	const [payGroupEmployees, setPayGroupEmployees] = useState<any[]>(
		editSelected?.employees || []
	);

	const buttons = [
		{
			text: `${editSelected ? 'Edit' : 'Submit'}`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			// onclick: () => handleSubmit(),
		},
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
	];

	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = constantDropdownOptions?.currencyData?.map(
		(currency: any) => ({
			value: currency.value,
			label: `${currency.label} - ${
				currencyData[currency.label] || currency.label
			}`,
		})
	);

	
	const handleSubmit = async (values: any) => {
		setLoading(true);

		if (editSelected) {
			try {
				let data: any = {
					payGroupId: editSelected?.id,
					name: values.name,
					employeeIds: payGroupEmployees.map(
						(employee) => employee.id
					),
				};
				const response = await postApi('/payGroup/update', data);

				if (response && response.data) {
					closeDrawerByAnimation();
					fetchPaygroup();
					toastText('Pay Group updated successfully', 'success');
				}
			} catch (error) {
				toastText('Someting went wrong in updating pay group', 'error');
				console.log('Error updating pay group:', error);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				if (selectedCurrency) {
					setIsSelected(true);
					setPayGroupName(values.name);
					setIsModalOpen(true);
					await fetchEmployeeData();
				} else {
					selectedCurrency
						? setIsSelected(true)
						: setIsSelected(false);
				}
			} catch (error) {
				console.log('Error handling new pay group submission:', error);
			} finally {
				setLoading(false);
			}
		}
	};

	const onFinishFailed = () => {
		if (selectedCurrency) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const fetchDepartmentCodes = async (branchIds: string[]) => {
		setIsLoading(true);
		try {
			const allDepartments: any[] = [];

			await Promise.all(
				branchIds.map(async (branchId) => {
					const branch = branchOptions.find(
						(b: any) => b.id === branchId
					);
					const apiRes: any = await getApi(`/department/${branchId}`);

					if (apiRes.data.data) {
						const departments = apiRes.data.data.map(
							(department: any) => ({
								id: department.id,
								name: `${branch?.name} - ${department.name}`,
							})
						);
						allDepartments.push(...departments);
					}
				})
			);

			const uniqueDepartments = allDepartments.reduce(
				(acc: any[], current: any) => {
					if (!acc.some((dept) => dept.id === current.id)) {
						acc.push(current);
					}
					return acc;
				},
				[]
			);

			setDepartmentOptions(uniqueDepartments);
		} catch (err) {
			console.log('🚀 ~ fetchDepartmentCodes ~ err:', err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (selectedBranch.length > 0) {
			fetchDepartmentCodes(selectedBranch);
		}
	}, [selectedBranch]);

	const fetchEmployeeData = async () => {
		setIsEmployeesLoading(true);
		try {
			const query = {
				currencyId: selectedCurrency || '',
				branchIds: selectedBranch,
				departmentIds: selectedDepartment,
			};

			const response = await getApi('/payGroup/employee', query);
			const employeeData = response.data.data || [];
			setEmployees(employeeData);
			setTotalEmployees(employeeData.length);
			const options = employeeData.map((employee: any) => ({
				value: employee.id,
				label: `${employee.employeeId} - ${employee.firstName} ${employee.lastName} `,
			}));
			setEmployeeOptions(options);
		} catch (err) {
			console.log('🚀 ~ fetchEmployeeData ~ err:', err);
		} finally {
			setIsEmployeesLoading(false);
		}
	};

	const fetchPayGroupEmployees = async () => {
		if (editSelected && editSelected.employees) {
			try {
				const data = { employeeIds: editSelected.employees };
				const result = await postApi('/payGroup/empId/', data);
				setPayGroupEmployees(result.data.data); // Set the state for displaying employees
			} catch (err) {
				console.error('Error fetching pay group employees:', err);
			}
		}
	};

	const handleRemoveEmployee = (employeeId: string) => {
		setEmployees((prevEmployees) =>
			prevEmployees.filter(
				(employee) => employee.employeeId !== employeeId
			)
		);
		setPayGroupEmployees((prevEmployees) =>
			prevEmployees.filter(
				(employee) => employee.employeeId !== employeeId
			)
		);

		setSelectedEmployee((prevSelected) =>
			prevSelected.filter((id) => id !== employeeId)
		);
		setTotalEmployees((prevTotal) => prevTotal - 1);
	};

	const handleEmployeeSelect = (employeeIds: string[]) => {
		const selectedEmployees = employees.filter((employee) =>
			employeeIds.includes(employee.id)
		);

		setPayGroupEmployees((prevEmployees) => [
			...prevEmployees,
			...selectedEmployees.filter(
				(selectedEmployee) =>
					!prevEmployees.some(
						(employee) => employee.id === selectedEmployee.id
					)
			),
		]);
	};

	useEffect(() => {
		if (editSelected) {
			fetchEmployeeData();
		}
	}, [editSelected]);

	useEffect(() => {
		if (editSelected) {
			fetchPayGroupEmployees();
		}
	}, [editSelected]);

	const getFilteredEmployeeOptions = () => {
		return employeeOptions.filter(
			(option) => !selectedEmployee.includes(option.value)
		);
	};
	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);


	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					initialValues={editSelected}
					onFinishFailed={onFinishFailed}
					onFinish={handleSubmit}
					autoComplete="off"
				>
					
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Pay Group Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Pay group name required',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item name={'currency'} wrapperCol={{ span: 24 }}>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Currency'}{' '}
								{true && (
									<span className="required-color">*</span>
								)}
							</label>
							<Select
								placeholder="Select Currency"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={editSelected ? true : false}
								value={selectedCurrency}
								onSelect={(currency) =>
									setSelectedCurrency(currency)
								}
								options={mappedCurrencyOptions}
							></Select>
							{isSelected === false && (
								<p className="ant-form-item-explain-error">
									Currency required
								</p>
							)}
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item name={'branchId'} wrapperCol={{ span: 24 }}>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Branch'}{' '}
							</label>

							<Select
								mode="multiple"
								placeholder="Select Branch"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={editSelected ? true : false}
								value={selectedBranch}
								onChange={(branches) => {
									setSelectedBranch(branches);
									setSelectedDepartment([]);
									setIsSelected(true);
								}}
							>
								{branchOptions?.map(
									(branch: any, key: number) => {
										return (
											<Select.Option
												value={branch?.id}
												key={key}
											>
												{branch?.name}
											</Select.Option>
										);
									}
								)}
							</Select>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item
							name={'department'}
							wrapperCol={{ span: 24 }}
						>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Department'}
							</label>
							{isLoading ? ( // Check if departments are loading
								<Select
									placeholder="Loading departments..." // Placeholder text while loading
									className={
										styles['side-drawer-form--select']
									}
									size="large"
									loading={isLoading} // Shows loading spinner
									disabled={true} // Disabled while loading
								/>
							) : (
								<Select
									mode="multiple"
									placeholder="Select Department"
									className={
										styles['side-drawer-form--select']
									}
									size="large"
									value={selectedDepartment}
									onChange={(departments) =>
										setSelectedDepartment(departments)
									}
									disabled={
										selectedBranch.length === 0 ||
										!!editSelected
									} // Remains disabled in edit mode
								>
									{departmentOptions?.map(
										(branch: any, key: number) => {
											return (
												<Select.Option
													value={branch?.id}
													key={key}
												>
													{branch?.name}
												</Select.Option>
											);
										}
									)}
								</Select>
							)}
						</Form.Item>
					</div>

					{editSelected && (
						<div className={styles['side-drawer-form__inputs']}>
							<Form.Item
								name={'employee'}
								wrapperCol={{ span: 24 }}
							>
								<label
									className={
										styles['side-drawer-form__role--label']
									}
								>
									{'Select Employee'}
								</label>
								<Select
									mode="multiple"
									placeholder="Search/Add Employee"
									className={
										styles['side-drawer-form--select']
									}
									size="large"
									loading={isEmployeesLoading}
									value={selectedEmployee}
									onChange={(employeeIds) => {
										setSelectedEmployee(employeeIds);
										handleEmployeeSelect(employeeIds);
									}}
									tagRender={(props) => (
										<span style={{ display: 'none' }}>
											{props.label}
										</span>
									)}
									showArrow
									showSearch
									notFoundContent="No employees available"
									optionLabelProp="label"
									disabled={employeeOptions.length === 0}
									dropdownRender={(menu) => (
										<div style={{ padding: 8 }}>
											{employeeOptions.length > 0 ? (
												<List
													dataSource={getFilteredEmployeeOptions()}
													renderItem={(item) => (
														<List.Item
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
															}}
															actions={[
																<Button
																	type="default"
																	onClick={() => {
																		handleEmployeeSelect(
																			[
																				item.value,
																			]
																		);
																		setEmployeeOptions(
																			(
																				prev
																			) =>
																				prev.filter(
																					(
																						option
																					) =>
																						option.value !==
																						item.value
																				)
																		);
																	}}
																	style={{
																		borderColor:
																			'#584495',
																		color: '#584495',
																	}}
																>
																	Add
																</Button>,
															]}
														>
															{item.label}
														</List.Item>
													)}
												/>
											) : (
												<div
													style={{
														textAlign: 'center',
														padding: '8px 0',
													}}
												>
													No employees available
												</div>
											)}
										</div>
									)}
								/>
							</Form.Item>
						</div>
					)}

					{editSelected && (
						<div className={styles['side-drawer-form__inputs']}>
							<div
								style={{
									marginBottom: '8px',
									fontWeight: 'bold',
								}}
							>
								Total number of Employees:{' '}
								{payGroupEmployees.length}
							</div>
							<List
								dataSource={payGroupEmployees}
								renderItem={(item) => (
									<List.Item
										actions={[
											<Button
												type="text"
												icon={
													<CloseOutlined
														style={{
															color: '#d9d9d9',
														}}
													/>
												}
												onClick={() =>
													handleRemoveEmployee(
														item.employeeId
													)
												}
											/>,
										]}
									>
										{item.firstName} {item.lastName} -{' '}
										<span style={{ color: '#584495' }}>
											{item.employeeId}
										</span>
									</List.Item>
								)}
							/>
						</div>
					)}
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
			<AddModal
				isModalOpen={isModalOpen}
				onClose={handleModalClose}
				currency={
					mappedCurrencyOptions?.find(
						(option: any) => option.value === selectedCurrency
					) || {}
				}
				branches={branchOptions.filter((branch: any) =>
					selectedBranch.includes(branch.id)
				)}
				departments={departmentOptions.filter((department: any) =>
					selectedDepartment.includes(department.id)
				)}
				totalEmployees={totalEmployees}
				employees={employees}
				isEmployeesLoading={isEmployeesLoading}
				onRemoveEmployee={handleRemoveEmployee}
				payGroupName={payGroupName}
				fetchPaygroup={fetchPaygroup}
				closeDrawerByAnimation={closeDrawerByAnimation}
			/>
		</>
	);
};

export default AddPayGroupBody;
