import { Space, Table } from 'antd';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

const { Column } = Table;

type Props = {
	DataSource: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	openDrawerHandler: any;
	setDrawerInfoHandler: any;
	showModal: any;
	isLoading: boolean;
};

const CostCenterTable = (props: Props) => {
	const {
		DataSource,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditData,
		showModal,
		isLoading,
	} = props;

		const permissions = useContext(PermissionContext);


	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit Cost Center');
		setEditData(selectedObj);
	};

	// Delete user data handler
	const deleteDataHandler = (data: any) => {
		setEditData(data);
		showModal();
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={DataSource}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Cost Code"
					dataIndex="code"
					key="code"
					sorter={true}
					className="bg-white"
				/>
				<Column
					title="Cost Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					sorter={true}
				/>
				<Column
					title="Department Code"
					dataIndex="departmentCode"
					key="departmentCode"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="Department Name"
					dataIndex="departmentName"
					key="departmentName"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="Branch Code"
					dataIndex="branchCode"
					key="branchCode"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="Branch Name"
					dataIndex="branchName"
					key="branchName"
					className="bg-white"
					// sorter={true}
				/>
				{(permissions.allowedPermissions.includes('Edit_Cost_Center') ||
					permissions.allowedPermissions.includes(
						'Delete_Cost_Center'
					)) && (
					<Column
						title="Action"
						key="action"
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								{permissions.allowedPermissions.includes(
									'Edit_Cost_Center'
								) ? (
									<div
										className="cursor-pointer  flex align-center justify-center"
										onClick={() => editDataHandler(data)}
									>
										<EditActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed  flex align-center justify-center">
										<EditActionSvg color="#928F8F" />
									</div>
								)}
								{permissions.allowedPermissions.includes(
									'Delete_Cost_Center'
								) ? (
									<div
										className="cursor-pointer flex align-center justify-center"
										onClick={() => deleteDataHandler(data)}
									>
										<DeleteActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed flex align-center justify-center">
										<DeleteActionSvg color="#928F8F" />
									</div>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default CostCenterTable;
