import EmployeeApproval from 'components/Settings/Approval/Employee/EmployeeApproval';
import TaxationApproval from 'components/Settings/Approval/Employee/TaxationApproval';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { Tabs } from 'antd';

const EmployeeComponent = () => {
	const [activeKey, setActiveKey] = useState('employmentDetails');

	const items = [
		{
			key: 'employmentDetails',
			label: 'Employment Details',
			children: <EmployeeApproval />,
		},
		{
			key: 'taxationDetails',
			label: 'Taxation Details',
			children: <TaxationApproval />,
		},
	];
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	return (
		<div>
			<div className={styles['employee']}>
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						items={items}
						onChange={(key) => changeTab(key)}
						style={{
							fontSize: '18px',
							marginBottom: '4px',
						}}
					/>
			</div>
		</div>
	);
};

export default EmployeeComponent;
