import { timeLogsApi } from 'Api/timeLogs';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { AddSvg, CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../redux/actions/constantDropdownAction';
import TimeLogsTableHeader from './Table/Header';
import PendingTimeLogs from './Table/PendingTimeLogsTable';
import TimeLogsTable from './Table/TimeLogsTable';
import styles from './index.module.scss';
import { shiftsApi } from 'Api/shifts';

const TimeLogsComponent = () => {
	const buttons = [
		{
			text: 'Add Time Log',
			isLoading: false,
			className: 'btn-blue',
			icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsAddModalOpen(true);
				// openDrawerHandler();
			},
			disabled: false,
		},
	];

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { isLoading: dropdownOptionsLoading } = useSelector(
		(state: any) => state.constantDropdown
	);
	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState([dayjs().startOf('month'), dayjs()]);
	const [isFetchTimeLogsLoading, setIsFetchTimeLogsLoading] = useState(false);
	const [statusFilterValue, setStatusFilterValue] = useState('');
	const [selectedShift, setSelectedShift] = useState('');
	const [timeActivities, setTimeActivities] = useState([]);
	const [shiftOptions, setShiftOptions] = useState([]);
	const [isFetchShiftsLoading, setIsFetchShiftsLoading] = useState(false);

	const [pendingDate, setPendingDate] = useState(dayjs(new Date()));

	const [activeKey, setActiveKey] = useState('timeLogs');

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		if (sorter.field === 'submittedOn') {
			setSortField('createdAt');
		} else {
			setSortField(sorter.field);
		}
	};

	const handleStatusFilter = (value: string) => {
		setStatusFilterValue(value);
	};
	const handleSelectedShift = (value: string) => {
		setSelectedShift(value);
	};

	const fetchTimeLogs = async () => {
		setIsFetchTimeLogsLoading(true);
		try {
			const query = {
				supervisorId: statusFilterValue,
				startDate: date[0].format('YYYY-MM-DD'),
				endDate: date[1].format('YYYY-MM-DD'),
				page: currentPage,
				pageSize: pageSize,
				sortBy: sortField,
				sortOrder: sortOrder,
			};

			const response = await timeLogsApi.getTimeActivities(query);
			setTimeActivities(response.data.data);
			setTotalRecords(response.data.count);
		} catch (err: any) {
			const message =
				err.response?.data?.message ||
				'Something went wrong in fetching time logs';
			toastText(message, 'error');
		}
		setIsFetchTimeLogsLoading(false);
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	useEffect(() => {
		fetchTimeLogs();
	}, [date, statusFilterValue, pageSize, currentPage, sortField, sortOrder]);

	let items = [
		{
			key: 'timeLogs',
			label: 'Time Logs',
			children: (
				<TimeLogsTable
					tableData={timeActivities}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					tableChangeHandler={tableChangeHandler}
					isLoading={isFetchTimeLogsLoading}
				/>
			),
		},
		{
			key: 'pendingTimeLogs',
			label: 'Pending Time Logs',
			children: (
				<PendingTimeLogs  supervisorId={statusFilterValue}  selectedShift={selectedShift}/>
			),
		},
	];

	useEffect(() => {
		fetchShiftsMonthWise();
	}, [pendingDate]);

	const fetchShiftsMonthWise = async () => {
		const query = {
			month: dayjs(pendingDate).format('MM'),
			year: dayjs(pendingDate).format('YYYY'),
		};
		try {
			setIsFetchShiftsLoading(true);
			const shiftApiRes = await shiftsApi.getShifts(query);

			let mappedShifts = shiftApiRes?.data?.data?.map((shift: any) => ({
				value: shift.id,
				label: shift.shiftName,
			}));
			setShiftOptions(mappedShifts);
			setSelectedShift(mappedShifts[0]?.value);
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in fetching shifts';
			toastText(message, 'error');
		}
		setIsFetchShiftsLoading(false);
	};
	return (
		<>
			<div className={styles['time-logs']}>
				<div className={styles['time-logs__header']}>
					<div className={styles['time-logs__header__title']}>
						Time Logs
					</div>
					<div
						className={styles['time-logs__header__actions']}
						onClick={() => {
							navigate('/time-activities');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['time-logs__table']}>
					<TimeLogsTableHeader
						statusFilterValue={statusFilterValue}
						handleStatusFilter={handleStatusFilter}
						options={dropdownOptions?.supervisors}
						handleSelectedShift={handleSelectedShift}
						selectedShift={selectedShift}
						shiftOptions={shiftOptions}
						button={buttons}
						handleDatePicker={handleDatePicker}
						dateValue={date}
						dropdownOptionsLoading={dropdownOptionsLoading}
						activeKey={activeKey}
						pendingDateValue={pendingDate}
						handlePendingDate={(value: any) =>
							setPendingDate(value)
						}
						isFetchShiftsLoading={isFetchShiftsLoading}
					/>
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						items={items}
						onChange={(key) => changeTab(key)}
						style={{
							fontSize: '18px',
							marginBottom: '0px',
							padding: '1.5rem',
						}}
					/>
					{/* <TimeLogsTable
						tableData={timeActivities}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						tableChangeHandler={tableChangeHandler}
						isLoading={isFetchTimeLogsLoading}
					></TimeLogsTable> */}
				</div>
			</div>
			{/* {isAddModalOpen && (
				<AddModal
					handleCancel={() => setIsAddModalOpen(false)}
					isOpen={isAddModalOpen}
					fetchTimeActivities={fetchTimeLogs}
				/>
			)} */}
		</>
	);
};

export default TimeLogsComponent;
