import { Col, Form, Modal, Row } from 'antd';
import { postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import SelectDropdown from 'components/Global/SelectDropdown';
import TextareaFieldInput from 'components/Global/textareaField';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import { AddLeaveRequestProps } from './type';
import DatePickerField from 'components/Global/DatePicker';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload';
import './index.scss';
import { leaveManagementApi } from 'Api/masters/leave-management';
import { leaveRequestApi } from 'Api/leaveRequest';
import { holidayApi } from 'Api/masters/holiday';

const AddLeaveRequestModal: React.FC<AddLeaveRequestProps> = (props) => {
	const {
		handleCancel,
		isAddLeaveRequestModalOpen,
		fetchLeaveRequest,
		edit,
		setEdit,
	} = props;

	const dispatch = useDispatch<AppDispatch>();

	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [employeeMaxLeaves, setEmployeeMaxLeaves] = useState(0);
	const [leaveRequestDetails, setleaveRequestDetails] = useState<any>({
		employeeId: null,
		startDate: null,
		endDate: null,
		reason: null,
		leaveId: null,
	});

	const [formError, setFormError] = useState<any>({
		employeeId: false,
		startDate: false,
		endDate: false,
		reason: false,
		leaveId: false,
	});

	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [leaveDays, setLeaveDays] = useState<number>(0);
	const [leaveTypeDropdown, setLeaveTypeDropdown] = useState<any>([]);
	const [leaveLoading, setLeaveLoading] = useState(false);
	const [maxLeaves, setMaxLeaves] = useState(0);
	const [attachmentRequired, setAttachmentRequired] = useState(false);
	const [holidays, setHolidays] = useState<any[]>([]);
	const [fetchedFile, setFetchedFile] = useState<any>(null);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		console.log('edit: ', edit);
		if (edit) {
			const editData = {
				startDate: dayjs(edit.startDate),
				endDate: dayjs(edit.endDate),
				reason: edit.reason,
				status: edit.status,
				employeeId: edit?.employeeId,
				leaveId: edit.leaveId,
				noOfDays: edit.noOfDays,
			};

			if (edit.documentName) {
				const file = {
					name: edit.documentName,
				};
				setFileList([file]);
				setFetchedFile(file);
			} else {
				setFileList([]);
				setFetchedFile(null);
			}

			setleaveRequestDetails(editData);
			countNoOfDays(editData.startDate, editData.endDate);
			fetchLeaveType(edit.employeeId);
		}
	}, [edit]);

	useEffect(() => {
		if (edit && leaveTypeDropdown.length > 0) {
			const _maxLeaves = leaveTypeDropdown.find(
				(leave: any) => leave.value === edit.leaveId
			);
			setMaxLeaves(_maxLeaves?.totalLeaves);
			setEmployeeMaxLeaves(_maxLeaves?.maxLeaves);
			setAttachmentRequired(_maxLeaves?.attachmentRequired);
		}
	}, [leaveTypeDropdown, edit]);

	// useEffect(() => {
	// 	getHolidays();
	// }, []);

	// const getHolidays = async () => {
	// 	try {
	// 		const currentYear = dayjs().year();
	// 		console.log(currentYear);
	// 		const response = await holidayApi.getHolidaysForCurrentYear(
	// 			currentYear
	// 		);
	// 		console.log(response);

	// 		setHolidays(response.data.data);
	// 	} catch (err) {
	// 		toastText('Failed to fetch holidays.', 'error');
	// 	}
	// };

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...leaveRequestDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			// const startDate = dayjs(leaveRequestDetails.startDate).startOf(
			// 	'day'
			// );
			// const endDate = dayjs(leaveRequestDetails.endDate).startOf('day');

			// const overlappingHolidays = holidays.filter((holiday) => {
			// 	const holidayDate = dayjs(holiday.holidayDate).startOf('day');
			// 	return (
			// 		holidayDate.isSame(startDate, 'day') ||
			// 		holidayDate.isSame(endDate, 'day') ||
			// 		(holidayDate.isAfter(startDate) &&
			// 			holidayDate.isBefore(endDate))
			// 	);
			// });

			// const isHolidayOverlap = overlappingHolidays.length > 0;

			// if (isHolidayOverlap) {
			// 	const overlappingDates = overlappingHolidays
			// 		.map((holiday) =>
			// 			dayjs(holiday.holidayDate).format('DD-MM-YYYY')
			// 		)
			// 		.join(', ');

			// 	toastText(
			// 		`Leave dates fall on a holiday (${overlappingDates}). Please choose different dates.`,
			// 		'error'
			// 	);
			// 	return;
			// }
			if (attachmentRequired && !file && !edit) {
				toastText('Attachment is required', 'error');
				return;
			}

			const leaveFormData = new FormData();
			leaveFormData.append('employeeId', leaveRequestDetails.employeeId);
			leaveFormData.append(
				'startDate',
				formatToDateOnly(leaveRequestDetails.startDate)
			);
			leaveFormData.append(
				'endDate',
				formatToDateOnly(leaveRequestDetails.endDate)
			);
			leaveFormData.append('reason', leaveRequestDetails.reason);
			leaveFormData.append('leaveId', leaveRequestDetails.leaveId);
			leaveFormData.append('noOfDays', leaveDays as any);
			leaveFormData.append('employeeMaxLeaves', employeeMaxLeaves as any);
			leaveFormData.append('moduleName', 'LEAVE_REQUEST');
			if (edit) {
				if (
					fileList[0] &&
					(!fetchedFile || fileList[0].name !== fetchedFile.name)
				) {
					leaveFormData.append('file', fileList[0]);
				}
			} else {
				leaveFormData.append('file', file);
			}

			setIsLoading(true);
			try {
				let response;
				if (edit) {
					response = await leaveRequestApi.updateLeaveRequest(
						edit.id,
						leaveFormData
					);
				} else {
					response = await leaveRequestApi.createLeaveRequest(
						leaveFormData
					);
				}

				fetchLeaveRequest();
				let message = edit
					? 'Leave Request has been updated successfully'
					: 'Leave Request has been requested successfully';

				toastText(message, 'success');
				closeModal();
			} catch (err: any) {
				let message;
				console.log('err: ', err);
				if (err.response?.data?.error?.code === 103) {
					message = err.response.data.message;
				} else {
					message = `Something went wrong in ${
						edit ? 'Updating' : 'Creating'
					} Leave Request`;
				}

				toastText(message, 'error');
			}
			setIsLoading(false);
		}
	};

	const closeModal = () => {
		setleaveRequestDetails({
			employeeId: null,
			startDate: null,
			endDate: null,
			reason: null,
			leaveId: null,
		});
		setEmployeeMaxLeaves(0);
		setLeaveTypeDropdown([]);
		setFormError({
			employeeId: false,
			startDate: false,
			endDate: false,
			reason: false,
			leaveId: false,
		});
		setEdit(null);
		setFile(null);
		setLeaveDays(0);
		setFileList([]);
		setMaxLeaves(0);
		setAttachmentRequired(false);
		handleCancel();
	};

	const fetchLeaveType = async (employeeId: string) => {
		try {
			setLeaveLoading(true);
			const response = await leaveManagementApi.getLeaveTypeByEmployeeId(
				employeeId
			);
			setLeaveTypeDropdown(response?.data.data);
		} catch (err: any) {
		} finally {
			setLeaveLoading(false);
		}
	};
	const countNoOfDays = (startDate: any, endDate: any) => {
		if (startDate && endDate) {
			const _startDate = new Date(startDate);
			const _endDate = new Date(endDate);
			_startDate.setHours(0, 0, 0, 0);
			_endDate.setHours(0, 0, 0, 0);

			const diffDays = Math.round(
				Math.abs(_startDate.getTime() - _endDate.getTime()) /
					(1000 * 60 * 60 * 24) +
					1
			);
			setLeaveDays(diffDays);
			return diffDays;
		} else {
			setLeaveDays(0);
			return 0;
		}
	};

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		if (name === 'employeeId') {
			setLeaveTypeDropdown([]);
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					leaveId: '',
					startDate: '',
					endDate: '',
				};
				return newDetails;
			});

			fetchLeaveType(value as string);
		}

		if (name === 'startDate') {
			// if (leaveRequestDetails.endDate) {
			// 	const leaveCount = countNoOfDays(
			// 		value,
			// 		leaveRequestDetails.endDate
			// 	);
			// 	console.log(
			// 		'value! > leaveRequestDetails.endDate: ',
			// 		value! > leaveRequestDetails.endDate
			// 	);
			// 	if (
			// 		leaveCount > maxLeaves  &&
			// 		!(value! > leaveRequestDetails.endDate)
			// 	) {
			// 		setleaveRequestDetails((prev: any) => {
			// 			const newDetails = {
			// 				...prev,
			// 				endDate: dayjs(value).add(maxLeaves - 1, 'day'),
			// 			};
			// 			return newDetails;
			// 		});
			// 	}
			// }
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					endDate: null,
				};
				return newDetails;
			});
		}

		if (name === 'leaveId') {
			const leaveType = leaveTypeDropdown.find(
				(leave: any) => leave.value === value
			);
			setMaxLeaves(leaveType?.totalLeaves);
			setEmployeeMaxLeaves(leaveType?.maxLeaves || 0);
			setAttachmentRequired(leaveType?.attachmentRequired || false);
			setLeaveDays(0);
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					startDate: '',
					endDate: '',
				};
				return newDetails;
			});
		}
		OnChange(value, name);
	};

	const OnChange = (value: string | number | null, key: string) => {
		setleaveRequestDetails((prev: any) => {
			const newDetails = {
				...prev,
				[key]: value,
			};
			if (key === 'startDate' || key === 'endDate') {
				countNoOfDays(newDetails.startDate, newDetails.endDate);
			}
			return newDetails;
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};
	let isRemoving = false;

	const propsUpload = {
		name: 'file',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}

			const isLt1M = file.size! / 1024 / 1024 < 1;
			if (!isLt1M) {
				toastText('File must be smaller than 1MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false;
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};
	const CustomDragger = (
		<Dragger {...propsUpload} className="test">
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-hint">
				Drag and drop a file or choose file from Device.
				<br />{' '}
				<span className="color-purple">Maximum file size: 1MB</span>
			</p>
		</Dragger>
	);

	const myButtons = [
		{
			text: edit ? 'Update' : 'Request',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			disabled: leaveLoading,
			onclick: () => {
				handleSubmit();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
	];

	return (
		<Modal
			open={isAddLeaveRequestModalOpen}
			onOk={handleSubmit}
			onCancel={closeModal}
			okText={'Save'}
			closable={false}
			width={800}
			footer={null}
			title={<h2>{edit ? 'Edit Leave Request' : 'Add Leave Request'}</h2>}
			className="addLeaveRequestModal"
		>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<Row className={styles['form-container-card']} gutter={50}>
				<Col span={12} className={styles['col']}>
					<SelectDropdown
						placeholder="Employee Name"
						options={
							constantDropdownOptions?.employees
								? constantDropdownOptions?.employees
								: []
						}
						value={leaveRequestDetails?.employeeId}
						onChange={(value: any) =>
							handleChange(value, 'employeeId', true)
						}
						size="large"
						required={true}
						helperText="Employee Name required"
						label="Employee Code | Employee Name"
						isError={formError.employeeId}
					/>
				</Col>
				<Col span={12} className={styles['col']}>
					<SelectDropdown
						placeholder="Leave Type"
						options={leaveTypeDropdown ? leaveTypeDropdown : []}
						value={leaveRequestDetails?.leaveId}
						disabled={leaveLoading}
						onChange={(value: any) =>
							handleChange(value, 'leaveId', true)
						}
						size="large"
						required={true}
						helperText="Leave Type required"
						label="Leave Type"
						isError={formError.leaveId}
						loading={leaveLoading}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="startDate"
						onChange={(value) => {
							handleChange(value, 'startDate', true);
						}}
						value={leaveRequestDetails.startDate}
						isError={formError.startDate}
						disabled={leaveLoading}
						required={true}
						helperText=" Start Date required"
						label="Start Date"
						// disabledBeforeDates={dayjs().startOf('day')}
						placeholder="Select Start Date"
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="endDate"
						onChange={(value) => {
							handleChange(value, 'endDate', true);
						}}
						value={leaveRequestDetails.endDate}
						isError={formError.endDate}
						required={true}
						label="End Date"
						helperText="End Date required"
						placeholder="Select End Date"
						disabled={
							(leaveRequestDetails.startDate ? false : true) ||
							leaveLoading
						}
						disabledBeforeDates={leaveRequestDetails.startDate}
						disabledAfterDates={dayjs(
							leaveRequestDetails.startDate
						).add(maxLeaves - 1, 'day')}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<div className={styles['noOfDay-container']}>
						<label className={styles['noOfDay-label']}>
							Number of Days: {leaveDays}
						</label>
					</div>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<TextareaFieldInput
						name="reason"
						value={leaveRequestDetails.reason}
						label="Reason"
						required={true}
						helperText="Reason required"
						onChange={(value) =>
							handleChange(value, 'reason', true)
						}
						isError={formError.reason}
					/>
				</Col>
			</Row>
			{attachmentRequired && (
				<div className={styles['modalUploadContainer']}>
					<label className={styles['modalUploadLabel']}>
						{'Attachment'}
						{!edit && attachmentRequired && (
							<span className={styles['requiredAsterisk']}>
								*
							</span>
						)}
					</label>
					<Form.Item required>{CustomDragger}</Form.Item>
				</div>
			)}

			<div className={styles['buttons']}>
				<Buttons buttons={myButtons} />
			</div>
		</Modal>
	);
};

export default AddLeaveRequestModal;
