import { DatePicker, Select } from 'antd';
import { months } from 'constants/Data';
import dayjs from 'dayjs';
import { on } from 'events';
import moment from 'moment';
import React, { useState } from 'react';

type Props = {
	onMonthChange: (month: number) => void;
	onYearChange: (year: any) => void;
	month: number;
	year: any;
};

const YearMonthPicker = (props: Props) => {
	const { onMonthChange, onYearChange,year,month } = props;

	return (
		<div>
			<label style={{ margin: '10px' }}>Year</label>
			<DatePicker
				picker="year"
				format="YYYY"
				style={{ width: 120 }}
				disabledDate={(current) => {
					return (
						current &&
						(current > moment().endOf('year') ||
							current <
								moment().subtract(7, 'years').startOf('year'))
					);
				}}
				onChange={(selectedYear) => {
					onYearChange(selectedYear);
				}}
				value={year}
				allowClear={false}
			/>

			<label style={{ margin: '10px' }}>Month</label>

			<Select
				showSearch
				placeholder="Select month"
				options={months.map((month) => ({
					value: month.value,
					label: month.label,
				}))}
				style={{ width: 120 }}
				onChange={(month) => {
					onMonthChange(month);
				}}
				value={month}
				optionFilterProp="label"
			/>
		</div>
	);
};

export default YearMonthPicker;
