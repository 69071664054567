import React, { useContext } from 'react';

import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import HolidayComponent from 'components/Settings/Masters/Holidays';

export const Holiday = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Holidays');

	return <div>{check && <HolidayComponent />}</div>;
};
