import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
// import AddLogsModal from '../AddLogsModal';
import styles from './index.module.scss';
import { useContext, useState } from 'react';
import EditCompanyModal from '../EditCompany';
import { currencyData } from 'constants/CurrencyData';
import { PermissionContext } from 'components/Global/AuthLayout';
type Props = {
	tableData: any;
	isLoading: boolean;
};

const CompanyTable = (props: Props) => {
	const { tableData, isLoading } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState<any>(null);
	const [edit, setEdit] = useState<any>(null);

	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Company_Setup');

	const isEdit =
		permissionContext.allowedPermissions.includes('Edit_Company_Setup');

	const showModal = (company: any) => {
		setSelectedCompany(company);
		setIsModalOpen(true);
	};

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const renderStatutoryDetails = (record: any) => {
		const details = (
			<div>
				<p>
					<strong>NHIMA:</strong> {record.CompanyDetails?.nihma}
				</p>
				<p>
					<strong>NAPSA:</strong> {record.CompanyDetails?.napsa}
				</p>
				<p>
					<strong>TPIN:</strong> {record.tpin}
				</p>
			</div>
		);

		return (
			<Tooltip title={details}>
				<Button type="link">View Details</Button>
			</Tooltip>
		);
	};

	// const renderCurrency = (record: any) => {
	// 	const baseCurrency = record.CompanyCurrency?.find(
	// 		(currency: any) => currency.isBaseCurrency
	// 	);
	// 	return baseCurrency ? baseCurrency.currency : 'N/A';
	// };
	const renderCurrency = (record: any) => {
		const baseCurrency = record.CompanyCurrency?.find(
			(currency: any) => currency.isBaseCurrency
		);
		return baseCurrency ? currencyData[baseCurrency.currency] : 'N/A';
	};

	const onCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				rowKey={(record: any) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Company Name"
					dataIndex="name"
					key="name"
					width={'25%'}
					className="bg-white"
				/>
				<Column
					title="Email Address"
					dataIndex="email"
					key="email"
					className="bg-white"
				/>
				<Column
					title="Statutory components"
					key="statutoryComponents"
					className="bg-white"
					render={(text: any, record: any) =>
						renderStatutoryDetails(record)
					}
				/>
				<Column
					title="Start of year"
					key="financialYear"
					className="bg-white"
					render={(text, record: any) =>
						monthNames[record.CompanyDetails?.financialYear - 1]
					}
				/>
				<Column
					title="Currency"
					key="currency"
					className="bg-white"
					render={(text, record: any) => renderCurrency(record)}
				/>

				<Column
					title="Action"
					dataIndex="action"
					key="action"
					className="bg-white"
					render={(record: any, data: any) => (
						<Space size={20}>
							{isAdd && isEdit && (
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => {
										console.log(data, 'single');

										setEdit(data);
										showModal(data);
									}}
								>
									<EditActionSvg />
								</div>
							)}
							<div className="cursor-pointer flex align-center justify-center">
								<EyeOutlined style={{ fontSize: '22px' }} />
							</div>
						</Space>
					)}
				/>
			</Table>
			{isModalOpen && (
				<EditCompanyModal
					isOpen={isModalOpen}
					onCancel={onCancel}
					edit={edit}
				/>
			)}
		</div>
	);
};

export default CompanyTable;
