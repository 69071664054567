import TableActionHeader from 'components/Global/TableActionHeader';
import './index.scss';

export const Dashboard = () => {
	return (
		<div>
			<TableActionHeader></TableActionHeader>
		</div>
	);
};
