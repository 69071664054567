import TableActionHeader from 'components/Global/TableActionHeader';
import ConfigurationLayout from 'layouts/Configuration';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getAllowedModule } from 'utils/utils';
import { PermissionContext } from 'components/Global/AuthLayout';
import { keyWiseConfigurationRouteMapping } from 'utils/mappings';

interface SelectedValue {
	key: string;
}

export default function Configuration() {
	const context = useContext(PermissionContext);
	const navigate = useNavigate();

	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];

	const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

	// Function to handle sidebar changes
	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	useEffect(() => {
		const allowedConfiguration = getAllowedModule(context.permissions, [
			'Company',
			'Branch Code',
			'Category',
			'Job Title',
			'Pay Point',
			'Departments',
			'Cost Center',
			'Job Grade',
			// 'Earning',
			// 'Deduction',
			'Holidays',
			'Leave Management',
		]);
		if (lastSegment === 'configuration') {
			if (allowedConfiguration) {
				const key =
					keyWiseConfigurationRouteMapping[
						allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
					];
				navigate(`/settings/configuration/${key}`);
			} else {
				navigate(`/`);
			}
		}
		setSelectedSidebar(lastSegment);
	}, [lastSegment]);

	return (
		<>
			<TableActionHeader title={'Configuration'} />
			<div className={styles['Configuration']}>
				<ConfigurationLayout
					onSideBarChange={sideBarChangeHandler}
					selectedSidebar={selectedSidebar}
				>
					<Outlet />
				</ConfigurationLayout>
			</div>
		</>
	);
}
