import { Form, Input, Row, Col, Radio, Button } from 'antd';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';

type Props = {};

const BankTransfer = (props: Props) => {
	const [form] = Form.useForm();

	const renderLabel = (label: string, required: boolean) => (
		<span>
			{label} {required && <span className={styles['required']}>*</span>}
		</span>
	);

	const buttons = [
		{
			text: 'Save',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: '',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				console.log('Clicked cancel');
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	return (
		<div className={styles.form}>
			<div className={styles['form-container']}>
				{/* <p className={styles['form-container-title']}>Bank Transfer</p> */}
				<Form
					form={form}
					name="dependencies"
					autoComplete="off"
					layout="vertical"
					requiredMark={false}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								label={renderLabel('Account Holder Name', true)}
								name="accountHolderName"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message:
											'Account Holder Name is required',
									},
									{
										pattern: /^[A-Za-z\s]+$/,
										message:
											'Account Holder Name must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={renderLabel('Bank Name', true)}
								name="bankName"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Bank Name is required',
									},
									{
										pattern: /^[A-Za-z\s]+$/,
										message: 'Bank Name must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel('Account Number', true)}
								name="accountNumber"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Account Number is required',
									},
									{
										pattern: /^\d+$/,
										message: 'Account Number must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel(
									'Re-enter Account Number',
									true
								)}
								name="reAccountNumber"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Account Number is required',
									},
									{
										pattern: /^\d+$/,
										message: 'Account Number must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel('Sort Code', true)}
								name="sortCode"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Sort Code is required',
									},
									{
										pattern: /^[A-Za-z0-9]+$/,
										message: 'Sort Code must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel('Account Type', true)}
							>
								<Radio.Group>
									<Radio
										style={{ marginTop: 15 }}
										value="current"
									>
										{' '}
										Current{' '}
									</Radio>
									<Radio value="savings"> Savings </Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default BankTransfer;
