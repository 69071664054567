import TimeLogsComponent from 'components/TimeLogs';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

export const TimeLogs = () => {
		const context = useContext(PermissionContext);
		const check = context.allowedPermissions.includes('Allow_Time_Logs');

	return <div>{check &&<TimeLogsComponent/>}</div>;
};
