import { useContext } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import BranchCodeComponent from 'components/Settings/Masters/BranchCode';
import './index.scss';

export const BranchCode = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Branch_Code');

	return <div>{check && <BranchCodeComponent />}</div>;
};
