import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';

type GlobalInformationModalProps = {
	open: boolean;
	InformationData: any;
	closeInformationModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	loading: boolean;
};

const GlobalInformationModal = (props: GlobalInformationModalProps) => {
	const {
		open,
		InformationData,
		closeInformationModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		loading,
	} = props;

	return (
		<Modal
			open={open}
			maskClosable={true}
			width={1000}
			footer={null}
			onCancel={closeInformationModal}
		>
			<Table
				dataSource={InformationData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={loading}
			>
				<Column
					title="Date"
					dataIndex="createdAt"
					key="createdAt"
					render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
					sorter={true}
					width={'33%'}
				/>
				<Column
					title="Title"
					dataIndex="docTitle"
					key="docTitle"
					width={'33%'}
				/>
				<Column
					title="Attachment"
					dataIndex="documentLink"
					key="documentLink"
					width={'33%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<a
									href={record.documentLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.documentName}
										maxLength={50}
										key={record.documentName}
										tooltipMessage={record.documentName}
										isTooltip={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</Modal>
	);
};

export default GlobalInformationModal;
