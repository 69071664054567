import {
	DeleteOutlined,
	DollarOutlined,
	EditOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import { Space, Table } from 'antd';
import styles from './index.module.scss';
import { EmployeeTableInterface } from 'interfaces/employee.interface';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import { useContext, useState } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import Ellipse from 'components/Global/Ellipse';

const { Column } = Table;

type Props = {
	companyData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	openDrawerHandler: any;
	setDrawerInfoHandler: any;
	showModal: any;
	isLoading: boolean;
	setVisible: (value: boolean) => void;
};

const CompanyTable = (props: Props) => {
	const {
		companyData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditData,
		showModal,
		isLoading,
		setVisible,
	} = props;

	const permissions = useContext(PermissionContext);

	//const [isModalVisible, setIsModalVisible] = useState(false);

	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit Company');
		setEditData(selectedObj);
	};

	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditData(userObject);
		showModal();
	};

	const handleCurrencyModel = () => {
		setVisible(true);
	};
	// Method to close dollar modal
	const handleCancel = () => {
		//setIsModalVisible(false); // Set state to hide modal
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={companyData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Company Name"
					dataIndex="name"
					key="name"
					width={'25%'}
					sorter={true}
					className="bg-white"
				/>
				<Column
					title="Company Address"
					// dataIndex="address"
					key="address"
					width={'25%'}
					className="bg-white"
					sorter={true}
					render={(_: any, data: any) => (
						<div>
							<Ellipse
								message={data.address}
								maxLength={25}
								isTooltip={true}
								tooltipMessage={data.address}
							/>
						</div>
					)}
				/>
				<Column
					title="Email"
					dataIndex="email"
					key="email"
					className="bg-white"
					sorter={true}
				/>
				<Column
					title="TPIN"
					dataIndex="tpin"
					key="tpin"
					width={'15%'}
					className="bg-white"
					sorter={true}
				/>
				{/* <Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					width={'15%'}
					className="bg-white"
					sorter={true}
					render={(text: string, record: any) => (
						<Space>
							<span>{text}</span>
							{permissions.isSuperAdmin && (
								<SettingOutlined 
									style={{ fontSize: 18, cursor: 'pointer' }}
									onClick={() => handleCurrencyModel()}
								/>
							)}
						</Space>
					)}
				
					
				/> */}
				{permissions.isSuperAdmin && (
					<Column
						title="Action"
						key="action"
						width={'10%'}
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => editDataHandler(data)}
								>
									<EditActionSvg />
								</div>
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => deleteDataHandler(data)}
								>
									<DeleteActionSvg />
								</div>
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default CompanyTable;
