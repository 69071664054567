import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import dayjs from 'dayjs';
import MidMonthPayTableHeader from './Header';
import MidMonthTable from './MidMonthPayTable';
import { getApi, putApi } from 'apis';

const MidMonthPaysComponent = () => {
	const navigate = useNavigate();

	const [midMonthData, setMidMonthData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState<any>(dayjs());

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchMidMonthData = async () => {
		try {
			const year = date.year();
			setIsLoading(true);
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				year: year,
			};

			const midMonthPay = await getApi(`/midMonthPays`, query);
			const data = midMonthPay.data.data;
			setMidMonthData(data);
			setTotalRecords(midMonthPay.data.total);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateMidMonthStatus = async (id: string, status: any) => {
		try {
			setIsLoading(true);
			const requestData = { id, status };

			const response = await putApi(`/midMonthPays/${id}`, requestData);

			if (response && response.data) {
				const updatedData = midMonthData.map((item) =>
					item.id === id ? { ...item, status } : item
				);
				setMidMonthData(updatedData);
				toastText('Status updated successfully.', 'success');
			} else {
				toastText('Invalid response format', 'error');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchMidMonthData();
	}, [date, pageSize, sortField, currentPage, sortOrder]);

	return (
		<div className="modal-animation">
			<div className={styles['mid-month-pay']}>
				<div className={styles['mid-month-pay__header']}>
					<div className={styles['mid-month-pay__header__title']}>
						Mid Month Pay
					</div>
					<div
						className={styles['mid-month-pay__header__actions']}
						onClick={() => {
							navigate('/');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['mid-month-pay__table']}>
					<MidMonthPayTableHeader
						handleDatePicker={handleDatePicker}
						dateValue={date}
					/>
					<MidMonthTable
						tableData={midMonthData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						tableChangeHandler={tableChangeHandler}
						isLoading={isLoading}
						fetchMidMonthData={fetchMidMonthData}
						updateMidMonthStatus={updateMidMonthStatus}
					/>
				</div>
			</div>
		</div>
	);
};

export default MidMonthPaysComponent;
