import Employees from 'components/Employees';
import './index.scss';

export const EmployeesPage = () => {
	return (
		<div>
			<Employees />
		</div>
	);
};
