import { HistoryOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
const { Column } = Table;

type Props = {
	payPeriodLatestData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const PayPeriodLatestTable = (props: Props) => {
	const { payPeriodLatestData, isLoading, showModal, totalRecords } = props;

	const capitalizeFirstLetter = (text: string) => {
		if (!text) return '';
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	};

	const formatWorkWeek = (workWeek: string[]) => {
		return workWeek.map((day) => capitalizeFirstLetter(day)).join(', ');
	};
	return (
		<div>
			<Table
				dataSource={payPeriodLatestData}
				rowKey={(record) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Work week"
					dataIndex="workWeek"
					key="workWeek"
					className="bg-white"
					width={'20%'}
					render={(text: string[]) => {
						const formattedWorkWeek = formatWorkWeek(text);
						return (
							<Ellipse
								message={formattedWorkWeek}
								maxLength={20}
								key={formattedWorkWeek}
								tooltipMessage={formattedWorkWeek}
								isTooltip={true}
							/>
						);
					}}
				/>
				<Column
					title="Employee Pay Date"
					dataIndex="startPayroll"
					key="startPayroll"
					className="bg-white"
					width={'20%'}
					render={(text) => dayjs(text).format('DD')}
				/>
				<Column
					title="Start Date"
					dataIndex="startPayroll"
					key="startPayroll"
					className="bg-white"
					width={'15%'}
					render={(text) => dayjs(text).format('MMM, YYYY')}
				/>
				<Column
					title="Pay Date"
					dataIndex="startPayroll"
					key="startPayroll"
					className="bg-white"
					width={'15%'}
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>

				{totalRecords >= 1 && (
					<Column
						title="History"
						dataIndex="history"
						key="history"
						className="bg-white"
						width={'15%'}
						render={() => (
							<Button
								type="link"
								icon={<HistoryOutlined />}
								onClick={showModal}
							/>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default PayPeriodLatestTable;
