import { getApi, postApi } from 'apis';

const getLeaveManagement = async (params: any) => {
	return await getApi('/leaveManagement', params);
};
const addConfigurationRoles = async (
	leaveManagementId: string,
	configurationRules: any
) => {
	return await postApi(
		`/leaveManagement/configuration/${leaveManagementId}`,
		configurationRules
	);
};

const getLeaveConfigurations = async (leaveManagementId: string) => {
	return await getApi(`/leaveManagement/configuration/${leaveManagementId}`);
};

const getLeaveTypeByEmployeeId = async (employeeId: string) => {
	return await getApi(`/leaveManagement/leaveType/${employeeId}`);
};

export const leaveManagementApi = {
	getLeaveManagement,
	addConfigurationRoles,
	getLeaveConfigurations,
	getLeaveTypeByEmployeeId,
};
