import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
	sidebarDrawerOpen: () => void;
	PayPeriodExists: boolean;
	isLoading: boolean;
};

const PayPeriodHeader = ({
	sidebarDrawerOpen,
	PayPeriodExists,
	isLoading,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Pay_Period');
	const buttonTitle = PayPeriodExists
		? 'Update Pay Period'
		: 'Add Pay Period';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['employee-header']}>
			{isAdd && (
				<div>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default PayPeriodHeader;
