import { getApi, postApi, putApi } from 'apis';

const getApprovalRequests = async () => {
	return await getApi(`/approval`);
};

const acceptApprovalRequests = async (data: any) => {
	return await postApi(`/approval/accept`, data);
};

const rejectApprovalRequests = async (data: any) => {
	return await postApi(`/approval/reject`, data);
};

const acceptTimeSheetApprovalRequest = async (data: any) => {
	return await postApi(`/approval/timesheet/accept`, data);
};

const rejectTimeSheetApprovalRequests = async (data: any) => {
	return await postApi(`/approval/timesheet/reject`, data);
};

const getApprovalFields = async (data: any) => {
	return await postApi(`/approval/fields`, data);
};

const updateApprovalFields = async (data: any) => {
	return await putApi(`/approval/fields`, data);
};

export const getApproveRequestApi = {
	getApprovalRequests,
	acceptApprovalRequests,
	rejectApprovalRequests,
	getApprovalFields,
	updateApprovalFields,
	acceptTimeSheetApprovalRequest,
	rejectTimeSheetApprovalRequests,
};
