import React, { useContext } from 'react';

import './index.scss';
import DepartmentComponent from 'components/Settings/Masters/Department';
import { PermissionContext } from 'components/Global/AuthLayout';

export const Department = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Departments');

	return <div>{check && <DepartmentComponent />}</div>;
};
