import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Upload, UploadFile } from 'antd';
import Title from 'antd/es/typography/Title';
import { UploadChangeParam } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import { useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import { payeApi } from 'Api/payeApi';

type AddCommunicationModalProps = {
	open: boolean;
	closeAddCommunicationModal: () => void;
	fetchInformationDetails: () => void;
};
const AddCommunicationModal = (props: AddCommunicationModalProps) => {
	const { open, closeAddCommunicationModal, fetchInformationDetails } = props;
	const [communicationName, setCommunicationName] = useState('');
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isSubmitClick, setIsSubmitClick] = useState(false);

	let isRemoving = false;

	const buttons = [
		{
			text: 'Upload',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: handleSubmit,
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: cancelCommunicationModal,
		},
	];

	function cancelCommunicationModal() {
		setCommunicationName('');
		setFile(null);
		setFileList([]);
		setIsSubmitClick(false);
		setHasError(false);
		closeAddCommunicationModal();
	}

	const propsUpload = {
		name: 'file',
		accept: '.pdf,.jpg,.jpeg,.png',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}

			const isLt1M = file.size! / 1024 / 1024 < 1;
			if (!isLt1M) {
				toastText('File must be smaller than 1MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false;
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};
	const handleChange = (value: string) => {
		if (value) {
			setCommunicationName(value);
			setHasError(false);
		} else {
			setCommunicationName('');
			setHasError(true);
		}
	};

	async function handleSubmit() {
		setIsSubmitClick(true);
		let _communicationName = communicationName.trim();
		if (invalidText(communicationName)) {
			setHasError(true);
			return;
		}
		_communicationName = _communicationName.replace(/ /g, '_');
		if (!file) {
			return;
		}
		try {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('name', _communicationName);
			formData.append('moduleName', 'PAYEcommunication');
			formData.append('file', file);
			await payeApi.createCommunication(formData);
			fetchInformationDetails();
			toastText('Communication added successfully', 'success');
		} catch (error: any) {
			const message =
				error?.response?.data?.error?.description ||
				'Something went wrong in upload document';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			cancelCommunicationModal();
		}
	}
	return (
		<>
			<Modal
				open={open}
				maskClosable={true}
				width="600px"
				footer={null}
				closable={false}
				onCancel={cancelCommunicationModal}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Add Communication</Title>
						<div
							className={styles['close-icon']}
							onClick={cancelCommunicationModal}
						>
							<CloseOutlined />
						</div>
					</div>
					<div>
						<Row
							className={styles['form-container-card']}
							gutter={25}
						>
							<Col span={24} className={styles['col']}>
								<InputField
									name="communicationName"
									value={communicationName}
									label="Communication Name"
									regex="^[a-zA-Z0-9 ]*$"
									required={true}
									helperText="Communication name can only contain letters and spaces."
									onChange={(value) => {
										handleChange(value);
									}}
									width="100%"
									isError={hasError}
									disabled={false}
								/>
							</Col>
							<Col span={24} className={styles['col']}>
								<div className={styles['document-upload']}>
									<label
										className={
											styles['document-form-label']
										}
									>
										Upload the Document{' '}
										<span className={styles['required']}>
											*{' '}
										</span>
									</label>
									<Dragger {...propsUpload} className="test">
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-hint">
											Drag and drop a file or choose file
											from Device Supported file formats.
											<br />
											PDF, JPG, JPEG, PNG |{' '}
											<span className="color-purple">
												Maximum file size : 1MB
											</span>
										</p>
									</Dragger>
									{!file && isSubmitClick ? (
										<p
											style={{
												color: 'red',
												fontSize: '12px',
											}}
										>
											Select document
										</p>
									) : (
										''
									)}
								</div>
							</Col>
						</Row>
						<Row className={styles['modal-buttons']}>
							<Buttons buttons={buttons} />
						</Row>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AddCommunicationModal;
