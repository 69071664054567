import EarningsModal from './EarningsBody';
import YearMonthPicker from './EarningsBody/DatePicker';
import styles from './index.module.scss';
import { useState } from 'react';
import { employeeApi } from 'Api/employee';
import dayjs from 'dayjs';
import { toastText } from 'utils/utils';
import Buttons from 'components/Global/Buttons';

const EarningsTab = (props: any) => {
	const {
		year,
		month,
		employeeId,
		onYearChange,
		onMonthChange,
		activeTab
	} = props;
	const [earningsAmount, setEarningsAmount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const myButtons = [
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			disabled: isLoading,
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleSave();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			disabled: isLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				// handleCancel();
			},
		},
	];

	const [data, setData] = useState<any>();
	const [isFetchDataLoading, setIsFetchDataLoading] =
		useState<boolean>(false);

	const handleDataChange = (data: any) => {
		setData(data);
		const total = data?.reduce(
			(sum: any, item: any) =>
				item.isChecked ? sum + parseFloat(item?.amount || '0.00') : sum,
			0
		);
		setEarningsAmount(total);
	};

	const handleFetchLoading = (status: boolean) => {
		setIsFetchDataLoading(status);
	};

	const handleSave = async () => {
		const finalData = {
			year,
			month,
			employeeId,
			data,
		};

		try {
			setIsLoading(true);
			const apiCall = await employeeApi.createEmployeeEarnings(finalData);

			let message =
				apiCall?.data?.message || `Earnings saved successfully`;
			toastText(message, 'success');
		} catch (error: any) {
			let message =
				error?.data?.message ||
				`something went wrong failed to save earnings`;
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<div className={styles.headerBox}>
				<div className={styles.headerContent}>
					<div className={styles.titleContainer}>
						<div className={styles.titleText}>{'Earnings'}</div>
						<div className={styles.separation}></div>
						<div className={styles.earningsCount}>
							<div className={styles.titleEarnings}>
								Total Earnings
							</div>
							<div className={styles.earningsAmount}>
								K
								{!isFetchDataLoading &&
									earningsAmount?.toFixed(2)}
							</div>
						</div>
					</div>
					<div className={styles.yearMonthContent}>
						<YearMonthPicker
							onYearChange={onYearChange}
							onMonthChange={onMonthChange}
							year={dayjs().year(year)}
							month={month}
						/>
					</div>
				</div>
			</div>
			<EarningsModal
				onDataChange={(data) => handleDataChange(data)}
				onLoadingChange={(loading) => handleFetchLoading(loading)}
				month={month}
				year={year}
				employeeId={employeeId}
				activeTab={activeTab}
			/>
			<div className={styles.buttons}>
				{!isFetchDataLoading && <Buttons buttons={myButtons} />}
			</div>
		</div>
	);
};

export default EarningsTab;
