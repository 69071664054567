import {  useEffect, useState } from 'react';
import {  toastText } from 'utils/utils';
import CompanyTable from './Table';
import Header from './Header';
import { companySetup } from 'Api/companySetup';

type Props = {};

const CompanySetupComponent = (props: Props) =>{
	const [companyData, setCompanyData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	
	const fetchCompany = async () => {
		try {
			setIsLoading(true);
			const response = await companySetup.getCompanyDetails();
			setCompanyData(response?.data?.data);
		} catch (err: any) {
			const message =
				err?.response?.data?.message ||
				'Something went wrong in fetching company';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};


		useEffect(() => {
		fetchCompany();
	}, []);

	return (
		<>
			{/* <div>
				<Header />{' '}
			</div> */}

			<CompanyTable
				tableData={companyData}
				isLoading={isLoading}
			//	handleEdit = {handleEdit}
			/>
		</>
	);
}

export default CompanySetupComponent;
