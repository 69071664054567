import {  getApi, postApi, putApi } from 'apis';

const createTaxation = async (data: any) => {
	return await postApi('/employee/taxation', data);
};
const getTaxationDetailsByEmployeeId = async (id: string) => {
	return await getApi(`/employee/taxation/${id}`);
};

const updateTaxation = async (employeeId:string,data: any) => {
	return await putApi(`/employee/taxation/${employeeId}`, data);
};

export const taxationApi = {
	createTaxation,
	getTaxationDetailsByEmployeeId,
	updateTaxation,
};
