import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import InputNumberField from 'components/Global/InputNumberField';
import SelectDropdown from 'components/Global/SelectDropdown';
import TextareaFieldInput from 'components/Global/textareaField';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import { NewLoanRequestProps } from './types';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import { loanRequestApi } from 'Api/loanRequest';
import './index.scss';

const NewLoanRequestModel: React.FC<NewLoanRequestProps> = (props) => {
	const {
		handleCancel,
		isNewLoanRequestModalOpen,
		fetchLoanRequest,
		edit,
		setEdit,
	} = props;

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [loanRequestDetails, setLoanRequestDetails] = useState<any>({
		employeeId: '',
		amount: 0,
		installment: 0,
		notes: '',
	});
	const [monthlyDeductions, setMonthlyDeductions] = useState<any[]>([]);
	const [formError, setFormError] = useState<any>({
		employeeId: false,
		amount: false,
		installment: false,
		notes: false,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		if (edit) {
			const editEmployee = constantDropdownOptions?.employees.find(
				(employee: any) =>
					employee?.label.split(' ')[0] === edit?.employeeCode
			);
			const editData = {
				...edit,
				employeeId: editEmployee?.value,
			};
			setLoanRequestDetails(editData);
			calculateMonthlyDeductions(
				edit.amount,
				edit.installment,
				new Date(edit.createdAt).toDateString()
			);
		}
	}, [edit]);

	useEffect(() => {
		calculateMonthlyDeductions(
			loanRequestDetails.amount,
			loanRequestDetails.installment,
			new Date().toDateString()
		);
	}, [loanRequestDetails.amount, loanRequestDetails.installment]);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	const calculateMonthlyDeductions = (
		amount: string | number | null,
		installments: string | number | null,
		startDate: string | null
	) => {
		if (amount === null || installments === null || startDate === null)
			return;

		const amountNumber =
			typeof amount === 'number' ? amount : parseFloat(amount as string);
		const installmentsNumber =
			typeof installments === 'number'
				? installments
				: parseInt(installments as string, 10);

		if (isNaN(amountNumber) || isNaN(installmentsNumber)) return;

		const startMonth = dayjs(startDate);
		let deductions = [];
		const deductionPerMonth = amountNumber / installmentsNumber;

		for (let i = 0; i < installmentsNumber; i++) {
			const month = startMonth.add(i + 1, 'month').format('MMM - YYYY');
			deductions.push({
				key: i + 1,
				month: month,
				amount: deductionPerMonth.toFixed(2),
			});
		}

		setMonthlyDeductions(deductions);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...loanRequestDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				employeeId: loanRequestDetails.employeeId,
				amount: loanRequestDetails.amount,
				installment: loanRequestDetails.installment,
				notes: loanRequestDetails.notes,
			};

			setIsLoading(true);
			try {
				let response;
				if (edit) {
					response = await loanRequestApi.updateLoan(edit.id, data);
				} else {
					response = await loanRequestApi.requestLoan(data);
				}

				fetchLoanRequest();
				let message =
					response?.data?.message ||
					(edit
						? 'Loan Request has been updated successfully'
						: 'Loan Request has been created successfully');
				toastText(message, 'success');
				closeModal();
			} catch (err: any) {
				let message =
					err.response?.data?.message ||
					(edit
						? 'Something went wrong in updating Loan Request'
						: 'Something went wrong in creating Loan Request');
				toastText(message, 'error');
			}
			setIsLoading(false);
		}
	};

	const closeModal = () => {
		setLoanRequestDetails({
			employeeId: '',
			amount: 0,
			installment: 0,
			notes: '',
		});
		setFormError({
			employeeId: false,
			amount: false,
			installment: false,
			notes: false,
		});
		setEdit(null);
		handleCancel();
	};

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}
		if (typeof value === 'number') {
			if (value <= 0) {
				setHasError(true);
				return;
			}
		}
		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name);
	};

	const OnChange = (value: string | number | null, key: string) => {
		setLoanRequestDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const myButtons = [
		{
			text: edit ? 'Update' : 'Request',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleSubmit();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
	];

	return (
		<Modal
			open={isNewLoanRequestModalOpen}
			onOk={handleSubmit}
			onCancel={closeModal}
			okText={'Save'}
			closable={false}
			width={800}
			footer={null}
			title={<h2>{edit ? 'Edit Loan Request' : 'New Loan Request'}</h2>}
			className="newLoanRequestModal"
		>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<Row className={styles['form-container-card']} gutter={50}>
				<Col span={24} className={styles['col']}>
					<SelectDropdown
						placeholder="Employee Name"
						options={
							constantDropdownOptions?.employees
								? constantDropdownOptions?.employees
								: []
						}
						value={loanRequestDetails?.employeeId}
						onChange={(value: any) =>
							handleChange(value, 'employeeId', true)
						}
						size="large"
						required={true}
						helperText="Employee Name required"
						label="Employee Code | Employee Name"
						isError={formError.employeeId}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="amount"
						value={loanRequestDetails.amount}
						label="Amount"
						required={true}
						helperText="Amount required"
						onChange={(value) =>
							handleChange(value, 'amount', true)
						}
						suffix={'K'}
						isError={formError.amount}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="installment"
						value={loanRequestDetails.installment}
						label="Repayment Installments"
						required={true}
						helperText="Repayment Installments required"
						onChange={(value) =>
							handleChange(value, 'installment', true)
						}
						isError={formError.installment}
					/>
				</Col>

				{loanRequestDetails.amount && loanRequestDetails.installment ? (
					<Col
						span={24}
						className={styles['title-monthly-deduction']}
					>
						<Title level={5}>Monthly Deduction</Title>
						<div className={styles['table-container']}>
							<div
								className={`${styles['table-header']} ${styles['table-row']}`}
							>
								<div className={styles['table-cell']}>
									Month
								</div>
								<div className={styles['table-cell']}>
									Amount
								</div>
							</div>
							{monthlyDeductions.map((item) => (
								<div
									key={item.key}
									className={`${styles['table-row']}`}
								>
									<div className={styles['table-cell']}>
										{item.month}
									</div>
									<div className={styles['table-cell']}>
										{item.amount}
									</div>
								</div>
							))}
						</div>
					</Col>
				) : (
					<></>
				)}
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<TextareaFieldInput
						name="notes"
						value={loanRequestDetails.notes}
						label="Comments"
						required={true}
						helperText="Comments required"
						onChange={(value) => handleChange(value, 'notes', true)}
						isError={formError.notes}
					/>
				</Col>
			</Row>

			<div className={styles['buttons']}>
				<Buttons buttons={myButtons} />
			</div>
		</Modal>
	);
};

export default NewLoanRequestModel;
