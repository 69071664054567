import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type Props = {
	button: any;
	dateValue: any;
	handleDatePicker: any;
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
};

const HolidayHeader = (props: Props) => {
	const {
		handleDatePicker,
		dateValue,
		button,
		searchText,
		handleSearch,
		handleSearchApi,
	} = props;
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes('Add_Holidays');
	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>

				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="year"
					isError={false}
					onChange={handleDatePicker}
				/>
			</Space>

			{isAdd && (
				<Space>
					<Buttons buttons={button} />
				</Space>
			)}
		</div>
	);
};

export default HolidayHeader;
