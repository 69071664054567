import React from 'react';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
interface HeaderProps {
	showModal: () => void;
	isLoading: boolean;
}
const Header = (props: HeaderProps) => {
	const { showModal ,isLoading} = props;
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Mid-Month_Pay_Setup'
	);
	const buttons = [
		{
			text: 'Setup',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				showModal();
			},
			disabled: isLoading ,
		},
	];
	return (
		<div
			className={styles['header']}
			style={{
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				marginBottom: '10px',
				top: '105.33px',
			}}
		>
			<div>{isAdd && <Buttons buttons={buttons} />}</div>
		</div>
	);
};

export default Header;
