import { LoadingOutlined } from '@ant-design/icons';
import { getApproveRequestApi } from 'Api/approveRequest';
import { Button, Card, Checkbox, Collapse, Modal, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import InputField from 'components/Global/InputField';
import NotificationTimeSheetModal from 'components/TimeSheet/NotificationModal';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
	fieldNameMapping,
	invalidText,
	sectionMapping,
	taxationMethodMapping,
	toastText,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import NotificationLoanRequestModal from 'components/LoanRequest/NotificationLoanRequestModal';
import NotificationLeaveRequestModal from 'components/LeaveRequest/NotificationLeaveRequestModal';

type Props = {
	isNotificationModalOpen: boolean;
	handleCancel: () => void;
};

const { Panel } = Collapse;

const NotificationModal = (props: Props) => {
	const [notificationData, setNotificationData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAcceptLoading, setIsAcceptLoading] = useState(false);
	const [isRejectLoading, setIsRejectLoading] = useState(false);
	const [checkedState, setCheckedState] = useState<any>([]);
	const [reasonState, setReasonState] = useState<{ [key: string]: string }>(
		{}
	);
	const [errorState, setErrorState] = useState<{ [key: string]: boolean }>(
		{}
	);

	const [isTimeSheetModalOpen, setIsTimeSheetModalOpen] = useState(false);
	const [selectedTimeSheet, setSelectedTimeSheet] = useState(null);
	const [selectedTimeSheetLabel, setSelectedTimeSheetLabel] = useState('');
	const [approvalId, setApprovalId] = useState(null);

	const [isLoanRequestModalOpen, setIsLoanRequestModalOpen] = useState(false);
	const [selectedLoanRequest, setSelectedLoanRequest] = useState(null);
	const [selectedLoanRequestLabel, setSelectedLoanRequestLabel] =
		useState('');
	const [isLeaveRequestModalOpen, setIsLeaveRequestModalOpen] =
		useState(false);
	const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
	const [selectedLeaveRequestLabel, setSelectedLeaveRequestLabel] =
		useState('');

	const { isNotificationModalOpen, handleCancel } = props;

	useEffect(() => {
		fetchApprovalRequest();
	}, []);

	const fetchApprovalRequest = async () => {
		setIsLoading(true);
		try {
			const resData = await getApproveRequestApi.getApprovalRequests();
			setNotificationData(resData.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setIsLoading(false);
	};

	const closeTimeSheetModal = () => {
		setIsTimeSheetModalOpen(false);
	};
	const closeLoanRequestModal = () => {
		setIsLoanRequestModalOpen(false);
	};
	const closeLeaveRequestModal = () => {
		setIsLeaveRequestModalOpen(false);
	};

	const handleHeaderCheckboxChange = (
		employeeId: string,
		section: string,
		checked: boolean
	) => {
		setCheckedState((prevState: any) => {
			const updatedState = { ...prevState };
			notificationData?.forEach((employee: any) => {
				if (employee.entityId === employeeId) {
					employee.ApprovalRequest.forEach((request: any) => {
						if (request.section === section) {
							updatedState[request.id] = checked;
							if (!checked) {
								setErrorState((prevState) => ({
									...prevState,
									[request.id]: false,
								}));
							}
						}
					});
				}
			});
			return updatedState;
		});
	};

	const handleCheckboxChange = (id: string, checked: boolean) => {
		setCheckedState((prevState: any) => ({
			...prevState,
			[id]: checked,
		}));

		if (!checked) {
			setErrorState((prevState) => ({
				...prevState,
				[id]: false,
			}));
		}
	};

	const handleReasonChange = (id: string, value: string) => {
		setReasonState((prevState) => ({
			...prevState,
			[id]: value,
		}));
		setErrorState((prevState) => ({
			...prevState,
			[id]: false,
		}));
	};

	const handleApproveClick = (employeeId: string) => {
		const selectedIds = Object.keys(checkedState).filter(
			(key) => checkedState[key]
		);

		if (selectedIds.length > 0) {
			const result = selectedIds.map((id) => ({
				id,
				reason: reasonState[id] || '',
			}));
			acceptApprovalRequest(result);
		}
	};

	const acceptApprovalRequest = async (ids: any) => {
		setIsAcceptLoading(true);
		try {
			await getApproveRequestApi.acceptApprovalRequests({
				ids,
			});
			fetchApprovalRequest();
			toastText('Request approved successfully', 'success');
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setCheckedState([]);
		setIsAcceptLoading(false);
	};

	const handleRejectClick = (employeeId: string) => {
		const selectedIds = Object.keys(checkedState).filter(
			(key) => checkedState[key]
		);

		if (selectedIds.length > 0) {
			let isValid = true;
			const newErrorState: { [key: string]: boolean } = {};

			selectedIds.forEach((id) => {
				if (!reasonState[id]) {
					isValid = false;
					newErrorState[id] = true;
				}
			});

			if (isValid) {
				const result = selectedIds.map((id) => ({
					id,
					reason: reasonState[id],
				}));
				rejectApprovalRequest(result);
			} else {
				setErrorState(newErrorState);
			}
		}
	}; 

	const rejectApprovalRequest = async (ids: any) => {
		setIsRejectLoading(true);
		try {
			await getApproveRequestApi.rejectApprovalRequests({
				ids,
			});
			toastText('Request rejected successfully', 'success');
			fetchApprovalRequest();
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setCheckedState([]);
		setIsRejectLoading(false);
	};

	const mapEmployeesToAccordionItems = (employees: any) => {
		return employees.map((employee: any) => {
			if (employee.moduleName === 'EMPLOYEE') {
				const approvalRequestsBySection: { [key: string]: any[] } = {};

				employee.ApprovalRequest.forEach((request: any) => {
					if (!approvalRequestsBySection[request.section]) {
						approvalRequestsBySection[request.section] = [];
					}
					approvalRequestsBySection[request.section].push(request);
				});

				return {
					key: employee.entityId,
					label: `Employee: ${employee?.entityData?.employeeId}`,
					children: (
						<div className="notification-table">
							{Object.entries(approvalRequestsBySection).map(
								([section, requests], index) => (
									<div key={section}>
										<h4
											className="color-purple"
											style={{
												fontWeight: 500,
												fontSize: '1.7rem',
												marginBottom: '12px',
											}}
										>
											{
												sectionMapping[
													section as keyof typeof sectionMapping
												]
											}
										</h4>
										<Table
											dataSource={requests}
											pagination={false}
											rowKey="id"
										>
											<Column
												title={
													<Checkbox
														checked={requests.every(
															(request: any) =>
																checkedState[
																	request.id
																]
														)}
														onChange={(e) =>
															handleHeaderCheckboxChange(
																employee.entityId,
																section,
																e.target.checked
															)
														}
													/>
												}
												width={'10%'}
												key="checkbox"
												render={(text, record: any) => (
													<Checkbox
														checked={
															checkedState[
																record.id
															]
														}
														onChange={(e) =>
															handleCheckboxChange(
																record.id,
																e.target.checked
															)
														}
													/>
												)}
											/>

											<Column
												title="Field Name"
												key="fieldName"
												className="bg-white"
												width={'20%'}
												render={(text, record: any) =>
													fieldNameMapping[
														record.fieldName as keyof typeof fieldNameMapping
													]
												}
											/>
											<Column
												title="Old Value"
												dataIndex="oldValue"
												key="oldValue"
												width={'20%'}
												className="bg-white"
												render={(record, data: any) => {
													let _record = record;
													if (
														data.fieldName ===
															'dateOfBirth' ||
														data.fieldName ===
															'joiningDate' ||
														data.fieldName ===
															'terminationDate' ||
														data.fieldName ===
															'effectiveStartDate'
													) {
														if (record !== 'NA') {
															_record =
																dayjs(
																	record
																).format(
																	'DD/MM/YYYY'
																);
														} else {
															_record = 'NA';
														}
													}
													if (
														data.changes
															.isSelectField
													) {
														_record =
															data.changes
																.oldValueName;
													}
													if (
														data.fieldName ===
														'Salary Details'
													) {
														_record =
															data.changes
																.oldValue
																.monthlySalary;
													}
													if (
														data.fieldName ===
														'taxationMethod'
													) {
														_record =
															taxationMethodMapping[
																data.oldValue as keyof typeof taxationMethodMapping
															];
													}
													if (
														data.fieldName ===
														'earnings'
													) {
														_record =
															data.changes
																.oldValue
																.amount;
													}
													return _record;
												}}
											/>
											<Column
												title="Updated Value"
												dataIndex="newValue"
												key="newValue"
												width={'20%'}
												className="bg-white"
												render={(record, data: any) => {
													let _record = record;
													if (
														data.fieldName ===
															'dateOfBirth' ||
														data.fieldName ===
															'joiningDate' ||
														data.fieldName ===
															'terminationDate' ||
														data.fieldName ===
															'effectiveStartDate'
													) {
														_record =
															dayjs(
																record
															).format(
																'DD/MM/YYYY'
															);
													}
													if (
														data.changes
															.isSelectField
													) {
														_record =
															data.changes
																.newValueName;
													}
													if (
														data.fieldName ===
														'Salary Details'
													) {
														_record =
															data.changes
																.newValue
																.monthlySalary;
													}
													if (
														data.fieldName ===
														'taxationMethod'
													) {
														_record =
															taxationMethodMapping[
																data.newValue as keyof typeof taxationMethodMapping
															];
													}
													if (
														data.fieldName ===
														'earnings'
													) {
														_record =
															data.changes
																.newValue
																.amount;
													}
													return _record;
												}}
											/>
											<Column
												title="Reason"
												key="reason"
												width={'30%'}
												className="bg-white"
												render={(text, record: any) => (
													<div>
														<InputField
															name={`reason-${record.id}`}
															label=""
															value={
																reasonState[
																	record.id
																] || ''
															}
															required={false}
															isError={
																errorState[
																	record.id
																] || false
															}
															helperText={
																'Reason is required'
															}
															disabled={
																!checkedState[
																	record.id
																]
															} // Disable if the checkbox is not checked
															onChange={(
																value: string
															): void =>
																handleReasonChange(
																	record.id,
																	value
																)
															}
														/>
													</div>
												)}
											/>
										</Table>
									</div>
								)
							)}
							<Space>
								<Button
									className="btn-blue"
									onClick={() =>
										handleApproveClick(employee.entityId)
									}
									loading={isAcceptLoading}
									disabled={
										isAcceptLoading || isRejectLoading
									}
								>
									Approve
								</Button>
								<Button
									className="btn-cancel"
									onClick={() =>
										handleRejectClick(employee.entityId)
									}
									loading={isRejectLoading}
									disabled={
										isAcceptLoading || isRejectLoading
									}
								>
									Reject
								</Button>
							</Space>
						</div>
					),
				};
			} else if (employee.moduleName === 'TIME_SHEET') {
				return {
					label: `Time sheet (${dayjs(
						employee?.entityData?.fromDate
					).format('DD/MM/YYYY')} - ${dayjs(
						employee.entityData.toDate
					).format('DD/MM/YYYY')})`,
					key: employee.entityId,
					children: null,
					timeSheetId: employee.entityData.id,
					approvalId: employee.ApprovalRequest[0]?.id,
					type: employee.moduleName,
				};
			} else if (employee.moduleName === 'LOAN_REQUEST') {
				return {
					label: `Loan Request (Employee - ${employee?.entityData?.employee?.employeeId})`,
					key: employee.entityId,
					children: null,
					approvalId: employee.ApprovalRequest[0]?.id,
					type: employee.moduleName,
				};
			} else if (employee.moduleName === 'LEAVE_REQUEST') {
				return {
					label: `Leave Request (Employee - ${employee?.entityData?.employee?.employeeId})`,
					key: employee.entityId,
					children: null,
					approvalId: employee.ApprovalRequest[0]?.id,
					type: employee.moduleName,
				};
			}
		});
	};

	const items = mapEmployeesToAccordionItems(notificationData);

	return (
		<Modal
			open={isNotificationModalOpen}
			onCancel={handleCancel}
			okText="Save"
			closable={false}
			width={900}
			footer={null}
		>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '40vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<div className={styles['notification-modal']}>
					<h2 className={styles['notification-modal__title']}>
						Notifications
					</h2>
					{notificationData?.length ? (
						<div className={styles['notification-modal__list']}>
							{/* <h4>Employee</h4> */}
							<div
								className={
									styles['notification-modal__list--items']
								}
							>
								<Collapse
									defaultActiveKey={['1']}
									style={{
										padding: '0px',
									}}
									className="collapse"
								>
									{items.map((item: any) => {
										if (item.children) {
											return (
												<Panel
													key={item.key}
													header={item.label}
													disabled={invalidText(
														item.children
													)}
													style={{
														padding: '5px 0px',
													}}
												>
													{item.children}
												</Panel>
											);
										} else {
											return (
												<Card
													key={item.key}
													style={{
														backgroundColor:
															'white',
														boxShadow:
															'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
														borderRadius: '0px',
														marginBottom: '2rem',
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															gap: '2rem',
														}}
													>
														<p>{item.label}</p>
														<Button
															onClick={() => {
																setApprovalId(
																	item.approvalId
																);
																if (
																	item.type ===
																	'TIME_SHEET'
																) {
																	setSelectedTimeSheet(
																		item.key
																	);
																	setSelectedTimeSheetLabel(
																		item.label
																	);
																	setIsTimeSheetModalOpen(
																		true
																	);
																} else if (
																	item.type ===
																	'LOAN_REQUEST'
																) {
																	setSelectedLoanRequest(
																		item.key
																	);
																	setSelectedLoanRequestLabel(
																		item.label
																	);
																	setIsLoanRequestModalOpen(
																		true
																	);
																	setIsTimeSheetModalOpen(
																		false
																	);
																} else if (
																	item.type ===
																	'LEAVE_REQUEST'
																) {
																	setSelectedLeaveRequest(
																		item.key
																	);
																	setSelectedLeaveRequestLabel(
																		item.label
																	);
																	setIsLeaveRequestModalOpen(
																		true
																	);
																	setIsTimeSheetModalOpen(
																		false
																	);
																	setIsLoanRequestModalOpen(
																		false
																	);
																}
															}}
														>
															View Details
														</Button>
													</div>
												</Card>
											);
										}
									})}
								</Collapse>
							</div>
						</div>
					) : (
						<>
							<div
								className={
									styles['notification-modal--icon__text']
								}
							>
								No approval request present!
							</div>
						</>
					)}
				</div>
			)}
			{isTimeSheetModalOpen && selectedTimeSheet && approvalId && (
				<NotificationTimeSheetModal
					isOpen={isTimeSheetModalOpen}
					handleCancel={closeTimeSheetModal}
					selectedTimeSheet={selectedTimeSheet}
					selectedTimeSheetLabel={selectedTimeSheetLabel}
					approvalId={approvalId}
					fetchApprovalRequest={fetchApprovalRequest}
				/>
			)}
			{isLoanRequestModalOpen && selectedLoanRequest && approvalId && (
				<NotificationLoanRequestModal
					isOpen={isLoanRequestModalOpen}
					handleCancel={closeLoanRequestModal}
					selectedLoanRequest={selectedLoanRequest}
					selectedLoanRequestLabel={selectedLoanRequestLabel}
					approvalId={approvalId}
					fetchApprovalRequest={fetchApprovalRequest}
				/>
			)}
			{isLeaveRequestModalOpen && selectedLeaveRequest && approvalId && (
				<NotificationLeaveRequestModal
					isOpen={isLeaveRequestModalOpen}
					handleCancel={closeLeaveRequestModal}
					selectedLeaveRequest={selectedLeaveRequest}
					selectedLeaveRequestLabel={selectedLeaveRequestLabel}
					approvalId={approvalId}
					fetchApprovalRequest={fetchApprovalRequest}
				/>
			)}
		</Modal>
	);
};

export default NotificationModal;
