import TimeActivitiesComponent from 'components/TimeActivities';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TimeActivities = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Time_Activities');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <TimeActivitiesComponent />}</div>;
};
