import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useState } from 'react';

type Props = {
	content: any[];
	reportType: string;
};

const ReportPopOver = (props: Props) => {
	const { content, reportType } = props;
	const [visible, setVisible] = useState(false);

	const togglePopover = () => {
		setVisible(!visible);
	};

	const DisplayReports = () => {
		return (
			<>
				{content?.map((item: any) => {
					console.log('item: ', item);
					return (
						<div key={item.id}>
							<a
								href={item.documentLink}
								rel="noopener noreferrer"
							>
								{item.reportName}
							</a>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<Popover
			content={<DisplayReports />}
			title=""
			trigger="click"
			placement="bottom"
			open={visible}
			onOpenChange={togglePopover}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
				}}
			>
				<p style={{ marginRight: '8px' }}>{reportType} Reports</p>
				{visible ? <UpOutlined /> : <DownOutlined />}
			</div>
		</Popover>
	);
};

export default ReportPopOver;
