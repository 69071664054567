import { deleteApiWithData, getApi } from 'apis';
import { SideDrawerWrapper } from 'components/Global';
import Buttons from 'components/Global/Buttons';
import TableActionHeader from 'components/Global/TableActionHeader';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import AddPayGroupBody from './AddPayGroupBody';
import EmployeeTable from './Table/PayGroupTable';
import PayGroupHeader from './Table/Header';
import styles from './index.module.scss';
import PayGroupTable from './Table/PayGroupTable';

const PayGroup = () => {
	const buttons = [
		{
			text: 'Create Pay Group',
			isLoading: false,
			className: 'btn-blue',
			icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				openDrawerHandler();
			},
			disabled: false,
		},
	];

	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [edit, setEdit] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [drawerInfo, setDrawerInfo] = useState({
		drawerTitle: 'Branch Code',
	});
	const [branchData, setBranchData] = useState([]);
	const [branchOptions, setBranchOptions] = useState([]);

		const handleSearchApi = async (value: string) => {
		console.log('🚀 ~ handleSearchApi ~ value:', value);
		// fetchDepartment()
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	// Open side drawer with animation
	const openDrawerHandler = () => {
		setDrawerInfo({ drawerTitle: 'Create Pay Group' });
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};
	// Remove the side drawer
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	// Close the side drawer with animation
	const closeDrawerByAnimation = () => {
		setEdit(null); //!isAddUserLoading &&
		setDrawerAnimation(false); //!isAddUserLoading &&
	};

	const fetchPaygroup = async () => {
		try {
			setIsLoading(true);

			const query = {
				page: currentPage,
				search: searchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			const apiRes = await getApi('/payGroup', query);

			 setBranchData(apiRes.data.data);
			 setTotalRecords(apiRes.data.total);
		} catch (err: any) {
			if (err.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching pay group', 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};
	// Change data and title between components
	const setDrawerInfoHandler = (drawerTitle: any) => {
		setDrawerInfo({ drawerTitle });
	};

	// Confirm operation
	const handleOk = () => {
		setIsModalOpen(false);
		setEdit(null);
	};

	// Cancel operation
	const handleCancel = () => {
		setIsModalOpen(false); // !isAddUserLoading &&
		setEdit(null);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	// Delete user
	const deleteHandler = async () => {
		if (edit) {
			try {
				setIsDeleteLoading(true);
				await deleteApiWithData('/payGroup', {
					payGroupId: edit.id,
				});
				if (totalRecords > 1 && totalRecords % pageSize === 1) {
					setCurrentPage(currentPage - 1);
				}
				handleCancel();
				fetchPaygroup();
				toastText('Pay group deleted successfully', 'success');
			} catch (error: any) {
				const errorMessage =
					error.response?.data?.message ||
					'Something went wrong in delete pay group';
				toastText(errorMessage, 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const fetchBranchCodes = async () => {
		try {
			const apiRes: any = await getApi('/branchCode');

			if (apiRes.data.data) {
				const branches = apiRes.data.data.map((branch: any) => {
					return {
						id: branch.id,
						name: branch.name,
					};
				});
				setBranchOptions(branches);
			}
		} catch (err) {
			console.log('🚀 ~ fetchBranchCodes ~ err:', err);
			// toastText('Something went wrong in fetching branch code', 'error');
		}
	};
	useEffect(() => {
		fetchBranchCodes();
	}, []);

	useEffect(() => {
		fetchPaygroup();
	}, [pageSize, searchValue, currentPage, sortOrder, sortField]);
	return (
		<div>
			{/* <TableActionHeader title={'Branch Code'}>
				<div>
					<Buttons buttons={buttons} />
				</div>
			</TableActionHeader> */}
			<div className={styles['employee-container']}>
				<PayGroupHeader button={buttons} />
				<PayGroupTable
					paygroupData={branchData}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					fetchRecords={fetchPaygroup}
					setEditData={setEdit}
					openDrawerHandler={openDrawerHandler}
					setDrawerInfoHandler={setDrawerInfoHandler}
					showModal={showModal}
					isLoading={isLoading}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<AddPayGroupBody
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchPaygroup={fetchPaygroup}
						editSelected={edit}
						setEditSelectedUser={setEdit}
						branchOptions={branchOptions}
					/>
				</SideDrawerWrapper>
			)}
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isModalOpen}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading} 
			/>
		</div>
	);
};

export default PayGroup;
