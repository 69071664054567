import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	// button: any;
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	leaveTypeFilterValue: string;
	handleLeaveTypeFilter: (value: string) => void;
};

const LeaveManagementHeader = (props: Props) => {
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		handleLeaveTypeFilter,
		leaveTypeFilterValue,
	} = props;
	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<Select
					className={styles['employee-header-select']}
					value={leaveTypeFilterValue}
					options={[
						{ label: 'All', value: '' },
						{ label: 'Paid Leave', value: 'PAID' },
						{
							label: 'Unpaid Leave',
							value: 'UNPAID',
						},
					]}
					onChange={(value) => {
						handleLeaveTypeFilter(value);
					}}
					size="large"
					placeholder="Select by Leave Type"
				/>
			</Space>

			{/* <Space>
				<Buttons buttons={button} />
			</Space> */}
		</div>
	);
};

export default LeaveManagementHeader;
