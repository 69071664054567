import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Upload, UploadFile } from 'antd';
import Title from 'antd/es/typography/Title';
import { UploadChangeParam } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import Buttons from 'components/Global/Buttons';
import { useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { nhimaApi } from 'Api/nhima';

type UploadRecordProps = {
	open: boolean;
	closeUploadRecordModal: () => void;
	nhimaRecordId: string;
	fetchNhimaReportDetails: () => void;
};

const UploadRecord = (props: UploadRecordProps) => {
	const {
		open,
		closeUploadRecordModal,
		fetchNhimaReportDetails,
		nhimaRecordId,
	} = props;

	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isSubmitClick, setIsSubmitClick] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	let isRemoving = false;

	const buttons = [
		{
			text: 'Upload',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			minWidth: '10rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				cancelUploadRecordModal();
			},
			minWidth: '10rem',
		},
	];
	const propsUpload = {
		name: 'file',
		// accept: '.pdf,.jpg,.jpeg,.png',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}

			const isLt1M = file.size! / 1024 / 1024 < 1;
			if (!isLt1M) {
				toastText('File must be smaller than 1MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false;
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const cancelUploadRecordModal = () => {
		setFile(null);
		setFileList([]);
		closeUploadRecordModal();
		setIsLoading(false);
		setIsSubmitClick(false);
	};

	const handleSubmit = async () => {
		setIsSubmitClick(true);

		if (!file) {
			return;
		}
		try {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('moduleName', 'NHIMARecord');
			formData.append('file', file);
			await nhimaApi.createNhimaRecord(formData, nhimaRecordId);
			fetchNhimaReportDetails();
		} catch (error: any) {
			const message =
				error?.response?.data?.error?.description ||
				'Something went wrong in upload document';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			cancelUploadRecordModal();
		}
	};

	return (
		<Modal
			open={open}
			maskClosable={true}
			width="500px"
			footer={null}
			closable={false}
			onCancel={cancelUploadRecordModal}
			centered
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Title level={4}>NHIMA Record</Title>
					<div
						className={styles['close-icon']}
						onClick={cancelUploadRecordModal}
					>
						<CloseOutlined />
					</div>
				</div>
				<div>
					<Row className={styles['form-container-card']} gutter={25}>
						<Col span={24} className={styles['col']}>
							<div className={styles['document-upload']}>
								<label
									className={styles['document-form-label']}
								>
									Upload the Document{' '}
									<span className={styles['required']}>
										*{' '}
									</span>
								</label>
								<Dragger {...propsUpload} className="test">
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-hint">
										Drag and drop a file or choose file from
										Device.
										<br />
										{/* PDF, JPG, JPEG, PNG |{' '} */}
										<span className="color-purple">
											Maximum file size : 1MB
										</span>
									</p>
								</Dragger>
								{!file && isSubmitClick ? (
									<p
										style={{
											color: 'red',
											fontSize: '12px',
										}}
									>
										Select document
									</p>
								) : (
									''
								)}
							</div>
						</Col>
					</Row>
					<Row className={styles['modal-buttons']}>
						<Buttons buttons={buttons} />
					</Row>
				</div>
			</div>
		</Modal>
	);
};

export default UploadRecord;
