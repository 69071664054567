import { deleteApiWithData, getApi, postApi, putApi } from 'apis';

export const getAllBranchCode = async (params: any) => {
	return await getApi('/branchCode', params);
};

export const createBranchCode = async (data: any) => {
	return await postApi('/branchCode', data);
};

export const updateBranchCode = async (data: any) => {
	return await putApi('/branchCode', data);
};

export const deleteBranchCode = async (data: any) => {
	return await deleteApiWithData('/branchCode', data);
};
