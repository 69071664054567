import { Col, Row, Select } from 'antd';
import CustomCollapse from 'components/Global/Collpase';
import SelectDropdown from 'components/Global/SelectDropdown';
import { useState } from 'react';
import styles from './index.module.scss';
import CustomCollapsePanel from 'components/Global/Collpase';
import { BankSvg, CashSvg, ChequeSvg, RightArrowSvg } from 'utils/svgs';
import DirectDeposit from './DirectDeposit';
import BankTransfer from './BankTransfer';
import Cheque from './Cheque';
import FormItem from 'antd/es/form/FormItem';

type Props = {};

const PaymentInfo = (props: Props) => {
	const [selectedValue, setSelectedValue] = useState('01- Pay Office');

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleDropdownChange = (value: string) => {
		setSelectedValue(value.toString());
	};
	return (
		<div className={styles['form']}>
			<div className={styles['form-container']}>
				<div className={styles['form-container-head']}>
					<p className={styles['form-container-head-title']}>
						Payment Info
					</p>
					{/* <p className={styles['form-container-head-warning']}>
						<sup>*</sup> Indicated mandatory fields
					</p> */}
				</div>

				<Row className={styles['form-container-card']}>
					<Col span={24} className={styles['col']}>
						<FormItem
							label={
								<span className={styles['bold-label']}>
									Pay Point
								</span>
							}
							name={'payPoint'}
							rules={[
								{
									required: false,
									message: 'Pay Point is required',
									validateTrigger: 'onChange',
								},
							]}
						>
							<Select
								placeholder="Select Pay Point"
								className={`${styles['side-drawer-form--select']} ${styles['small-select']}`}
								size="middle"
								value={selectedValue}
								onChange={handleDropdownChange}
							>
								<Select.Option value="1">
									01- Pay Office
								</Select.Option>
								<Select.Option value="2">
									02- Direct Deposit
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>

				<div className={styles.collapse}>
					<div className={styles['collapse-menu']}>
						<p className={styles['collapse-title']}>
							How would you like to pay this employee?{' '}
							<sup className={styles['collapse-required']}>*</sup>
						</p>
						{/* <div> */}{' '}
						<CustomCollapsePanel
							header="Direct Deposit (Automated Process)"
							icon={<RightArrowSvg />}
							isOpen={isOpen}
						>
							<DirectDeposit />
						</CustomCollapsePanel>
						{/* </div> */}
						<CustomCollapsePanel
							header="Bank Transfer (Manual Process)"
							icon={<BankSvg />}
							isOpen={isOpen}
						>
							<BankTransfer />
						</CustomCollapsePanel>
						<CustomCollapsePanel
							header="Cheque"
							icon={<ChequeSvg />}
							isOpen={isOpen}
						>
							<Cheque />
						</CustomCollapsePanel>
						<CustomCollapsePanel
							header="Cash"
							icon={<CashSvg />}
							isOpen={isOpen}
						>
							<div>Cash</div>
						</CustomCollapsePanel>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentInfo;
