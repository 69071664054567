import { createSlice } from '@reduxjs/toolkit';
import { fetchBranchCodeAction } from '../actions/branchCodeAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const BranchCodeSlice = createSlice({
	name: 'branchCode',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBranchCodeAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchBranchCodeAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(
			fetchBranchCodeAction.rejected,
			(state, action: any) => {
				state.isLoading = false;
				state.error = action.payload;
			}
		);
	},
});

export default BranchCodeSlice;
