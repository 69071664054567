import { deleteApi, getApi, postApi } from 'apis';

const getTimeSheets = async (data: any) => {
	return await getApi('/timeSheets', data);
};

const getTimeSheetsLogs = async (timeSheetId: any) => {
	return await getApi(`/timeSheets/logs/${timeSheetId}`);
};

const createTimeSheets = async (data: any) => {
	return await postApi('/timeSheets', data);
};

const deleteTimeSheet = async (id: string) => {
	return await deleteApi(`/timeSheets/${id}`);
};

export const timeSheetsApi = {
	getTimeSheets,
	deleteTimeSheet,
	createTimeSheets,
	getTimeSheetsLogs,
};
