import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDepartments } from 'Api/masters/department';

export const fetchDepartmentAction = createAsyncThunk(
	'department',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getAllDepartments(params);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
