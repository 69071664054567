import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	Modal,
	List,
	Spin,
	Row,
	Typography,
	Button,
	Col,
	Divider,
	Table,
} from 'antd';
import { postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import React, { useState } from 'react';
import { toastText } from 'utils/utils';

interface AddModalProps {
	isModalOpen: boolean;
	onClose: () => void;
	currency: any | null;
	branches: any[];
	departments: any[];
	totalEmployees: number;
	employees: any[];
	isEmployeesLoading: boolean;
	onRemoveEmployee: (employeeId: string) => void;
	payGroupName: string;
	fetchPaygroup: any;
	closeDrawerByAnimation: () => void;
}

const AddModal: React.FC<AddModalProps> = ({
	isModalOpen,
	onClose,
	currency,
	branches,
	departments,
	totalEmployees,
	employees,
	isEmployeesLoading,
	onRemoveEmployee,
	payGroupName,
	fetchPaygroup,
	closeDrawerByAnimation,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const buttons = [
		{
			text: 'Create Pay Group',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: totalEmployees === 0 || isLoading,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancelNew',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onClose();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const currencyId = currency?.value || '';
			const branchIds = branches.map((branch) => branch.id);
			const departmentIds = departments.map(
				(department) => department.id
			);
			const employeeIds = employees.map((employee) => employee.id);

			const data = {
				name: payGroupName,
				branchIds,
				departmentIds,
				employeeIds,
				companyCurrencyId: currencyId,
			};

			await postApi('/payGroup/', data);
			onClose();
			closeDrawerByAnimation();
			fetchPaygroup();
			toastText('Pay Group created successfully', 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to department';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Value',
			dataIndex: 'value',
			key: 'value',
		},
	];

	const dataSource = [
		{
			label: 'Currency',
			value: currency?.label || 'N/A',
		},
		{
			label: 'Branches',
			value:
				branches.length > 0
					? branches.map((branch) => branch.name).join(', ')
					: 'No branches available',
		},
		{
			label: 'Departments',
			value:
				departments.length > 0
					? departments
							.map((department) => department.name)
							.join(', ')
					: 'No departments available',
		},
	];

	return (
		<Modal open={isModalOpen} footer={null} closable={false} width={800}>
			{isEmployeesLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '200px', 
					}}
				>
					<Spin size="large" />
				</div>
			) : (
				<>
					<div
						style={{
							padding: '16px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: '16px',
									 marginTop: '0px', 
								}}
							>
								<Typography.Title
									level={3}
			        style={{ margin: '0', padding: '0' }}
								>
									<span>Pay group : {payGroupName}</span>
								</Typography.Title>
								<Button
									icon={<CloseOutlined />}
									onClick={onClose}
								/>
							</div>
							<Table
								columns={columns}
								dataSource={dataSource}
								pagination={false}
								bordered={false} // Remove table borders
								style={{
									marginTop : '0px',
									marginBottom: '25px',
									border: 'none',
									maxHeight: '120px', // Set max height to show only 3 rows
								}}
								showHeader={false} // Hide table header
								rowKey={(record) => record.label}
								size="middle" // Optional: Adjust row height
							/>
							<Col span={24}>
								{' '}
								<p
									style={{
										marginTop: '15px',
										fontSize: '14px',
										fontWeight: 'bold',
									}}
								>
									Total number of Employees: {totalEmployees}
								</p>
							</Col>
							<Col span={24}>
								<Typography.Title
									level={5}
									style={{
										marginBottom: '8px',
										marginTop: '15px',
									}}
								>
									Employee Name
								</Typography.Title>
								<List
									dataSource={employees}
									renderItem={(employee) => (
										<List.Item
											actions={[
												<Button
													type="text"
													icon={
														<CloseOutlined
															style={{
																color: '#d9d9d9',
															}}
														/>
													}
													onClick={() =>
														onRemoveEmployee(
															employee.employeeId
														)
													}
												/>,
											]}
										>
											{employee.firstName}{' '}
											{employee.lastName} -{' '}
											<span
												style={{
													color: '#584495',
												}}
											>
												{employee.employeeId}
											</span>
										</List.Item>
									)}
									style={{
										maxHeight: '200px',
										overflowY: 'auto',
										// border: '1px solid #f0f0f0',
										padding: '10px',
									}}
								/>
							</Col>
							{/* </Row> */}
						</div>
					</div>
					{/* </div> */}
					<div style={{ marginTop: '16px'  , marginBottom: '20px'}}>
						{' '}
						<Buttons buttons={buttons} />
					</div>
				</>
			)}
		</Modal>
	);
};

export default AddModal;
