import { PermissionContext } from 'components/Global/AuthLayout';
import UsersTable from 'components/Settings/User';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export const Users = () => {
	const context = useContext(PermissionContext);
	const navigate = useNavigate();
const check = context.allowedPermissions.includes('Allow_Users');

return <div>{check && <UsersTable />}</div>;
};
