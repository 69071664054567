import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
	getAllowedModule,
	getAllowedPermissions,
	invalidText,
} from 'utils/utils';
import { jwtDecode } from 'jwt-decode';
import { AppDispatch } from '../../../redux/store';
import { fetchProfileAction } from '../../../redux/actions/profileAction';
import {
	keyWiseConfigurationRouteMapping,
	keyWisePayrollSettingsRouteMapping,
	keyWiseSettingsRouteMapping,
} from 'utils/mappings';

export const PermissionContext = createContext<any>({
	permissions: null,
	companyId: null,
	userId: null,
});

export const AuthLayout = () => {
	const authpath = [
		'/login',
		'/reset-password',
		'/forgot-password',
		'/set-password',
		'/register',
	];

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [permissions, setPermissions] = useState([]);
	const [companyId, setCompanyId] = useState('');
	const [userId, setUserId] = useState('');
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [allowedPermissions, setAllowedPermissions] = useState<any>([]);

	const { pathname } = useLocation();
	const token = localStorage.getItem('accessToken');
	const path = window.location.pathname;

	const fetchProfile = async () => {
		const response = await dispatch(fetchProfileAction());
		if (response.payload?.responseStatus === 401) {
			navigate('/login');
		}
		setPermissions(response?.payload.data?.permissions);

		const _allowedPermissions = getAllowedPermissions(
			response?.payload.data?.permissions
		);
		setAllowedPermissions(_allowedPermissions);
	};

	useEffect(() => {
		if (!authpath.includes(path) && invalidText(token)) {
			navigate('/login');
		}
	}, [pathname]);

	useEffect(() => {
		if (token) {
			fetchProfile();
			if (path === '/login') {
				navigate('/');
			}
		}
	}, []);

	useEffect(() => {
		if (token) {
			try {
				const userData: User = jwtDecode(token as string);
				if (userData) {
					setCompanyId(userData?.companyId);
					setUserId(userData?.id);
					setIsSuperAdmin(userData?.isSuperAdmin);
					setIsAdmin(userData?.isAdmin);
				}
			} catch (error: any) {
				localStorage.removeItem('accessToken');
				navigate('/login');
			}
		}
	}, [token]);

	useEffect(() => {
		if (permissions?.length > 0) {
			const splitArray = pathname.split('/');
			let key = splitArray[splitArray?.length - 1];

			let allowedConfiguration = getAllowedModule(permissions, [
				'Branch Code',
				'Company',
				'Category Code',
				'Job Title',
				'Pay Point',
				'Departments',
				'Cost Center',
				'Job Grade',
				// 'Earning',
				// 'Deduction',
				'Holidays',
				'Leave Management',
			]);

			const allowedPayrollSettings = getAllowedModule(permissions, [
				'Pay Schedule',
				'Pay Period',
				'Statutory Components',
				'Mid-Month Pay Setup',
				'Payroll History',
				'Deduction Code',
				'Earnings Code',
			]);

			let allowedSettingsModule = getAllowedModule(permissions, [
				'Users',
				'Roles',
			]);

			if (isSuperAdmin) {
				allowedConfiguration = 'Company';
				allowedSettingsModule = 'Users';
			}

			if (
				invalidText(allowedConfiguration) &&
				invalidText(allowedSettingsModule) &&
				invalidText(allowedPayrollSettings)
			) {
				navigate('/');
			} else {
				if (key === 'settings' && allowedSettingsModule) {
					const _key =
						keyWiseSettingsRouteMapping[
							allowedSettingsModule.toLowerCase() as keyof typeof keyWiseSettingsRouteMapping
						];
					navigate(`/settings/${_key}`);
				} else if (key === 'configuration' && allowedConfiguration) {
					const _key =
						keyWiseConfigurationRouteMapping[
							allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
						];
					navigate(`/settings/configuration/${_key}`);
				} else if (key === 'branch-code') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Branch_Code') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/branch-code`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'category-code') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Category_Code') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/category-code`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'department') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Departments') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/department`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'job-grade') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Job_Grade') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/job-grade`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'job-title') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Job_Title') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/job-title`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'cost-center') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Cost_Center') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/cost-center`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
					// } else if (key === 'earning') {
					// 	if (isSuperAdmin) {
					// 		navigate(`/settings/configuration/company`);
					// 	} else if (invalidText(allowedConfiguration)) {
					// 		navigate('/settings');
					// 	} else if (
					// 		allowedPermissions.includes('Allow_Earning') &&
					// 		allowedConfiguration
					// 	) {
					// 		navigate(`/settings/configuration/earning`);
					// 	} else {
					// 		const _key =
					// 			keyWiseConfigurationRouteMapping[
					// 				allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
					// 			];
					// 		navigate(`/settings/configuration/${_key}`);
					// 	}
					// } else if (key === 'deduction') {
					// 	if (isSuperAdmin) {
					// 		navigate(`/settings/configuration/company`);
					// 	} else if (invalidText(allowedConfiguration)) {
					// 		navigate('/settings');
					// 	} else if (
					// 		allowedPermissions.includes('Allow_Deduction') &&
					// 		allowedConfiguration
					// 	) {
					// 		navigate(`/settings/configuration/deduction`);
					// 	} else {
					// 		const _key =
					// 			keyWiseConfigurationRouteMapping[
					// 				allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
					// 			];
					// 		navigate(`/settings/configuration/${_key}`);
					// 	}
				} else if (key === 'leave-management') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Leave_Management') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/leave-management`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'holidays') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else if (invalidText(allowedConfiguration)) {
						navigate('/settings');
					} else if (
						allowedPermissions.includes('Allow_Holidays') &&
						allowedConfiguration
					) {
						navigate(`/settings/configuration/holidays`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'company') {
					if (isSuperAdmin) {
						navigate(`/settings/configuration/company`);
					} else {
						const _key =
							keyWiseConfigurationRouteMapping[
								allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
							];
						navigate(`/settings/configuration/${_key}`);
					}
				} else if (key === 'users') {
					if (
						allowedPermissions.includes('Allow_Users') &&
						allowedSettingsModule
					) {
						navigate('settings/users');
					} else {
						const _key =
							keyWiseSettingsRouteMapping[
								allowedSettingsModule.toLowerCase() as keyof typeof keyWiseSettingsRouteMapping
							];
						navigate(`/settings/${_key}`);
					}
				} else if (key === 'roles') {
					if (
						allowedPermissions.includes('Allow_Roles') &&
						allowedSettingsModule
					) {
						navigate('settings/roles');
					} else {
						const _key =
							keyWiseSettingsRouteMapping[
								allowedSettingsModule.toLowerCase() as keyof typeof keyWiseSettingsRouteMapping
							];
						navigate(`/settings/${_key}`);
					}
				} else if (
					key === 'payroll-settings' &&
					allowedPayrollSettings
				) {
					const _key =
						keyWisePayrollSettingsRouteMapping[
							allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
						];
					navigate(`/settings/payroll-settings/${_key}`);
				} else if (key === 'company-setup') {
					if (
						allowedPermissions.includes('Allow_Company_Setup') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/company-setup`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'pay-period') {
					if (
						allowedPermissions.includes('Allow_Pay_Period') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/pay-period`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'pay-group') {
					if (
						allowedPermissions.includes('Allow_Pay_Group') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/pay-group`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'statutory-components') {
					if (
						allowedPermissions.includes(
							'Allow_Statutory_Components'
						) &&
						allowedPayrollSettings
					) {
						navigate(
							`/settings/payroll-settings/statutory-components`
						);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'earning-code') {
					if (
						allowedPermissions.includes('Allow_Earnings_Code') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/earning-code`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'deduction-code') {
					if (
						allowedPermissions.includes('Allow_Deduction_Code') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/deduction-code`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'mid-month-pay') {
					if (
						allowedPermissions.includes(
							'Allow_Mid-Month_Pay_Setup'
						) &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/mid-month-pay`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				} else if (key === 'payroll-history') {
					if (
						allowedPermissions.includes('Allow_Payroll_History') &&
						allowedPayrollSettings
					) {
						navigate(`/settings/payroll-settings/payroll-history`);
					} else {
						const _key =
							keyWisePayrollSettingsRouteMapping[
								allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
							];
						navigate(`/settings/payroll-settings/${_key}`);
					}
				}
			}
		}
	}, [permissions, isSuperAdmin]);

	const permissionHandler = (
		_permissions: any,
		companyId: string,
		userId: string,
		isSuperAdmin: boolean,
		isAdmin: boolean,
		isClear?: boolean
	) => {
		setPermissions(_permissions);
		setCompanyId(companyId);
		setUserId(userId);
		setIsSuperAdmin(isSuperAdmin);
		setIsAdmin(isAdmin);
		const _allowedPermissions = getAllowedPermissions(
			isClear ? [] : _permissions
		);
		setAllowedPermissions(_allowedPermissions);
	};

	useEffect(() => {
		if (permissions?.length > 0) {
			setPermissions(permissions);
		}
	}, [permissions]);
	return (
		<PermissionContext.Provider
			value={{
				permissions,
				permissionHandler,
				userId,
				isAdmin,
				companyId,
				isSuperAdmin,
				allowedPermissions,
			}}
		>
			<Outlet />
		</PermissionContext.Provider>
	);
};

interface User {
	email: string;
	exp: number;
	iat: number;
	id: string;
	type: string;
	isAdmin: boolean;
	permissions: any;
	isSuperAdmin: boolean;
	companyId: string;
}
