import { Tooltip } from 'antd';
import styles from './index.module.scss';
import { EyeIcon } from 'utils/svgs';
import dayjs from 'dayjs';
type Props = {
	monthAndAmount: any;
};
const TooltipContent = ({ monthAndAmount }: Props) => {
	return (
		<div className={styles['tooltip-content']}>
			<div className={styles['tooltip-header']}>
				<span>Month</span>
				<span>Amount</span>
			</div>
			{monthAndAmount.map((entry: any, index: any) => (
				<div key={index} className={styles['tooltip-row']}>
					<span className={styles['tooltip-cell']}>
						{dayjs().month(entry.month-1).format('MMMM')}
					</span>
					<span className={styles['tooltip-cell']}>
						{entry.amount.toFixed(2)}
					</span>
				</div>
			))}
		</div>
	);
};

const PersonalmonthAndAmountComponent = ({ monthAndAmount }: Props) => {
	return (
		<div className="ant-tooltip-container">
			<Tooltip
				title={<TooltipContent monthAndAmount={monthAndAmount} />}
				color="#fff"
				placement="top"
				overlayInnerStyle={{ width: 'max-content' }}
				className="ant-tooltip-paye"
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EyeIcon />
					<span>View Details</span>
				</div>
			</Tooltip>
		</div>
	);
};

export default PersonalmonthAndAmountComponent;
