import React, { useEffect, useState } from 'react';
import TableComponent from './Table';
import GlobalStatutoryHeader from '../GlobalHeader';
import GlobalInformationModal from '../GlobalInformationModal';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { toastText } from 'utils/utils';
import AddCommunicationModal from './AddCommunicationModal';
import UploadRecord from './UploadRecord';
import CalculatePayeModal from './CalculatePayeModal';
import { payeApi } from 'Api/payeApi';

const btnText = 'PAYE';

const PayeCalculateComponent = () => {
	const [extraDetailsPopover, setExtraDetailsPopover] = useState(false);
	const [addCommunicationModal, setAddCommunicationModal] = useState(false);
	const [calculateModalOpen, setCalculateModalOpen] = useState(false);
	const [informationModal, setInformationModal] = useState(false);
	const [uploadRecordModal, setUploadRecordModal] = useState(false);

	const [payeInformationCurrentPage, setPayeInformationCurrentPage] =
		useState(1);
	const [payeInformationPageSize, setPayeInformationPageSize] = useState(10);
	const [isInformationLoading, setIsInformationLoading] = useState(false);
	const [payeInformationTotalRecords, setPayeInformationTotalRecords] =
		useState(0);
	const [payeInformationSortField, setPayeInformationSortField] =
		useState('');
	const [payeInformationSortOrder, setPayeInformationSortOrder] =
		useState('');
	const [payeInformationData, setPayeInformationData] = useState<any>([]);

	const [calculatePayeCurrentPage, setCalculatePayeCurrentPage] = useState(1);
	const [calculatePayePageSize, setCalculatePayePageSize] = useState(10);
	const [calculatePayeTotalRecords, setCalculatePayeTotalRecords] =
		useState(0);
	const [calculatePayeSortField, setCalculatePayeSortField] = useState('');
	const [calculatePayeSortOrder, setCalculatePayeSortOrder] = useState('');
	const [calculatePayeIsLoading, setCalculatePayeIsLoading] = useState(false);
	const [calculatePayeData, setCalculatePayeData] = useState<any>([]);
	const [payeRecordId, setPayeRecordId] = useState<any>(null);

	const openUploadRecordModal = (payeRecordId: string) => {
		setUploadRecordModal(true);
		setPayeRecordId(payeRecordId);
	};

	const toggleExtraDetails = () => {
		setExtraDetailsPopover(!extraDetailsPopover);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setPayeInformationTotalRecords(pagination.total);
		setPayeInformationCurrentPage(pagination.current);
		setPayeInformationPageSize(pagination.pageSize);
		setPayeInformationSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setPayeInformationSortField(sorter.field);
	};

	const tableChangeHandlerForCalculatePaye = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setCalculatePayeTotalRecords(pagination.total);
		setCalculatePayeCurrentPage(pagination.current);
		setCalculatePayePageSize(pagination.pageSize);
		setCalculatePayeSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setCalculatePayeSortField(sorter.field);
	};

	const openAddCommunicationModal = () => {
		setAddCommunicationModal(true);
		setExtraDetailsPopover(false);
	};

	const closeAllModal = () => {
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setInformationModal(false);
		setExtraDetailsPopover(false);
		setUploadRecordModal(false);
		setPayeRecordId(null);
	};

	const openInformationModal = () => {
		setInformationModal(true);
		setExtraDetailsPopover(false);
	};

	const openCalculateModal = () => {
		setCalculateModalOpen(true);
		setExtraDetailsPopover(false);
	};

	async function fetchInformationDetails() {
		try {
			const query = {
				page: payeInformationCurrentPage,
				sortBy: payeInformationSortField,
				sortOrder: payeInformationSortOrder,
				pageSize: payeInformationPageSize,
			};

			setIsInformationLoading(true);
			const allData = await payeApi.getAllCommunication(query);

			setPayeInformationData(allData.data.data);

			setPayeInformationTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching Information',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsInformationLoading(false);
		}
	}

	async function fetchPayeReportDetails() {
		try {
			const query = {
				page: calculatePayeCurrentPage,
				sortBy: calculatePayeSortField,
				sortOrder: calculatePayeSortOrder,
				pageSize: calculatePayePageSize,
			};
			setCalculatePayeIsLoading(true);
			const allData = await payeApi.getPayeReports(query);

			setCalculatePayeData(allData.data.data);

			setCalculatePayeTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching Calculate Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setCalculatePayeIsLoading(false);
		}
	}
	useEffect(() => {
		fetchInformationDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		payeInformationPageSize,
		payeInformationCurrentPage,
		payeInformationSortField,
		payeInformationSortOrder,
	]);

	useEffect(() => {
		fetchPayeReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculatePayePageSize,
		calculatePayeCurrentPage,
		calculatePayeSortOrder,
		calculatePayeSortField,
	]);

	useEffect(() => {
		closeAllModal();
	}, []);
	return (
		<>
			<GlobalStatutoryHeader
				btnText={btnText}
				openCalculateModal={openCalculateModal}
				openInformationModal={openInformationModal}
				openAddCommunicationModal={openAddCommunicationModal}
				extraDetailsPopover={extraDetailsPopover}
				toggleExtraDetails={toggleExtraDetails}
			/>

			<TableComponent
				calculatePayeCurrentPage={calculatePayeCurrentPage}
				calculatePayePageSize={calculatePayePageSize}
				calculatePayeTotalRecords={calculatePayeTotalRecords}
				calculatePayeIsLoading={calculatePayeIsLoading}
				calculatePayeData={calculatePayeData}
				tableChangeHandler={tableChangeHandlerForCalculatePaye}
				openUploadRecordModal={openUploadRecordModal}
			/>

			<GlobalInformationModal
				open={informationModal}
				InformationData={payeInformationData}
				closeInformationModal={closeAllModal}
				totalRecords={payeInformationTotalRecords}
				pageSize={payeInformationPageSize}
				loading={isInformationLoading}
				currentPage={payeInformationCurrentPage}
				tableChangeHandler={tableChangeHandler}
			/>
			<CalculatePayeModal
				open={calculateModalOpen}
				cancelCalculateModal={closeAllModal}
				fetchPayeReportDetails={fetchPayeReportDetails}
			/>

			<UploadRecord
				open={uploadRecordModal}
				closeUploadRecordModal={closeAllModal}
				fetchPayeReportDetails={fetchPayeReportDetails}
				payeRecordId={payeRecordId}
			/>

			<AddCommunicationModal
				open={addCommunicationModal}
				closeAddCommunicationModal={closeAllModal}
				fetchInformationDetails={fetchInformationDetails}
			/>
		</>
	);
};

export default PayeCalculateComponent;
