import { Space } from 'antd';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	button: any;
};

const PayGroupHeader = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Pay_Group');
	const { button } = props;

	return (
		<div className={styles['employee-header']}>
			{isAdd && (
				<Space>
					<Buttons buttons={button} />
				</Space>
			)}
		</div>
	);
};

export default PayGroupHeader;
