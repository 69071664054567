import { Card, Col, Row } from 'antd';
import React from 'react';
import styles from './index.module.scss';

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	employeeId: string;
	employeeData: any;
	getEmployeeDetail: (employeeId: string) => void;
	fetchEmployees: any;
};

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};

const EmployeeDetails = ({ employeeData }: Props) => {
	const maskSSN = (ssn: string) => {
		if (ssn.length < 9) return ssn; // Handle cases where SSN is not in the expected format
		return `*****${ssn.slice(-4)}`;
	};

	return (
		<div className={styles['scrollable-content']}>
			<Row gutter={16}>
				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Personal Details
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>First Name:</b> {employeeData.firstName}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Last Name:</b> {employeeData.lastName}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Date of Birth:</b>{' '}
									{formatDate(employeeData.dateOfBirth)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Gender:</b> {employeeData.gender}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Country:</b> {employeeData.country}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Marital Status:</b>{' '}
									{employeeData.maritalStatus}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Employee Details
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>Joining Date:</b>{' '}
									{formatDate(employeeData.joiningDate)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Branch:</b> {employeeData.branch.name}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Department:</b>{' '}
									{employeeData.department.name}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Cost Center:</b>{' '}
									{employeeData.costCenter.name}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Category:</b>{' '}
									{employeeData.category.name}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Job Grade:</b>{' '}
									{employeeData.jobGrade.position}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Staff Code:</b> {employeeData.staffCode}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Identification Information
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>SSN:</b> {maskSSN(employeeData.ssn)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Record Status:</b>{' '}
									{employeeData.recordStatus}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Passport No:</b>{' '}
									{employeeData.passportNo}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>National Registration No:</b>{' '}
									{employeeData.nationalRegistrationNo}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default EmployeeDetails;
