import {
	CloseOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import InputNumberField from 'components/Global/InputNumberField';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import InputField from 'components/Global/InputField';
import { leaveManagementApi } from 'Api/masters/leave-management';
import { formatToDateOnly, toastText } from 'utils/utils';
import CheckBox from 'components/Global/Checkbox';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';

type ConfigRuleModalProps = {
	open: boolean;
	configLeaveId: string;
	closeModal: () => void;
	fetchLeaveManagementData: any;
};

const ConfigRuleModal = (props: ConfigRuleModalProps) => {
	const { open, closeModal, configLeaveId, fetchLeaveManagementData } = props;
	const [loading, setLoading] = useState(false);
	const [configLoading, setConfigLoading] = useState(false);
	const [rows, setRows] = useState<any>([]);
	const [effectiveDate, setEffectiveDate] = useState<any>(dayjs());
	const [attachmentRequired, setAttachmentRequired] = useState(false);
	const [errors, setErrors] = useState<any>({});

	const handleDate = (value: any) => {
		setEffectiveDate(value);
	};

	const handleInputChange = (
		index: number,
		field: string,
		value: number | null
	) => {
		const newRows = [...rows];
		newRows[index][field] = value;
		setRows(newRows);

		const newErrors = { ...errors };
		if (!newErrors[index]) newErrors[index] = {};

		const validateField = (rowIndex: number, fieldName: string) => {
			if (rowIndex < 0 || rowIndex >= newRows.length) return;

			const row = newRows[rowIndex];
			const prev = rowIndex > 0 ? newRows[rowIndex - 1] : null;
			const next =
				rowIndex < newRows.length - 1 ? newRows[rowIndex + 1] : null;

			if (!newErrors[rowIndex]) newErrors[rowIndex] = {};

			switch (fieldName) {
				case 'minMonth':
					console.log('prev: ', prev);
					console.log('next: ', next);
					console.log('row.minMonth: ', row.minMonth);
					if (
						row.minMonth === null ||
						(prev &&
							prev.maxMonth !== null &&
							row.minMonth <= prev.maxMonth) ||
						(next &&
							next.minMonth !== null &&
							row.minMonth >= next.minMonth &&
							row.minMonth > next.minMonth) ||
						row.minMonth <= prev.minMonth
					) {
						newErrors[rowIndex][fieldName] = 'Invalid min month';
					} else {
						delete newErrors[rowIndex][fieldName];
					}
					break;
				case 'maxMonth':
					if (
						row.maxMonth !== null &&
						row.maxMonth !== Infinity &&
						((row.minMonth !== null &&
							row.maxMonth <= row.minMonth) ||
							(next &&
								next.minMonth !== null &&
								row.maxMonth >= next.minMonth))
					) {
						newErrors[rowIndex][fieldName] = 'Invalid max month';
					} else {
						delete newErrors[rowIndex][fieldName];
					}
					break;
				case 'leaves':
					if (
						row.leaves === null ||
						(prev &&
							prev.leaves !== null &&
							row.leaves <= prev.leaves) ||
						(next &&
							next.leaves !== null &&
							row.leaves >= next.leaves)
					) {
						newErrors[rowIndex][fieldName] =
							'Invalid number of leaves';
					} else {
						delete newErrors[rowIndex][fieldName];
					}
					break;
			}
		};

		validateField(index, field);

		Object.keys(newErrors).forEach((key) => {
			if (Object.keys(newErrors[Number(key)]).length === 0) {
				delete newErrors[Number(key)];
			}
		});

		setErrors(newErrors);
	};

	const isRowFilled = () => {
		return rows.every((row: any, index: number) => {
			if (index === 0) {
				if (row.minMonth === null || row.leaves === null) {
					return false;
				}
				return (
					row.maxMonth &&
					!errors[index]?.maxMonth &&
					!errors[index]?.leaves &&
					!errors[index]?.minMonth
				);
			} else {
				return (
					row.minMonth &&
					row.maxMonth &&
					row.leaves &&
					!errors[index]?.minMonth &&
					!errors[index]?.maxMonth &&
					!errors[index]?.leaves
				);
			}
		});
	};

	const addRow = (index: number) => {
		const newRows = [...rows];
		newRows.push({
			minMonth: null,
			maxMonth: Infinity,
			leaves: null,
		});
		if (index === 1) {
			setRows(newRows);
			return;
		}
		newRows[newRows.length - 2].maxMonth = null;
		setRows(newRows);
	};

	const removeRow = (index: number) => {
		const newRows = rows.filter((_: any, i: number) => i !== index);
		newRows[newRows.length - 1].maxMonth = Infinity;
		setRows(newRows);
		const newErrors = { ...errors };
		delete newErrors[index];
		setErrors(newErrors);
	};

	const closeConfigurationModal = () => {
		setRows([]);
		setErrors({});
		closeModal();
		setAttachmentRequired(false);
		setEffectiveDate(dayjs());
		setConfigLoading(false);
	};

	const handleSave = async () => {
		let isValid = true;
		const newErrors = { ...errors };

		rows.forEach((row: any, index: number) => {
			if (index === 0) {
				if (row.minMonth === null || row.minMonth < 0) {
					isValid = false;
					if (!newErrors[index]) newErrors[index] = {};
					newErrors[index].minMonth = 'Min month is invalid';
				} else {
					if (newErrors[index]) delete newErrors[index].minMonth;
				}

				if (row.leaves === null || row.leaves < 0) {
					isValid = false;
					if (!newErrors[index]) newErrors[index] = {};
					newErrors[index].leaves = 'Number of leaves is invalid';
				} else {
					if (newErrors[index]) delete newErrors[index].leaves;
				}
			} else {
				if (row.minMonth === null || row.minMonth <= 0) {
					isValid = false;
					if (!newErrors[index]) newErrors[index] = {};
					newErrors[index].minMonth = 'Min month is invalid';
				} else {
					if (newErrors[index]) delete newErrors[index].minMonth;
				}

				if (row.leaves === null || row.leaves <= 0) {
					isValid = false;
					if (!newErrors[index]) newErrors[index] = {};
					newErrors[index].leaves = 'Number of leaves is invalid';
				} else {
					if (newErrors[index]) delete newErrors[index].leaves;
				}
			}

			if (
				row.maxMonth === null ||
				(row.maxMonth !== Infinity && row.maxMonth <= row.minMonth)
			) {
				isValid = false;
				if (!newErrors[index]) newErrors[index] = {};
				newErrors[index].maxMonth = 'Max month is invalid';
			} else {
				if (newErrors[index]) delete newErrors[index].maxMonth;
			}
		});

		setErrors(newErrors);
		if (
			new Date(effectiveDate).getTime() < new Date().setHours(0, 0, 0, 0)
		) {
			toastText('Effective date cannot be in the past', 'error');
			return;
		}
		if (isValid) {
			try {
				setLoading(true);
				const _configurationRules = rows.map((row: any) => {
					return {
						minMonth: row.minMonth,
						maxMonth: row.maxMonth === Infinity ? -1 : row.maxMonth,
						leaves: row.leaves,
					};
				});
				await leaveManagementApi.addConfigurationRoles(configLeaveId, {
					configurationRules: _configurationRules,
					attachmentRequired,
					effectiveDate: formatToDateOnly(effectiveDate),
				});
				toastText('Configuration saved successfully', 'success');
				fetchLeaveManagementData();
				closeConfigurationModal();
			} catch (error) {
				toastText(
					'Something went wrong in saving configuration',
					'error'
				);
			} finally {
				setLoading(false);
			}
		} else {
			return;
		}
	};

	const buttons = [
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: handleSave,
			minWidth: '10rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: closeConfigurationModal,
			minWidth: '10rem',
		},
	];

	const fetchConfigurations = async () => {
		try {
			setConfigLoading(true);
			const response = await leaveManagementApi.getLeaveConfigurations(
				configLeaveId
			);
			if (response?.data?.data?.length === 0) {
				setRows([{ minMonth: 0, maxMonth: null, leaves: 0 }]);
			} else {
				const configurationRules = response?.data?.data.sort(
					(a: any, b: any) => a.minMonth - b.minMonth
				);

				configurationRules.forEach((rule: any) => {
					if (rule.maxMonth === -1) {
						rule.maxMonth = Infinity;
					}
				});

				setAttachmentRequired(response?.data?.attachmentRequired);
				setEffectiveDate(dayjs(response?.data?.effectiveDate));
				setRows(configurationRules);
			}
		} catch (err) {
		} finally {
			setConfigLoading(false);
		}
	};

	useEffect(() => {
		if (open && configLeaveId) {
			fetchConfigurations();
		}
	}, [open, configLeaveId]);
	return (
		<Modal
			open={open}
			onCancel={closeConfigurationModal}
			footer={null}
			width={1000}
			maskClosable={true}
			closable={false}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Title level={4}>Configure Leave</Title>
					<div
						className={styles['close-icon']}
						onClick={closeConfigurationModal}
					>
						<CloseOutlined />
					</div>
				</div>
				{configLoading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<LoadingOutlined
							style={{ fontSize: '100px', color: '#584495' }}
						/>
					</div>
				) : (
					<div className={styles['modal-body']}>
						<Row
							align={'stretch'}
							className={styles['row']}
							style={{
								margin: '0px -25px',
							}}
						>
							<Col span={6} className={styles['col']}>
								<DatePickerField
									isError={false}
									label="Effective Date"
									name="effectiveDate"
									value={effectiveDate}
									required={true}
									placeholder="DOB"
									disabledBeforeDates={dayjs(effectiveDate)}
									onChange={(value) => {
										handleDate(value);
									}}
								/>
							</Col>
							<Col
								className={styles['checkbox-col col']}
								span={8}
							>
								<div className={styles['checkbox-wrapper']}>
									<CheckBox
										checked={attachmentRequired}
										label="Attachment Required"
										onChange={(value) =>
											setAttachmentRequired(value)
										}
									/>
								</div>
							</Col>
						</Row>
						<Row
							className={styles['form-container-card']}
							gutter={50}
						>
							{rows &&
								rows?.map((row: any, index: number) => (
									<Row
										key={index}
										className={styles['row']}
										align="middle"
										gutter={15}
									>
										<Col span={4} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputNumberField
													name="CurrentMonth"
													placeholder="Current Month"
													isError={false}
													label=""
													required={false}
													disabled={true}
													size="large"
													onChange={() => {}}
												/>
											</div>
										</Col>
										<Col span={2} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputField
													name="-"
													placeholder="-"
													value={' - '}
													isError={false}
													label=""
													required={false}
													disabled={true}
													size="large"
													onChange={() => {}}
													style={{
														width: '100%',
														textAlign: 'center',
													}}
												/>
											</div>
										</Col>
										<Col span={4} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputNumberField
													name="Joining Month"
													placeholder="Joining Month"
													isError={false}
													label=""
													required={false}
													disabled={true}
													size="large"
													onChange={() => {}}
												/>
											</div>
										</Col>
										<Col span={4} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputNumberField
													required
													name="minMonth"
													placeholder="Min Month"
													style={{ width: '100%' }}
													helperText=""
													value={row.minMonth}
													size="large"
													onChange={(value: any) =>
														handleInputChange(
															index,
															'minMonth',
															value
														)
													}
													isError={false}
												/>
												<div
													className={
														styles['error-message']
													}
												>
													{errors[index]?.minMonth
														? 'Min month is invalid'
														: '\u00A0'}
												</div>
											</div>
										</Col>
										<Col span={4} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputNumberField
													required
													name="maxMonth"
													placeholder="Max Month"
													helperText=""
													value={row.maxMonth}
													size="large"
													style={{ width: '100%' }}
													disabled={
														row.maxMonth ===
														Infinity
													}
													onChange={(value: any) =>
														handleInputChange(
															index,
															'maxMonth',
															value
														)
													}
													isError={false}
												/>
												<div
													className={
														styles['error-message']
													}
												>
													{errors[index]?.maxMonth
														? 'Max month is invalid'
														: '\u00A0'}
												</div>
											</div>
										</Col>
										<Col span={5} className={styles['col']}>
											<div
												className={
													styles['input-container']
												}
											>
												<InputNumberField
													placeholder="Number of Leaves"
													style={{ width: '100%' }}
													helperText=""
													size="large"
													required
													name="leaves"
													value={row.leaves}
													onChange={(value: any) =>
														handleInputChange(
															index,
															'leaves',
															value
														)
													}
													isError={false}
												/>
												<div
													className={
														styles['error-message']
													}
												>
													{errors[index]?.leaves
														? 'Number of leaves is invalid'
														: '\u00A0'}
												</div>
											</div>
										</Col>
										<Col
											span={1}
											className={styles['col']}
											style={{ textAlign: 'center' }}
										>
											<div
												className={
													styles['icon-container']
												}
											>
												{index === 0 ? (
													<PlusCircleOutlined
														onClick={
															!isRowFilled()
																? undefined
																: () =>
																		addRow(
																			rows.length
																		)
														}
														className={
															styles['icon']
														}
														style={{
															color: !isRowFilled()
																? 'grey'
																: 'black',
															cursor: !isRowFilled()
																? 'not-allowed'
																: 'pointer',
														}}
													/>
												) : (
													<MinusCircleOutlined
														onClick={() =>
															removeRow(index)
														}
														className={
															styles['icon']
														}
													/>
												)}
											</div>
										</Col>
									</Row>
								))}
						</Row>
					</div>
				)}
				<Row className={styles['modal-buttons']}>
					<Buttons buttons={buttons} />
				</Row>
			</div>
		</Modal>
	);
};

export default ConfigRuleModal;
