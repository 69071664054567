import React, { useContext } from 'react';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import PayGroup from 'components/Settings/PayrollSettings/PayGroup';

export const PayGroupPage = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Pay_Group');

	return <>{check && <PayGroup/>}</>;
};
