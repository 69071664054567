import { getApi, postApi } from 'apis';

const createSalary = async (data: any) => {
	return await postApi('/employee/salary', data);
};

const getEmployeeSalaryMonthWise = async (data:any) => {
	return await getApi('/employee/monthly-salary', data);

}
const getEmployeeSalary = async (data: any) => {
	return await getApi('/employee/detailed-salary', data);
};

export const salaryApi = {
	createSalary,
	getEmployeeSalaryMonthWise,
	getEmployeeSalary,
};
