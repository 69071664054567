import { getApi, putApi } from 'apis';

const getNotificationConfig = async () => {
	return await getApi('/notification/config');
};

const updateNotificationConfig = async (data: any) => {
	return await putApi('/notification/config', data);
};

const getNotificationLength = async () => {
	return await getApi(`/notification/notificationlength`);
};

export const notificationApi = {
	getNotificationConfig,
	updateNotificationConfig,
	getNotificationLength
};
