import { EyeOutlined } from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import { Col, Collapse, CollapseProps, Row } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import dayjs from 'dayjs';
import {
	EmployeeDetailsInterface,
	IdentificationDetailsInterface,
	PersonalDetailsInterface,
} from 'interfaces/employee.interface';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { set } from 'lodash';

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	employeeId: string;
	employeeData: any;
	getEmployeeDetail: any;
	fetchEmployees: any;
	setEmployeeId: any;
};

const EmploymentDetails = (props: Props) => {
	const permissionsContext = useContext(PermissionContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(false);
	const [isSSNVisible, setIsSSNdVisible] = useState(false);
	const buttons = [
		{
			text: searchParams.get('employeeId')
				? 'Edit & Next'
				: 'Save & Next',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onCancel();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	const {
		changeTab,
		onCancel,
		employeeId,
		employeeData,
		getEmployeeDetail,
		fetchEmployees,
		setEmployeeId,
	} = props;
	const [hasError, setHasError] = useState(false);

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [personalDetails, setPersonalDetails] =
		useState<PersonalDetailsInterface>({
			firstName: '',
			lastName: '',
			dateOfBirth: '',
			gender: '',
			country: '',
			maritalStatus: '',
		});

	const [identificationDetails, setIdentificationDetails] =
		useState<IdentificationDetailsInterface>({
			ssn: '',
			recordStatus: '',
			passportNo: null,
			nationalRegistrationNo: '',
		});

	const [employeeDetails, setEmployeeDetails] =
		useState<EmployeeDetailsInterface>({
			joiningDate: '',
			branchId: '',
			departmentId: '',
			costCenterId: '',
			categoryId: '',
			jobGradeId: '',
			staffCode: '',
			nhimaNo: null,
			// supervisorIds: [],
		});

	// const [paymentDetails, setPaymentDetails] =
	// 	useState<PaymentDetailsInterface>({
	// 		payMethod: '',
	// 		payPoint: '',
	// 		totalSalary: 0,
	// 		napsaYearToDate: 0,
	// 		leavesDue: 0,
	// 		leavesDayMonth: 0,
	// 		recordStatus: '',
	// 	});

	const [formError, setFormError] = useState<any>({
		firstName: false,
		lastName: false,
		dateOfBirth: false,
		gender: false,
		country: false,
		maritalStatus: false,
		ssn: false,
		bankAccountNo: false,
		nationalRegistrationNo: false,
		joiningDate: false,
		branchId: false,
		departmentId: false,
		costCenterId: false,
		categoryId: false,
		jobGradeId: false,
		staffCode: false,
		salaryRate: false,
		payMethod: false,
		payPoint: false,
		totalSalary: false,
		napsaYearToDate: false,
		leavesDue: false,
		leavesDayMonth: false,
		recordStatus: false,
		// nhimaNumber: false, // supervisorIds: false,
	});

	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if (
			employeeId &&
			!permissionsContext.allowedPermissions.includes('Edit_Employment')
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [employeeId, permissionsContext.allowedPermissions]);

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		stateName: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'branchId') {
			const updatedEmployeeDetails: any = { ...employeeDetails };
			updatedEmployeeDetails.departmentId = '';
			updatedEmployeeDetails.costCenterId = '';
			setEmployeeDetails(updatedEmployeeDetails);
		}
		if (name === 'departmentId') {
			const updatedEmployeeDetails: any = { ...employeeDetails };
			updatedEmployeeDetails.costCenterId = '';
			setEmployeeDetails(updatedEmployeeDetails);
		}

		OnChange(value, name, stateName);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		stateName: string
	) => {
		switch (stateName) {
			case 'personalDetails':
				setPersonalDetails((prev) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'identificationDetails':
				setIdentificationDetails((prev) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'employeeDetails':
				setEmployeeDetails((prev) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
		}
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleError = (formData: any) => {
		setFormError(formData);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...personalDetails,
				...identificationDetails,
				...employeeDetails,
				// ...paymentDetails,
				// ...taxationDetails,
			},
			{ ...formError }
		);

		checkFormError = {
			...checkFormError,
			ssn: !/^\d{9}$/.test(identificationDetails.ssn),
			passportNo: identificationDetails.passportNo
				? !/^[A-Z0-9]{13}$/.test(identificationDetails.passportNo)
				: false,
			// supervisorIds: employeeDetails.supervisorIds.length === 0,
			// bankAccountNo: !/^\d{13}$/.test(
			// 	identificationDetails.bankAccountNo
			// ),
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				// employeeId,
				isDirector: false,
				...identificationDetails,
				...personalDetails,
				...employeeDetails,
				dateOfBirth: formatToDateOnly(personalDetails.dateOfBirth),
				joiningDate: formatToDateOnly(employeeDetails.joiningDate),
			};

			setIsLoading(true);
			if (!searchParams.get('employeeId')) {
				try {
					const createdEmployee = await employeeApi.createEmployee(
						data
					);
					setEmployeeId(createdEmployee?.data?.data?.employeeId);
					toastText('Employee on boarded successfully', 'success');
					changeTab('taxationDetails');
					setSearchParams({
						employeeId: createdEmployee?.data?.data?.id,
					});
					getEmployeeDetail(createdEmployee?.data?.data?.id);
				} catch (err: any) {
					const message =
						err?.response?.data?.message ||
						'Something went wrong in creating employee';
					toastText(message, 'error');
				}
			} else {
				try {
					const employee = await employeeApi.updateEmployee(
						searchParams.get('employeeId') as string,
						data
					);
					let message =
						employee?.data?.message ||
						'Employee updated successfully';
					toastText(message, 'success');
					getEmployeeDetail(searchParams.get('employeeId'));

					changeTab('taxationDetails');
				} catch (err: any) {
					const message =
						err?.response?.data?.message ||
						'Something went wrong in updating employee';
					toastText(message, 'error');
				}
			}
			fetchEmployees();
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (employeeData) {
			const updatedPersonalDetails: any = { ...personalDetails };
			const updatedIdentificationDetails: any = {
				...identificationDetails,
			};
			const updatedEmployeeDetails: any = { ...employeeDetails };

			for (const key in employeeData) {
				if (key in updatedPersonalDetails) {
					updatedPersonalDetails[key] = employeeData[key];
				} else if (key in updatedIdentificationDetails) {
					updatedIdentificationDetails[key] = employeeData[key];
				} else if (key in updatedEmployeeDetails) {
					updatedEmployeeDetails[key] = employeeData[key];
				}
			}

			setPersonalDetails({
				...updatedPersonalDetails,
				dateOfBirth: dayjs(updatedPersonalDetails.dateOfBirth),
			});
			setIdentificationDetails(updatedIdentificationDetails);
			setEmployeeDetails({
				...updatedEmployeeDetails,
				joiningDate: dayjs(updatedEmployeeDetails.joiningDate),
			});
		}
	}, [employeeData]);

	const formatInputValue = (value: string) => {
		// Remove any non-numeric characters
		const numericValue = value.replace(/\D/g, '');

		// Split the numeric value into parts based on the format 000000/00/0
		const part1 = numericValue.substring(0, 6);
		const part2 = numericValue.substring(6, 8);
		const part3 = numericValue.substring(8, 9);

		let formattedValue = part1;
		if (part2) {
			formattedValue += '/' + part2;
		}
		if (part3) {
			formattedValue += '/' + part3;
		}

		return formattedValue;
	};

	// const onChangesss = (key: string | string[]) => {
	// 	console.log(key);
	// };

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label: (
				<div
					style={{ fontSize: '1.8rem', fontWeight: 500 }}
					className="color-purple"
				>
					Personal Details
				</div>
			),
			children: (
				<Row className={styles['form-container-card']} gutter={50}>
					<Col span={6} className={styles['col']}>
						<InputField
							name="firstName"
							value={personalDetails.firstName}
							label="First Name"
							required={true}
							helperText="First name required"
							onChange={(value) => {
								handleChange(
									value,
									'firstName',
									'personalDetails',
									true
								);
							}}
							isError={formError.firstName}
							disabled={isDisabled}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<InputField
							name="lastName"
							value={personalDetails.lastName}
							label="Last Name"
							required={true}
							helperText="Last name required"
							onChange={(value) => {
								handleChange(
									value,
									'lastName',
									'personalDetails',
									true
								);
							}}
							disabled={isDisabled}
							isError={formError.lastName}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<DatePickerField
							label="DOB"
							name="dateOfBirth"
							value={personalDetails.dateOfBirth}
							required={true}
							placeholder="DOB"
							onChange={(value) => {
								handleChange(
									value,
									'dateOfBirth',
									'personalDetails',
									true
								);
							}}
							disabled={isDisabled}
							isError={formError.dateOfBirth}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							placeholder="Gender"
							options={dropdownOptions?.gender}
							value={personalDetails.gender}
							onChange={(value) => {
								handleChange(
									value,
									'gender',
									'personalDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Gender is required"
							label="Gender"
							disabled={isDisabled}
							isError={formError.gender}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							placeholder="Country"
							options={dropdownOptions?.country}
							value={personalDetails.country}
							onChange={(value) => {
								handleChange(
									value,
									'country',
									'personalDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Country is required"
							label="Country"
							disabled={isDisabled}
							isError={formError.country}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							placeholder="Marital Status"
							options={dropdownOptions?.maritalStatus}
							value={personalDetails.maritalStatus}
							onChange={(value) => {
								handleChange(
									value,
									'maritalStatus',
									'personalDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Marital Status is required"
							label="Marital Status"
							disabled={isDisabled}
							isError={formError.maritalStatus}
						/>
					</Col>
				</Row>
			),
		},
		{
			key: '2',
			label: (
				<div
					style={{ fontSize: '1.8rem', fontWeight: 500 }}
					className="color-purple"
				>
					Employee Details
				</div>
			),
			children: (
				<Row className={styles['form-container-card']} gutter={50}>
					<Col span={6} className={styles['col']}>
						<DatePickerField
							label="Joining Date"
							name="joiningDate"
							value={employeeDetails.joiningDate}
							required={true}
							placeholder="Joining Date"
							onChange={(value) => {
								handleChange(
									value,
									'joiningDate',
									'employeeDetails',
									true
								);
							}}
							disabled={isDisabled}
							isError={formError.joiningDate}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Branch"
							placeholder="Select Branch"
							options={dropdownOptions?.branchCode}
							value={employeeDetails.branchId}
							onChange={(value) => {
								handleChange(
									value,
									'branchId',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Branch is required"
							label="Branch"
							disabled={isDisabled}
							isError={formError.branchId}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Department"
							placeholder="Select Department"
							options={
								employeeDetails.branchId
									? dropdownOptions?.department?.filter(
											(item: any) =>
												item.branchId ===
												employeeDetails.branchId
									  )
									: []
							}
							value={employeeDetails.departmentId}
							onChange={(value) => {
								handleChange(
									value,
									'departmentId',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Department is required"
							label="Department"
							disabled={isDisabled}
							isError={formError.departmentId}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Cost Center"
							placeholder="Select Cost Center"
							options={
								employeeDetails?.departmentId
									? dropdownOptions?.costCenter?.filter(
											(item: any) =>
												item.departmentId ===
												employeeDetails.departmentId
									  )
									: []
							}
							value={employeeDetails.costCenterId}
							onChange={(value) => {
								handleChange(
									value,
									'costCenterId',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Cost center is required"
							label="Cost Center"
							disabled={isDisabled}
							isError={formError.costCenterId}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Category"
							placeholder="Category"
							options={dropdownOptions?.category}
							value={employeeDetails.categoryId}
							onChange={(value) => {
								handleChange(
									value,
									'categoryId',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Category is required"
							label="Category"
							disabled={isDisabled}
							isError={formError.categoryId}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Job Grade"
							placeholder="Job Grade"
							options={dropdownOptions?.jobGrade}
							value={employeeDetails.jobGradeId}
							onChange={(value) => {
								handleChange(
									value,
									'jobGradeId',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Job Grade is required"
							label="Job Grade"
							disabled={isDisabled}
							isError={formError.jobGradeId}
						/>
					</Col>
					<Col span={6}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Staff Code"
							placeholder="Staff Code"
							options={dropdownOptions?.staffCode}
							value={employeeDetails.staffCode}
							onChange={(value) => {
								handleChange(
									value,
									'staffCode',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Staff Code is required"
							label="Staff Code"
							disabled={isDisabled}
							isError={formError.staffCode}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<InputField
							name="nhimaNo"
							value={employeeDetails?.nhimaNo as string}
							label="NHIMA Number"
							required={false}
							onChange={(value) => {
								handleChange(
									value,
									'nhimaNo',
									'employeeDetails',
									false
								);
							}}
							type={'text'}
							disabled={isDisabled}
							isError={formError.nhimaNo}
						/>
					</Col>
					{/* <Col span={6}>
						<SelectDropdown
							defaultOption={true}
							mode="multiple"
							defaultLabel="Supervisor"
							placeholder="Supervisor"
							options={dropdownOptions?.supervisors.filter((item: any) => item.label !== 'All')}
							value={employeeDetails.supervisorIds}
							onChange={(value) => {
								handleChange(
									value,
									'supervisorIds',
									'employeeDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Supervisor is required"
							label="Supervisor"
							disabled={isDisabled}
							isError={formError.supervisorIds}
						/>
					</Col> */}
				</Row>
			),
		},
		{
			key: '3',
			label: (
				<div
					style={{ fontSize: '1.8rem', fontWeight: 500 }}
					className="color-purple"
				>
					Identification Information
				</div>
			),
			children: (
				<Row className={styles['form-container-card']} gutter={50}>
					<Col span={6} className={styles['col']}>
						<InputField
							name="ssn"
							value={identificationDetails.ssn}
							label="SSN"
							required={true}
							helperText="SSN must be a 9-digit number"
							onChange={(value) => {
								handleChange(
									value,
									'ssn',
									'identificationDetails',
									true
								);
							}}
							regex="^\d{9}$"
							type={isSSNVisible ? 'text' : 'password'}
							suffix={
								<EyeOutlined
									onClick={() => {
										setIsSSNdVisible((prev) => !prev);
									}}
								/>
							}
							disabled={isDisabled}
							isError={formError.ssn}
						/>
					</Col>
					<Col span={6} className={styles['col']}>
						<SelectDropdown
							defaultOption={true}
							defaultLabel="Select Status"
							placeholder="Select Status"
							options={dropdownOptions?.recordStatus}
							value={identificationDetails.recordStatus}
							onChange={(value) => {
								handleChange(
									value,
									'recordStatus',
									'identificationDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Record status is required"
							label="Record status"
							disabled={isDisabled}
							isError={formError.recordStatus}
						/>
					</Col>
					<Col span={6}>
						<InputField
							name="passportNo"
							value={identificationDetails?.passportNo as string}
							label="Passport No."
							required={false}
							helperText="Passport must be a 13-character alphanumeric"
							onChange={(value) => {
								handleChange(
									value,
									'passportNo',
									'identificationDetails',
									true
								);
							}}
							regex="^[A-Z0-9]{13}$"
							disabled={isDisabled}
							isError={formError.passportNo}
						/>
					</Col>
					<Col span={6}>
						<InputField
							name="nationalRegistrationNo"
							value={identificationDetails.nationalRegistrationNo}
							label="National Registration No."
							required={true}
							helperText="Invalid national registration no."
							onChange={(value) => {
								const formattedValue = formatInputValue(value);

								handleChange(
									formattedValue,
									'nationalRegistrationNo',
									'identificationDetails',
									true,
									null
								);
							}}
							// regex="^[0-9]{13}$"
							disabled={isDisabled}
							isError={formError.nationalRegistrationNo}
						/>
					</Col>
					{/* <Col span={12}>
						<InputNumberField
							name="nationalRegistrationNo"
							value={identificationDetails.nationalRegistrationNo}
							label="National Registration No."
							required={true}
							helperText="Invalid national registration no."
							onChange={(value) => {
								handleChange(
									value,
									'nationalRegistrationNo',
									'identificationDetails',
									true,
									null
								);
							}}
							isError={formError.nationalRegistrationNo}
						/>
					</Col> */}
				</Row>
			),
		},
	];
	return (
		<div className={styles['employment']}>
			<Collapse
				items={items}
				defaultActiveKey={['1', '2', '3']}
				expandIconPosition="end"
				className={styles['employment--employeeDetails']}
				expandIcon={({ isActive }) => (
					<div
						style={{
							transform: `rotate(${isActive ? -90 : 0}deg)`,
							transition: '0.3s',
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-chevron-right"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 0 .708l6 6a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708.708L2.707 4.5 1.646 5.561a.5.5 0 0 1-.708-.708z"
							/>
						</svg>
					</div>
				)}
				// onChange={onChangesss}
			/>
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					margin: '1rem 0rem ',
				}}
			>
				<Buttons buttons={buttons} />
			</div>
		</div>
	);
};

export default EmploymentDetails;
