import React, { useContext } from 'react';

import './index.scss';
import CategoryCodeComponent from 'components/Settings/Masters/CategoryCode';
import { PermissionContext } from 'components/Global/AuthLayout';

export const CategoryCode = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Category_Code');

	return <div>{check && <CategoryCodeComponent />}</div>;
};
