import TimeSheetComponent from 'components/TimeSheet';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TimeSheets = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Time_Sheets');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <TimeSheetComponent />}</div>;
};
