import { getApi, putApi, postApi, deleteApi } from 'apis';

const requestLoan = async (data: any) => {
	return await postApi('/loan', data);
};

const updateLoan = async (id: string, data: any) => {
	return await putApi(`/loan/update/${id}`, data);
};

const getLoanRequests = async (data: any) => {
	return await getApi('/loan', data);
};

const getLoanRequestDetails = async (id: any) => {
	return await getApi(`/loan/${id}`);
};

const updateLoanRequestStatus = async (id: string, data: any) => {
	return await putApi(`/loan/loanStatus/${id}`, data);
};

export const updateLoanRequest = async (id: string, data: any) => {
	return await putApi(`/loan/${id}`, data);
};

const approveLoan = async (data: any) => {
	return await postApi(`/loan/approve`, data);
};

const rejectLoan = async (data: any) => {
	return await postApi(`/loan/reject`, data);
};

const deleteLoanRequest = async (id: any) => {
	return await deleteApi(`/loan/delete/${id}`);
};

const updateEffectiveDate = async (data: any) => {
	return await putApi(`/loan/creditLoan`, data);
};

const getLoanByEmployee = async (employeeId: string) => {
	return await getApi(`/loan/employee/${employeeId}`);
}

export const loanRequestApi = {
	getLoanRequests,
	getLoanRequestDetails,
	approveLoan,
	rejectLoan,
	deleteLoanRequest,
	updateEffectiveDate,
	requestLoan,
	updateLoan,
	updateLoanRequestStatus,
	getLoanByEmployee
};
