import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { EmployeeTableInterface } from 'interfaces/employee.interface';
import { useSearchParams } from 'react-router-dom';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

const { Column, ColumnGroup } = Table;

type Props = {
	employeeData: EmployeeTableInterface[];
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	isLoading: boolean;
	setIsAddEmployeeModalOpen: (value: boolean) => void;
	setViewOnlyEmployeeDetails: (value: boolean) => void;
	showModal: any;
};

const EmployeeTable = (props: Props) => {
	const {
		employeeData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		setIsAddEmployeeModalOpen,
		setViewOnlyEmployeeDetails,
		showModal,
	} = props;
	const [searchParams, setSearchParams] = useSearchParams();

	const permissionContext = useContext(PermissionContext);

	const handleEditEmployee = (id: string) => {
		setIsAddEmployeeModalOpen(true);
		setSearchParams({
			employeeId: id,
		});
	};

	// Delete user data handler
	const deleteDataHandler = (id: any) => {
		setSearchParams({
			employeeId: id,
		});
		showModal();
	};

	const handleViewEmployee = (id: string) => {
		setViewOnlyEmployeeDetails(true);
		setSearchParams({
			employeeId: id,
			onlyView: 'true',
		});
	};

	const isEdit = permissionContext.allowedPermissions.includes(
		'Edit_Employment' ||
		'Edit_Salary' ||
		'Edit_Payment_Info' ||
		'Edit_Documents' ||
		'Edit_Deductions' ||
		'Edit_Earnings'
	);

	const isDelete = permissionContext.allowedPermissions.includes(
		'Delete_Employment' ||
		'Delete_Salary' ||
		'Delete_Payment_Info' ||
		'Delete_Documents' ||
		'Delete_Deductions' ||
		'Delete_Earnings'
	);

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={employeeData}
				// scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name"
					dataIndex="firstName"
					key="firstName"
					render={(text, record: any) => (
						<span
							style={{ cursor: 'pointer', color: 'blue' }}
							onClick={() => handleViewEmployee(record.id)}
						>
							{`${record.firstName} ${record.lastName}`}
						</span>
					)}
					sorter={true}
				/>
				<Column
					title="Employee Code"
					dataIndex="employeeId"
					key="employeeId"
					sorter={true}
				/>
				<Column
					title="Joining Date"
					dataIndex="joiningDate"
					key="joiningDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={true}
				/>
				<Column
					title="Gender"
					dataIndex="gender"
					key="gender"
					sorter={true}
				/>
				<Column
					title="Status"
					dataIndex="recordStatus"
					key="recordStatus"
					render={(value) => {
						switch (value) {
							case 'ACTIVE':
								return <p className="green">Active</p>;
							case 'SUSPENDED':
								return <p className="red">Suspended</p>;
							case 'RETIRED':
								return <p className="green">Retired</p>;
							case 'REASSIGNED':
								return <p className="green">Reassigned</p>;
							case 'DISMISSED':
								return <p className="red">Dismissed</p>;
							case 'DECEASED':
								return <p className="green">Deceased</p>;
							case 'REDUNDANT':
								return <p className="green">Redundant</p>;
							default:
								return <p>{value}</p>;
						}
					}}
					sorter={true}
				/>
				{/* <Column
					title="Status"
					dataIndex="recordStatus"
					key="recordStatus"
					render={(value) =>
						value ? (
							<p className="green">Active</p>
						) : (
							<p className="red">Suspended</p>
						)
					}
					sorter={true}
				/> */}
				{(isEdit || isDelete) && (
					<Column
						title="Action"
						key="action"
						render={(_: any, record: any) => (
							<Space size="middle">
								{isEdit && (
									<p className="cursor-pointer">
										<EditOutlined
											style={{
												fontSize: 18,
											}}
											onClick={() => {
												handleEditEmployee(record.id);
											}}
										/>
									</p>
								)}
								{isDelete && (
									<p className="cursor-pointer">
										<DeleteOutlined
											style={{
												fontSize: 18,
												cursor: 'pointer',
											}}
											onClick={() =>
												deleteDataHandler(record.id)
											}
										/>
									</p>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default EmployeeTable;
