import { createSlice } from '@reduxjs/toolkit';
import { fetchPayPointAction } from '../actions/payPointAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const PayPointSlice = createSlice({
	name: 'payPoint',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPayPointAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchPayPointAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(fetchPayPointAction.rejected, (state, action: any) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default PayPointSlice;
