import { useContext, useEffect } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import './index.scss';
import LoanRequestComponent from 'components/LoanRequest';
import { useNavigate } from 'react-router-dom';

export const LoanRequest = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Loan_Request');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <LoanRequestComponent />}</div>;
};
