import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';

import { invalidText } from 'utils/utils';

import EmployeeShareComponent from '../ToolTip';
import dayjs from 'dayjs';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allPayeDetails: any;
	isLoading: boolean;
	fetchAllPayeDetails: any;
};
const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allPayeDetails,
		isLoading,
	} = props;

	return (
		<>
			<Modal
				title="PAYE History"
				open={open}
				onCancel={closeModal}
				maskClosable={true}
				width={1000}
				closable={true}
				footer={null}
			>
				<Table
					dataSource={allPayeDetails}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record?.id}
					loading={isLoading}
				>
					<Column
						title="Updated By"
						dataIndex="createdBy"
						key="createdBy"
						className="bg-white"
						render={(text) => text.fullName}
					/>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(text) =>
							dayjs(text).format('DD/MM/YYYY')
						}
					/>

					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>

					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						render={(text, record: any) => (
							<div>
								{record.payeAttachment.length > 0 ? (
									<a
										href={record.payeAttachment[0].documentLink}
										rel="noopener noreferrer"
									>
										<Ellipse
											message={record?.payeAttachment[0].documentName}
											maxLength={20}
											key={record?.payeAttachment[0].documentName}
											tooltipMessage={record?.payeAttachment[0].documentName}
											isTooltip={true}
										/>
									</a>
								) : (
									'-'
								)}
							</div>
						)}
					/>
					<Column
						title="Employee Share"
						dataIndex="PAYEEmployeeShare"
						key="PAYEEmployeeShare"
						className="bg-white"
						render={(employeeShare) => (
							<EmployeeShareComponent
								employeeShare={employeeShare}
							/>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
