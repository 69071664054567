import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Empty, Modal, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import CheckBox from 'components/Global/Checkbox';
import DatePickerField from 'components/Global/DatePicker';
import InputNumberField from 'components/Global/InputNumberField';
import { months } from 'constants/Data';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { nhimaApi } from 'Api/nhima';

type CalculateNhimaModalProps = {
	open: boolean;
	cancelCalculateModal: () => void;
	fetchNhimaReportDetails: () => void;
};
const CalculateNhimaModal = (props: CalculateNhimaModalProps) => {
	const { open, cancelCalculateModal, fetchNhimaReportDetails } = props;
	const [loading, setLoading] = useState(false);
	// const [selectAll, setSelectAll] = useState(false);

	const [currencyFields, setCurrencyFields] = useState<any>([]);
	const [currencyError, setCurrencyError] = useState<Boolean>(false);

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const currencyOptions = constantDropdownOptions?.currencyData;

	const [calculationDetails, setCalculationDetails] = useState<any>({
		year: dayjs(new Date()),
		month: new Date().getMonth() + 1,
	});

	const [formError, setFormError] = useState<any>({
		year: false,
		month: false,
	});

	const [hasError, setHasError] = useState(false);

	const buttons = [
		{
			text: 'Calculate',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: handleSubmit,
			minWidth: '10rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: cancelCalculateModal,
			minWidth: '10rem',
		},
	];

	const handleCancelModal = () => {
		setFormError({
			year: false,
			month: false,
			currencyFields: false,
		});
		setCalculationDetails({
			year: null,
			month: null,
		});
		setHasError(false);
		setCurrencyFields([]);
		setCurrencyError(false);
		// setSelectAll(false);
		cancelCalculateModal();
	};

	const handleCheckboxChange = (id: number, checked: boolean) => {
		const updatedData = currencyFields.map((item: any) =>
			item.id === id
				? {
						...item,
						conversionRate: checked ? item.conversionRate : null,
						checked: checked,
				  }
				: item
		);

		const CheckCurrencyError = currencyFields.some(
			(item: any) => item.isChecked
		);

		setCurrencyError(CheckCurrencyError);

		setCurrencyFields(updatedData);
	};

	const handleCurrencyChange = (id: number, value: number) => {
		const updatedData = currencyFields.map((item: any) =>
			item.id === id
				? { ...item, conversionRate: value, error: false }
				: item
		);

		setCurrencyFields(updatedData);
	};
	const handleChange = (value: any, name: string, required: boolean) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (name === 'year') {
			setCalculationDetails((prev: any) => ({
				...prev,
				// year: dayjs(value).year(),
				year: value,
			}));
		} else if (name === 'month') {
			setCalculationDetails((prev: any) => ({
				...prev,
				month: value,
			}));
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | string[] | Dayjs,
		key: string
	) => {
		setCalculationDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	// const handleSelectAll = (value: boolean) => {
	// 	setSelectAll(value);
	// 	if (value) {
	// 		const updatedData = currencyFields.map((item: any) => ({
	// 			...item,
	// 			checked: true,
	// 			conversionRate: item.conversionRate || 1,
	// 			error: false,
	// 		}));
	// 		setCurrencyFields(updatedData);
	// 	} else {
	// 		const updatedData = currencyFields.map((item: any) => ({
	// 			...item,
	// 			checked: item.isBaseCurrency,
	// 			conversionRate: null,
	// 			error: false,
	// 		}));
	// 		setCurrencyFields(updatedData);
	// 	}
	// };

	async function handleSubmit() {
		let checkFormError = validateFormData(
			{
				...calculationDetails,
			},
			{ ...formError }
		);

		const currencyErrors = currencyFields.map((item: any) => {
			if (item.isBaseCurrency) {
				return item;
			}
			if (
				item.checked &&
				(!item.conversionRate || item.conversionRate <= 0)
			) {
				item.error = true;
			} else {
				item.error = false;
			}
			return item;
		});

		setCurrencyFields(currencyErrors);

		const CheckCurrencyError = currencyFields.some(
			(item: any) => item.checked
		);

		setCurrencyError(!CheckCurrencyError);

		setFormError(checkFormError);

		if (currencyFields.length === 0) return;

		if (hasFormError(checkFormError)) {
			return;
		}

		if (currencyFields.some((item: any) => item.error)) {
			return;
		}

		try {
			setLoading(true);

			const currencyArray: any[] = [];

			currencyFields.forEach((item: any) => {
				if (item.checked) {
					currencyArray.push({
						currencyId: item.id,
						currencyRate: item.conversionRate || 1,
						currencyType: item.label,
					});
				}
			});

			const data = {
				year: calculationDetails.year.year(),
				month: calculationDetails.month,
				currencies: currencyArray,
			};
			await nhimaApi.calculateNhima(data);
			toastText('NHIMA calculated Successfully', 'success');
			fetchNhimaReportDetails();
			handleCancelModal();
		} catch (error: any) {
			console.error('error: ', error);
			if (error.response?.data?.error?.code !== 1005) {
				toastText(
					'Something went wrong calculating NHIMA report',
					'error'
				);
			} else {
				toastText(error.response?.data?.message, 'error');
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (open) {
			setCalculationDetails({
				year: dayjs(new Date()),
				month: new Date().getMonth() + 1,
			});
			setFormError({
				year: false,
				month: false,
				currencyFields: false,
			});
			setHasError(false);
		}
	}, [open]);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		if (currencyOptions?.length > 0 && currencyFields.length === 0) {
			const baseCurrency = currencyOptions.find(
				(currency: any) => currency?.isBaseCurrency
			);
			const remainingCurrencies = currencyOptions.filter(
				(currency: any) => !currency?.isBaseCurrency && currency?.value
			);
			const allCurrencies = [baseCurrency, ...remainingCurrencies];
			const initialCurrencyFields = allCurrencies
				.filter((currency: any) => currency?.value)
				.map((currency: any) => {
					const baseField = {
						id: currency.value,
						label: currency.label,
						conversionRate: currency.isBaseCurrency ? 1 : null,
						checked: currency.isBaseCurrency,
						disabled: currency.isBaseCurrency,
						isBaseCurrency: currency.isBaseCurrency,
					};
					if (!currency.isBaseCurrency) {
						return {
							...baseField,
							error: false,
						};
					}

					return baseField;
				});

			setCurrencyFields(initialCurrencyFields);
		}
	}, [currencyOptions, currencyFields.length]);

	return (
		<>
			<Modal
				open={open}
				width={500}
				maskClosable={true}
				closable={false}
				onCancel={handleCancelModal}
				footer={null}
				style={{ position: 'absolute', top: 150, right: 50 }}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Calculate NHIMA</Title>
						<div
							className={styles['close-icon']}
							onClick={handleCancelModal}
						>
							<CloseOutlined />
						</div>
					</div>
					<div>
						<Row
							className={styles['form-container-card']}
							gutter={50}
						>
							<Col span={24} className={styles['col']}>
								<DatePickerField
									name="year"
									value={calculationDetails.year}
									label="Year"
									required={true}
									helperText="Year is required"
									picker="year"
									onChange={(value) => {
										handleChange(value, 'year', true);
									}}
									isError={formError.year}
									disabled={false}
								/>
							</Col>
							<Col span={24} className={styles['col']}>
								<div className={styles['form-group']}>
									<label className={styles['form-label']}>
										Select Month{' '}
										<span className={styles['required']}>
											*{' '}
										</span>
									</label>
									<Select
										showSearch
										placeholder="Select month"
										size="large"
										options={months.map((month) => ({
											value: month.value,
											label: month.label,
										}))}
										style={{ width: '100%' }}
										onChange={(month) => {
											handleChange(month, 'month', true);
										}}
										value={calculationDetails.month}
										optionFilterProp="label"
									/>
								</div>
							</Col>

							<Col span={24} className={styles['col']}>
								<div className={styles['form-group']}>
									<label className={styles['form-label']}>
										Currency conversion rate{' '}
										<span className={styles['required']}>
											*{' '}
										</span>
									</label>
									{/* <CheckBox
										checked={selectAll}
										label="Currency"
										onChange={(value) => {
											handleSelectAll(value);
										}}
									/> */}
								</div>
								{/* <Divider style={{ margin: '0' }} /> */}

								{currencyFields.length === 0 && (
									<>
										<Empty />
									</>
								)}

								{currencyFields?.map((item: any) => {
									if (item.id === '') {
										return <></>;
									} else if (item.label === 'ZMW') {
										return (
											<>
												<div
													className={
														styles['currency-group']
													}
												>
													<div
														className={
															styles[
																'currency-group_checkbox'
															]
														}
													>
														<CheckBox
															disabled
															checked={
																item.checked
															}
															label={item.label}
															onChange={(
																checked
															) =>
																handleCheckboxChange(
																	item.id,
																	checked
																)
															}
														/>
													</div>
												</div>
											</>
										);
									} else {
										return (
											<div
												className={
													styles['currency-group']
												}
											>
												<div
													className={
														styles[
															'currency-group_checkbox'
														]
													}
												>
													<CheckBox
														checked={item.checked}
														label={item.label}
														onChange={(checked) =>
															handleCheckboxChange(
																item.id,
																checked
															)
														}
													/>
												</div>
												<div>
													<InputNumberField
														value={
															item.conversionRate
														}
														label=""
														required
														placeholder="Enter conversion rate"
														name={`currency-${item.id}`}
														size="large"
														style={{
															width: '126%',
														}}
														isError={item.error}
														onChange={(
															value: any
														) =>
															handleCurrencyChange(
																item.id,
																value
															)
														}
														helperText="Conversion rate is required"
													/>
												</div>
											</div>
										);
									}
								})}
							</Col>
							{currencyError && (
								<Col span={24} className={styles['col']}>
									<p className={styles['error-text']}>
										Please select One Currency
									</p>
								</Col>
							)}
						</Row>

						<Row className={styles['modal-buttons']}>
							<Buttons buttons={buttons} />
						</Row>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CalculateNhimaModal;
