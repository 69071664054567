import React, { useContext } from 'react';

import './index.scss';
import JobGradeComponent from 'components/Settings/Masters/JobGrade';
import { PermissionContext } from 'components/Global/AuthLayout';

export const JobGrade = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Job_Grade');

	return <div>{check && <JobGradeComponent />}</div>;
};
