import React from 'react';
import { Card, Col, Row , Empty } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';

type Props = {
	employeeData: any;
};

const TaxationDetails = ({ employeeData }: Props) => {
	// if (!employeeData || !employeeData.TaxationDetails) {
	// 	return null;
	// }

	const taxationDetails = employeeData?.TaxationDetails;

	const formatDate = (date: string) => {
		return dayjs(date).format('DD/MM/YYYY');
	};

	const displayBoolean = (value: boolean) => {
		return value ? 'Yes' : 'No';
	};

	return (
		<div className={styles['taxation-details']}>
			<Card
				title={
					<div className={styles['ant-card-head-title']}>
						Taxation Details
					</div>
				}
				bordered={false}
				className={styles['card']}
			>
     {!taxationDetails ? (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				) :(
				<Row gutter={16}>
					<Col span={12}>
						{/* <div className={styles['detail-item']}> */}
							<p>
								<b>Employee TPIN:</b>{' '}
								{taxationDetails.employeeTPIN}
							</p>
							</Col>
							<Col span={12}>
							<p>
								<b>Currency:</b> {taxationDetails.currency}
							</p>
						{/* </div> */}
					</Col>
					<Col span={12}>
						{/* <div className={styles['detail-item']}> */}
							<p>
								<b>Employment Type:</b>{' '}
								{taxationDetails.employmentType}
							</p>
							</Col>
							<Col span={12}>
							<p>
								<b>Taxation Method:</b>{' '}
								{taxationDetails.taxationMethod}
							</p>
						{/* </div> */}
					</Col>
					<Col span={12}>
						{/* <div className={styles['detail-item']}> */}
							<p>
								<b>Enable Gross up tax:</b>{' '}
								{displayBoolean(
									taxationDetails.enableGrossUpTax
								)}
							</p>
							</Col>
							<Col span={12}>
							<p>
								<b>Employee with Disabilities:</b>{' '}
								{displayBoolean(taxationDetails.disabilities)}
							</p>
						{/* </div> */}
					</Col>
					<Col span={12}>
						{/* <div className={styles['detail-item']}> */}
							<p>
								<b>Termination Date:</b>{' '}
								{formatDate(taxationDetails.terminationDate)}
							</p>
						{/* </div> */}
					</Col>
				</Row>
			)}
			</Card>
		</div>
	);
};

export default TaxationDetails;
