import React, { useContext } from 'react';

import './index.scss';
import PayPointComponent from 'components/Settings/Masters/PayPoint';
import { PermissionContext } from 'components/Global/AuthLayout';

export const PayPoint = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Pay_Point');

	return <div>{check && <PayPointComponent />}</div>;
};
