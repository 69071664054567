import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllBranchCode } from 'Api/masters/branch-code';

export const fetchBranchCodeAction = createAsyncThunk(
	'branchCode',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getAllBranchCode(params);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
