import React, { useContext } from 'react';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import MidMonthPayConfig from 'components/Settings/PayrollSettings/MidMonthPay';

export const MidMonthPaySetup = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes(
		'Allow_Mid-Month_Pay_Setup'
	);

	return <div>{check && <MidMonthPayConfig />}</div>;
};
