import React, { useEffect, useState } from 'react';
import TableComponent from './Table';
import GlobalStatutoryHeader from '../GlobalHeader';
import GlobalInformationModal from '../GlobalInformationModal';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { toastText } from 'utils/utils';
import CalculateNapsaModal from './CalculateNapsaModal';
import AddCommunicationModal from './AddCommunicationModal';
import UploadRecord from './UploadRecord';
import { napsaApi } from 'Api/napsa';

const btnText = 'NAPSA';

const NapsaCalculateComponent = () => {
	const [extraDetailsPopover, setExtraDetailsPopover] = useState(false);
	const [addCommunicationModal, setAddCommunicationModal] = useState(false);
	const [calculateModalOpen, setCalculateModalOpen] = useState(false);
	const [informationModal, setInformationModal] = useState(false);
	const [uploadRecordModal, setUploadRecordModal] = useState(false);

	const [napsaInformationCurrentPage, setNapsaInformationCurrentPage] =
		useState(1);
	const [napsaInformationPageSize, setNapsaInformationPageSize] =
		useState(10);
	const [isInformationLoading, setIsInformationLoading] = useState(false);
	const [napsaInformationTotalRecords, setNapsaInformationTotalRecords] =
		useState(0);
	const [napsaInformationSortField, setNapsaInformationSortField] =
		useState('');
	const [napsaInformationSortOrder, setNapsaInformationSortOrder] =
		useState('');
	const [napsaInformationData, setNapsaInformationData] = useState<any>([]);

	const [calculateNapsaCurrentPage, setCalculateNapsaCurrentPage] =
		useState(1);
	const [calculateNapsaPageSize, setCalculateNapsaPageSize] = useState(10);
	const [calculateNapsaTotalRecords, setCalculateNapsaTotalRecords] =
		useState(0);
	const [calculateNapsaSortField, setCalculateNapsaSortField] = useState('');
	const [calculateNapsaSortOrder, setCalculateNapsaSortOrder] = useState('');
	const [calculateNapsaIsLoading, setCalculateNapsaIsLoading] =
		useState(false);
	const [calculateNapsaData, setCalculateNapsaData] = useState<any>([]);

	const [napsaRecordId, setNapsaRecordId] = useState<any>(null);

	const openUploadRecordModal = (napsaRecordId: string) => {
		setUploadRecordModal(true);
		setNapsaRecordId(napsaRecordId);
	};

	const toggleExtraDetails = () => {
		setExtraDetailsPopover(!extraDetailsPopover);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setNapsaInformationTotalRecords(pagination.total);
		setNapsaInformationCurrentPage(pagination.current);
		setNapsaInformationPageSize(pagination.pageSize);
		setNapsaInformationSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setNapsaInformationSortField(sorter.field);
	};

	const tableChangeHandlerForCalculateNapsa = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setCalculateNapsaTotalRecords(pagination.total);
		setCalculateNapsaCurrentPage(pagination.current);
		setCalculateNapsaPageSize(pagination.pageSize);
		setCalculateNapsaSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setCalculateNapsaSortField(sorter.field);
	};

	const openAddCommunicationModal = () => {
		setAddCommunicationModal(true);
		setExtraDetailsPopover(false);
	};

	const closeAllModal = () => {
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setInformationModal(false);
		setExtraDetailsPopover(false);
		setUploadRecordModal(false);
		setNapsaRecordId(null);
	};

	const openInformationModal = () => {
		setInformationModal(true);
		setExtraDetailsPopover(false);
	};

	const openCalculateModal = () => {
		setCalculateModalOpen(true);
		setExtraDetailsPopover(false);
	};

	async function fetchInformationDetails() {
		try {
			const query = {
				page: napsaInformationCurrentPage,
				sortBy: napsaInformationSortField,
				sortOrder: napsaInformationSortOrder,
				pageSize: napsaInformationPageSize,
			};

			setIsInformationLoading(true);
			const response = await napsaApi.getAllCommunication(query);
			setNapsaInformationData(response.data.data);

			setNapsaInformationTotalRecords(response.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching Information',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsInformationLoading(false);
		}
	}

	async function fetchNapsaReportDetails() {
		try {
			const query = {
				page: napsaInformationCurrentPage,
				sortBy: calculateNapsaSortField,
				sortOrder: calculateNapsaSortOrder,
				pageSize: calculateNapsaPageSize,
			};
			setCalculateNapsaIsLoading(true);
			const allData = await napsaApi.getNapsaReports(query);

			setCalculateNapsaData(allData.data.data);

			setCalculateNapsaTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching report Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setCalculateNapsaIsLoading(false);
		}
	}
	useEffect(() => {
		fetchInformationDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		napsaInformationPageSize,
		napsaInformationCurrentPage,
		napsaInformationSortField,
		napsaInformationSortOrder,
	]);

	useEffect(() => {
		fetchNapsaReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculateNapsaPageSize,
		calculateNapsaCurrentPage,
		calculateNapsaSortOrder,
		calculateNapsaSortField,
	]);
	useEffect(() => {
		closeAllModal();
	}, []);
	return (
		<>
			<GlobalStatutoryHeader
				btnText={btnText}
				openCalculateModal={openCalculateModal}
				openInformationModal={openInformationModal}
				openAddCommunicationModal={openAddCommunicationModal}
				extraDetailsPopover={extraDetailsPopover}
				toggleExtraDetails={toggleExtraDetails}
			/>

			<TableComponent
				calculateNapsaCurrentPage={calculateNapsaCurrentPage}
				calculateNapsaPageSize={calculateNapsaPageSize}
				calculateNapsaTotalRecords={calculateNapsaTotalRecords}
				calculateNapsaIsLoading={calculateNapsaIsLoading}
				calculateNapsaData={calculateNapsaData}
				tableChangeHandler={tableChangeHandlerForCalculateNapsa}
				openUploadRecordModal={openUploadRecordModal}
			/>

			<GlobalInformationModal
				open={informationModal}
				InformationData={napsaInformationData}
				closeInformationModal={closeAllModal}
				totalRecords={napsaInformationTotalRecords}
				pageSize={napsaInformationPageSize}
				loading={isInformationLoading}
				currentPage={napsaInformationCurrentPage}
				tableChangeHandler={tableChangeHandler}
			/>
			<CalculateNapsaModal
				open={calculateModalOpen}
				cancelCalculateModal={closeAllModal}
				fetchNapsaReportDetails={fetchNapsaReportDetails}
			/>

			<UploadRecord
				open={uploadRecordModal}
				closeUploadRecordModal={closeAllModal}
				fetchNapsaReportDetails={fetchNapsaReportDetails}
				napsaRecordId={napsaRecordId}
			/>

			<AddCommunicationModal
				open={addCommunicationModal}
				closeAddCommunicationModal={closeAllModal}
				fetchInformationDetails={fetchInformationDetails}
			/>
		</>
	);
};

export default NapsaCalculateComponent;
