import { Input, Popover, Tooltip } from 'antd';
import { ChangeEvent, useState } from 'react';
import { allowedVariablesForEarningsFormula, validateFormula } from 'utils/utils';

type Props = {
	name: string;
	label: string;
	value: string;
	placeholder?: string;
	required: boolean;
	isError: boolean;
	onChange: (value: string) => void;
	helperText?: string;
	prefix?: any;
	suffix?: any;
	regex?: string;
	disabled?: boolean;
	type?: string;
	labelSuffix?: any;
	labelSuffixContent?: any;
};


const FormulaInput = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		regex,
		required = false,
		onChange,
		labelSuffix,
		disabled = false,
		labelSuffixContent,
		helperText = 'Invalid formula',
	} = props;

	const [hasError, setHasError] = useState(false);


	const handleChange = (value: string) => {
		if (required) {
			setHasError(value.trim() === '');
		}

		if (regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		if (
			value &&
			!validateFormula(value, allowedVariablesForEarningsFormula)
		) {
			setHasError(true);
		}
		else{
			setHasError(false);
		}

		onChange(value);
	};

	const handleBlur = (value: string) => {
		if (required) {
			setHasError(value.trim() === '');
		}

		if (
			value &&
			!validateFormula(value, allowedVariablesForEarningsFormula)
		) {
			setHasError(true);
		} else {
			setHasError(false);
		}

	};

	return (
		<div className="input-field">
			{label && (
				<p className="label">
					{label} {required && <span className="red">*</span>}
					<Popover content={labelSuffixContent} trigger="hover" className='cursor-pointer'>
						{labelSuffix}
					</Popover>
				</p>
			)}
			<div>
				<Input
					name={name}
					status={isError || hasError ? 'error' : ''}
					placeholder={placeholder}
					value={value}
					required={required}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e.target.value)
					}
					onBlur={(e: ChangeEvent<HTMLInputElement>) =>
						handleBlur(e.target.value)
					}
					size="large"
					disabled={disabled}
				/>
				{(isError || hasError) && (
					<p
						className="red"
						style={{
							fontSize: '12px',
							marginLeft: '2px',
						}}
					>
						{helperText}
					</p>
				)}
			</div>
		</div>
	);
};

export default FormulaInput;
