import { postApi, getApi } from 'apis';
const createNhima = async (data: any) => {
	return await postApi('/nhima', data, true);
};

const getLatestNhima = async () => {
	return await getApi('/nhima/latest');
};

const getNhimaHistory = async (params: any) => {
	return await getApi(`/nhima`, params);
};

const createCommunication = async (data: any) => {
	return await postApi('/nhimaCommunication/create', data, true);
};

const getAllCommunication = async (params: any) => {
	return await getApi(`/nhimaCommunication`, params);
};

const calculateNhima = async (data: any) => {
	return await postApi('/calculation/Nhima', data);
};

const getNhimaReports = async (params: any) => {
	return await getApi('/nhima/nhimaReports', params);
};

const createNhimaRecord = async (data: any, nhimaRecordId: string) => {
	return await postApi(`/nhima/record/${nhimaRecordId}`, data, true);
};

export const nhimaApi = {
	createNhima,
	getLatestNhima,
	getNhimaHistory,
	createCommunication,
	getAllCommunication,
	createNhimaRecord,
	getNhimaReports,
	calculateNhima,
};
