
import TableActionHeader from "components/Global/TableActionHeader";
import NotificationBody from "./NotificationBody";

const NotificationSettings = () => {

  return <>
    <TableActionHeader title={'notification'} />

    <NotificationBody />
  </>
};

export default NotificationSettings;
