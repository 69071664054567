import { Space } from 'antd';
import styles from './index.module.scss';
import DatePickerField from 'components/Global/DatePicker';

type Props = {
	dateValue: any;
	handleDatePicker: any;
};

const MidMonthPayTableHeader = (props: Props) => {
	const { handleDatePicker, dateValue } = props;
	return (
		<div className={styles['employee-header']}>
			<Space>
				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="year"
					isError={false}
					onChange={handleDatePicker}
				/>
			</Space>
		</div>
	);
};
export default MidMonthPayTableHeader;
