import React from 'react';

import './index.scss';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import NapsaCalculateComponent from './NapsaCalculate';
import NhimaCalculateComponent from './NhimaCalculate';
import PayeCalculateComponent from './PayeCalculate';

const CalculateStatutory = () => {
	const [activeKey, setActiveKey] = React.useState('napsa');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	let tabItems = [
		{
			key: 'napsa',
			label: 'NAPSA',
			children: <NapsaCalculateComponent />,
		},
		{
			key: 'nhima',
			label: 'NHIMA',
			children: <NhimaCalculateComponent />,
		},
		{
			key: 'paye',
			label: 'PAYE',
			children: <PayeCalculateComponent />,
		},
	];

	return (
		<div className={styles['calculate-statutory']}>
			<p>Calculate Statutory Components</p>

			<div className="calculate-statutory">
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					items={tabItems}
					onChange={(key) => changeTab(key)}
					style={{
						fontSize: '18px',
						marginBottom: '40px',
					}}
				/>
			</div>
		</div>
	);
};

export default CalculateStatutory;
