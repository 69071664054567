import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
	sidebarDrawerOpen: () => void;
	PersonalLevyExists: boolean;
	isLoading: boolean;
};

const PersonalLevyHeader = ({
	sidebarDrawerOpen,
	PersonalLevyExists,
	isLoading,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const buttonTitle = PersonalLevyExists
		? 'Update Personal Levy'
		: 'Add Personal Levy';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['others-header']}>
			<p>Personal Levy</p>
			{isAdd && (
				<div>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default PersonalLevyHeader;
