import { notificationApi } from 'Api/notification';
import { Checkbox, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { notificationConfigMapping } from 'utils/mappings';
import { toastText } from 'utils/utils';
import RoleAssignModal from '../AssignRoleModal';
import styles from './index.module.scss';
import './index.scss';

const NotificationBody = () => {
	const [notificationData, setNotificationData] = useState<any[]>([]);
	const [isRoleModalOpen, setIsRoleModalOpen] = useState<boolean>(false);
	const [selectedNotification, setSelectedNotification] = useState<any>(null);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchNotificationConfig = async () => {
		try {
			const notificationConfig =
				await notificationApi.getNotificationConfig();

			setNotificationData(notificationConfig.data.data);
		} catch (err: any) {
			let message =
				'Something went wrong in fetching notification config';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		}
	};

	useEffect(() => {
		fetchNotificationConfig();
	}, []);

	const handleNotificationConfig = (
		checked: any,
		id: string,
		type: string
	) => {
		const _data = notificationData.map((item: any) => {
			if (item.id === id) {
				item[type] = checked;
			}
			return item;
		});
		setNotificationData(_data);
	};

	const handleRoleSubmit = (notificationId: string, roleData: any) => {
		const data = notificationData.map((item: any) => {
			if (item.id === notificationId) {
				return {
					...item,
					roles: roleData,
				};
			} else {
				return item;
			}
		});
		setNotificationData(data);
		setIsRoleModalOpen(false);
	};

	const handleNotificationSubmit = async () => {
		try {
			setIsLoading(true);
			await notificationApi.updateNotificationConfig({
				data: notificationData,
			});
			toastText('Updated notification successfully.', 'success');
		} catch (err: any) {
			let message =
				'Something went wrong in updating notification config';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const buttons = [
		{
			text: 'Update',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				handleNotificationSubmit();
			},
			disabled: isLoading,
			size: 'small',
			isLoading: false,
		},
	];

	return (
		<div className={styles['notification-settings']}>
			<Table
				dataSource={notificationData}
				pagination={false}
				// onChange={tableChangeHandler}
				loading={false}
			>
				<Column
					title="Module Name"
					dataIndex="moduleName"
					key="moduleName"
					// sorter={true}
					className="bg-white"
					render={(record: string) => {
						return notificationConfigMapping[record];
					}}
				/>
				<Column
					title="Email"
					dataIndex="emailNotification"
					key="emailNotification"
					// sorter={true}
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<Checkbox
								checked={record}
								onChange={(e) =>
									handleNotificationConfig(
										e.target.checked,
										data.id,
										'emailNotification'
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Push"
					dataIndex="pushNotification"
					key="pushNotification"
					// sorter={true}
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<Checkbox
								checked={record}
								onChange={(e) =>
									handleNotificationConfig(
										e.target.checked,
										data.id,
										'pushNotification'
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Approval Roles"
					dataIndex="roles"
					key="roles"
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<p
								className="pointer"
								onClick={() => {
									setIsRoleModalOpen(true);
									setSelectedNotification(data);
									setRoles(data.roles);
								}}
							>
								Assign Role
							</p>
						);
					}}
				/>
			</Table>
			<Buttons buttons={buttons} />
			{isRoleModalOpen && (
				<RoleAssignModal
					isOpen={isRoleModalOpen}
					handleCancel={() => setIsRoleModalOpen(false)}
					selectedNotification={selectedNotification}
					handleRoleSubmit={handleRoleSubmit}
					roleData={roles}
				/>
			)}
		</div>
	);
};

export default NotificationBody;
