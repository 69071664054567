import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import { months } from 'constants/Data';
type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
	monthValue:any;
	yearValue: any;
	handleMonthDatePicker: any;
	handleYearDatePicker: any;
};

const LeaveRequestHeader = (props: Props) => {
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		handleMonthDatePicker,
		handleYearDatePicker,
		monthValue,
		yearValue,
		button,
	} = props;
const permissionContext = useContext(PermissionContext);
const isAdd = permissionContext.allowedPermissions.includes('Add_Leave_Request');
	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search Name | Code and Reason"
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<DatePickerField
					name={'dateFilter'}
					value={yearValue}
					required={false}
					picker="year"
					isError={false}
					onChange={handleYearDatePicker}
				/>
				<Select
					showSearch
					placeholder="Select month"
					options={months.map((month) => ({
						value: month.value,
						label: month.label,
					}))}
					style={{ width: 164 , height:42}}
					onChange={handleMonthDatePicker}
					value={monthValue}
					optionFilterProp="label"
				/>
			</Space>
			{isAdd && 
			<Buttons buttons={button} />
			}
		</div>
	);
};

export default LeaveRequestHeader;
