import NotificationSettings from "components/NotificationSettings";
import React from "react";

const Notification = () => {
  return <>
    <NotificationSettings />
  </>;
};

export default Notification;
