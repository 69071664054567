import {
	ClockCircleOutlined,
	FileDoneOutlined,
	SnippetsOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import AddLogsModal from '../AddLogsModal';
import styles from './index.module.scss';

type Props = {
	openCreateShiftModal: () => void;
	setEditShift: any;
	tableData: any;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: any;
	totalRecords: number;
	setIsShiftDeleteModalOpen: any;
	fetchShifts: any;
	holidays: any;
	openViewShiftModal: any;
};

const ShiftTable = (props: Props) => {
	const {
		openCreateShiftModal,
		setEditShift,
		tableData,
		tableChangeHandler,
		currentPage,
		pageSize,
		isLoading,
		totalRecords,
		setIsShiftDeleteModalOpen,
		fetchShifts,
		openViewShiftModal,
		holidays,
	} = props;
	const permissions = useContext(PermissionContext);

	const [isAddLogsModalOpen, setIsAddLogsModalOpen] = useState(false);
	const [selectedShift, setSelectedShift] = useState<any>({});
	const [selectedShiftDetails, setSelectedShiftDetails] = useState<any>({});
	const [shiftStartDate, setShiftStartDate] = useState<any>({});
	const [shiftEndDate, setShiftEndDate] = useState<any>({});
	// const [isShiftDetailsModalOpen, setIsShiftModalOpen] = useState(false);

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				// scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Shift Name"
					dataIndex="shiftName"
					key="shiftName"
					sorter={true}
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<p
								style={{ cursor: 'pointer', color: 'blue' }}
								onClick={() => {
									setEditShift(data);
									openViewShiftModal(true);
								}}
							>{`${record} (${dayjs(data.startDate).format(
								'DD/MM/YYYY'
							)} - ${dayjs(data.endDate).format(
								'DD/MM/YYYY'
							)})`}</p>
						);
					}}
				/>
				<Column
					title="Shift Time"
					dataIndex="startTime"
					key="startTime"
					className="bg-white"
					sorter={true}
					render={(record: any, data: any) => {
						return (
							<>{`${dayjs(data.startTime).format(
								'hh : mm a'
							)} - ${dayjs(data.endTime).format('hh : mm a')}`}</>
						);
					}}
				/>
				<Column
					title="Department"
					dataIndex="branch"
					key="branch"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="Supervisor"
					dataIndex="supervisorName"
					key="supervisorName"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="No. of Employee"
					dataIndex="noOfEmployees"
					key="noOfEmployees"
					className="bg-white"
					// sorter={true}
					render={(record: any, data: any) => {
						return (
							<div className="flex align-center justify-center">
								<UsergroupAddOutlined
									style={{ color: 'blue' }}
								/>
								<span
									style={{ marginLeft: '5px', color: 'blue' }}
								>
									{data?.noOfEmployees}
								</span>
							</div>
						);
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					// sorter={true}
					render={(record: any, data: any) => {
						return (
							<>
								{dayjs(data?.startDate) > dayjs(new Date()) ? (
									<Tag color="blue">
										{' '}
										<SnippetsOutlined />
										<span style={{ marginLeft: '5px' }}>
											Planned
										</span>
									</Tag>
								) : dayjs(data.endDate) < dayjs(new Date()) ? (
									<Tag color="purple">
										{' '}
										<FileDoneOutlined />
										<span style={{ marginLeft: '5px' }}>
											Completed
										</span>
									</Tag>
								) : (
									<Tag color="green">
										{' '}
										<ClockCircleOutlined />
										<span style={{ marginLeft: '5px' }}>
											Active
										</span>
									</Tag>
								)}
							</>
						);
					}}
				/>
				{(permissions.allowedPermissions.includes('Edit_Time_Shift') ||
					permissions.allowedPermissions.includes(
						'Delete_Time_Shift'
					) ||
					permissions.allowedPermissions.includes('Edit_Time_Logs') ||
					permissions.allowedPermissions.includes(
						'Add_Time_Logs'
					)) && (
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						render={(record: any, data: any) => {
							return (
								<div
									style={{
										display: 'flex',
										gap: '2rem',
										alignItems: 'center',
									}}
								>
									{permissions.allowedPermissions.includes(
										'Edit_Time_Shift'
									) ? (
										dayjs(data?.endDate) >
											dayjs(new Date()) &&
										data.timeLogsCount === 0 ? (
											<div
												className="cursor-pointer  flex align-center justify-center"
												onClick={() => {
													openCreateShiftModal();
													setEditShift(data);
												}}
											>
												<EditActionSvg />
											</div>
										) : (
											<div className="cursor-not-allowed  flex align-center justify-center">
												<EditActionSvg color="#928F8F" />
											</div>
										)
									) : null}
									{permissions.allowedPermissions.includes(
										'Delete_Time_Shift'
									) ? (
										data.timeLogsCount === 0 ? (
											<div
												className="cursor-pointer flex align-center justify-center"
												onClick={() => {
													setEditShift(data);
													setIsShiftDeleteModalOpen(
														true
													);
												}}
											>
												<DeleteActionSvg />
											</div>
										) : (
											<div className="cursor-not-allowed flex align-center justify-center">
												<DeleteActionSvg color="#928F8F" />
											</div>
										)
									) : null}

									{permissions.allowedPermissions.includes(
										'Edit_Time_Logs'
									) ||
									permissions.allowedPermissions.includes(
										'Add_Time_Logs'
									) ? (
										dayjs(data?.startDate) <
											dayjs(new Date()) &&
										dayjs(data?.endDate) >
											dayjs(new Date()) ? (
											<Button
												onClick={() => {
													setSelectedShift(data.id);
													setIsAddLogsModalOpen(true);
													setShiftStartDate(
														data.startDate
													);
													setShiftEndDate(
														data.endDate
													);
													setSelectedShiftDetails(
														data
													);
												}}
												type="primary"
											>
												Add Log
											</Button>
										) : (
											<Button type="primary" disabled>
												Add Log
											</Button>
										)
									) : null}
								</div>
							);
						}}
					/>
				)}
			</Table>
			{isAddLogsModalOpen && (
				<AddLogsModal
					selectedShift={selectedShift}
					shiftStartDate={dayjs(shiftStartDate).startOf('day')}
					holidays={holidays}
					shiftEndDate={shiftEndDate}
					isOpen={isAddLogsModalOpen}
					handleCancel={() => {
						setIsAddLogsModalOpen(false);
						setSelectedShift(null);
					}}
					fetchShifts={fetchShifts}
					selectedShiftDetails={selectedShiftDetails}
				/>
			)}
		</div>
	);
};

export default ShiftTable;
