import React from 'react';
import NhimaComponent from './NhimaTab';
import NapsaComponent from './NapsaTab';
import PayeComponent from './PayeTab';
import './index.scss';
import styles from './index.module.scss';
import { Divider, Tabs } from 'antd';
import OtherComponent from './OthersTab';

const StatutoryComponent = () => {
	const [activeKey, setActiveKey] = React.useState('napsa');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	let tabItems = [
		{
			key: 'napsa',
			label: 'NAPSA',
			children: (
				<NapsaComponent changeTab={changeTab} activeKey={activeKey} />
			),
		},
		{
			key: 'nhima',
			label: 'NHIMA',
			children: (
				<NhimaComponent changeTab={changeTab} activeKey={activeKey} />
			),
		},
		{
			key: 'paye',
			label: 'PAYE',
			children: (
				<>
					<PayeComponent
						changeTab={changeTab}
						activeKey={activeKey}
					/>
					<Divider
						dashed
						style={{ borderColor: '#BCBCBC', marginBottom: '4rem' }}
					></Divider>
					<OtherComponent />
				</>
			),
		},
	];

	return (
		<div className={styles['statutory-component']}>
			<div className="statutory-component-tabs">
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					items={tabItems}
					onChange={(key) => changeTab(key)}
					style={{
						fontSize: '18px',
						marginBottom: '40px',
					}}
				/>
			</div>
		</div>
	);
};

export default StatutoryComponent;
