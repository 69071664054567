import React, { useContext } from 'react';

import './index.scss';
import JobTitleComponent from 'components/Settings/Masters/JobTitle';
import { PermissionContext } from 'components/Global/AuthLayout';

export const JobTitle = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Job_Title');

	return <div>{check && <JobTitleComponent />}</div>;
};
