import { Button, Col, Modal, Row, Tabs } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import EmploymentDetails from './EmployentDetails';
import TaxationDetails from './TaxationDetails';
import styles from './index.module.scss';
import './index.scss';
// import ModalWrapper from 'components/Global/ModalWrapper';
import { HistoryOutlined, LoadingOutlined } from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import { salaryApi } from 'Api/salaryDetails';
import { getApi } from 'apis';
import { SideDrawerWrapper } from 'components/Global';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseSvg } from 'utils/svgs';
import { fetchConstantDropdownAction } from '../../redux/actions/constantDropdownAction';
import DeductionTab from './DeductionTab';
import Documents from './Documents';
import EarningsTab from './EarningsTab';
import EmployeeActivityBody from './EmployeeActivity';
import PaymentInfo from './PayementInfo';
import SalaryDetails from './SalaryDetails';

type Props = {
	onCancel: () => void;
	isAddEmployeeModalOpen: boolean;
	fetchEmployees: () => void;
};

const AddEmployee = (props: Props) => {
	const navigate = useNavigate();
	const { onCancel, isAddEmployeeModalOpen, fetchEmployees } = props;

	const dispatch = useDispatch<AppDispatch>();

	const { isLoading } = useSelector((state: any) => state.constantDropdown);
	const [searchParams, setSearchParams] = useSearchParams();
	const [employeeDetails, setEmployeeDetails] = useState<any>();
	const [activeKey, setActiveKey] = useState('employmentDetails');
	const [employeeId, setEmployeeId] = useState<string>('');
	const [isEmployeeActivityOpen, setIsEmployeeActivityOpen] =
		useState<boolean>(false);
	const [earningsYear, setEarningsYear] = useState(dayjs().year());
	const [earningsMonth, setEarningsMonth] = useState(
		new Date().getMonth() + 1
	);
	const [deductionsYear, setDeductionsYear] = useState(dayjs().year());
	const [deductionsMonth, setDeductionsMonth] = useState(
		new Date().getMonth() + 1
	);
	const [isEmployeeSalaryExists, setIsEmployeeSalaryExists] =
		useState<boolean>(false);

	const permissions = useContext(PermissionContext).allowedPermissions;

	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	const onEarningsYearChange = (selectedYear: any) => {
		setEarningsYear(selectedYear.year());
	};
	const onEarningsMonthChange = (month: number) => {
		setEarningsMonth(month);
	};
	const onDeductionsYearChange = (selectedYear: any) => {
		setDeductionsYear(selectedYear.year());
	};
	const onDeductionsMonthChange = (month: number) => {
		setDeductionsMonth(month);
	};

	let modalTitle = searchParams.get('employeeId')
		? 'Edit Employee'
		: 'Add Employee';

	let items = [
		{
			key: 'employmentDetails',
			label: 'Employment',
			children: (
				<EmploymentDetails
					changeTab={changeTab}
					onCancel={onCancel}
					employeeId={employeeId}
					employeeData={employeeDetails}
					setEmployeeId={setEmployeeId}
					fetchEmployees={fetchEmployees}
					getEmployeeDetail={(employeeId: string) => {
						getEmployeeDetail(employeeId);
					}}
				/>
			),
		},
		{
			key: 'taxationDetails',
			label: 'Taxation',
			children: searchParams.get('employeeId') ? (
				<TaxationDetails changeTab={changeTab} onCancel={onCancel} />
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '50vh',
						cursor: 'pointer',
						padding: '10px', // Adjust padding as needed
					}}
					onClick={() => {
						changeTab('employmentDetails');
					}}
				>
					Add{' '}
					<span
						style={{
							textDecoration: 'underline',
							marginLeft: '5px',
							marginRight: '5px',
						}}
						className="color-purple"
					>
						{' '}
						Employment{' '}
					</span>{' '}
					Details First!!
				</div>
			),
		},
		{
			key: 'salaryDetails',
			label: 'Salary',
			children: searchParams.get('employeeId') ? (
				<SalaryDetails
					employeeId={employeeId}
					setIsEmployeeSalaryExists={setIsEmployeeSalaryExists}
					isViewMode={false}
				/>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '50vh',
						cursor: 'pointer',
					}}
					onClick={() => {
						changeTab('employmentDetails');
					}}
				>
					Add
					<span
						style={{
							textDecoration: 'underline',
							marginLeft: '5px',
							marginRight: '5px',
						}}
						className="color-purple"
					>
						Employment
					</span>
					Details First!!
				</div>
			),
		},
		{
			key: 'paymentInfo',
			label: 'Payment Info',
			children: searchParams.get('employeeId') ? (
				<PaymentInfo />
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '50vh',
						cursor: 'pointer',
					}}
					onClick={() => {
						changeTab('employmentDetails');
					}}
				>
					Add
					<span
						style={{
							textDecoration: 'underline',
							marginLeft: '5px',
							marginRight: '5px',
						}}
						className="color-purple"
					>
						Employment
					</span>
					Details First!!
				</div>
			),
		},
		{
			key: 'documents',
			label: 'Documents',
			children: searchParams.get('employeeId') ? (
				<Documents isViewMode={false} />
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '50vh',
						cursor: 'pointer',
					}}
					onClick={() => {
						changeTab('employmentDetails');
					}}
				>
					Add
					<span
						style={{
							textDecoration: 'underline',
							marginLeft: '5px',
							marginRight: '5px',
						}}
						className="color-purple"
					>
						Employment
					</span>
					Details First!!
				</div>
			),
		},

		{
			key: 'earnings',
			label: (
				<>
					{/* <EarningsIconSvg /> */}
					Earnings
				</>
			),
			children:
				isEmployeeSalaryExists && searchParams.get('employeeId') ? (
					<EarningsTab
						onYearChange={onEarningsYearChange}
						onMonthChange={onEarningsMonthChange}
						activeTab={activeKey}
						year={earningsYear}
						month={earningsMonth}
						employeeId={searchParams.get('employeeId') as string}
					/>
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
							cursor: 'pointer',
						}}
						// className="color-purple"
						onClick={() => {
							changeTab(
								searchParams.get('employeeId')
									? 'salaryDetails'
									: 'employmentDetails'
							);
						}}
					>
						Add
						<span
							style={{
								textDecoration: 'underline',
								marginLeft: '5px',
								marginRight: '5px',
							}}
							className="color-purple"
						>
							{!searchParams.get('employeeId')
								? ' Employment '
								: ' Salary '}
						</span>
						Details First!!
					</div>
				),
		},
		{
			key: 'deductions',
			label: (
				<>
					{/* <EarningsIconSvg /> */}
					Deductions
				</>
			),
			children:
				isEmployeeSalaryExists && searchParams.get('employeeId') ? (
					<DeductionTab
						onYearChange={onDeductionsYearChange}
						onMonthChange={onDeductionsMonthChange}
						year={deductionsYear}
						activeTab={activeKey}
						month={deductionsMonth}
						employeeId={searchParams.get('employeeId') as string}
					/>
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
							cursor: 'pointer',
						}}
						// className="color-purple"
						onClick={() => {
							changeTab(
								searchParams.get('employeeId')
									? 'salaryDetails'
									: 'employmentDetails'
							);
						}}
					>
						Add
						<span
							style={{
								textDecoration: 'underline',
								marginLeft: '5px',
								marginRight: '5px',
							}}
							className="color-purple"
						>
							{!searchParams.get('employeeId')
								? ' Employment '
								: ' Salary '}
						</span>
						Details First!!
					</div>
				),
		},
	];

	if (!permissions.includes('Add_Employment')) {
		items = items.filter(
			(item) =>
				item.key !== 'employmentDetails' &&
				item.key !== 'taxationDetails'
		);
	}

	if (!permissions.includes('Add_Salary')) {
		items = items.filter((item) => item.key !== 'salaryDetails');
	}

	if (!permissions.includes('Add_Payment_Info')) {
		items = items.filter((item) => item.key !== 'paymentInfo');
	}

	useEffect(() => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');
		if (employeeIdFromSearchParams) {
			getEmployeeDetail(employeeIdFromSearchParams);
			getSalaryDetails(employeeIdFromSearchParams);
		} else {
			// fetchEmployeeId();
			setEmployeeId('To be auto generated');
		}
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	const getEmployeeDetail = async (employeeId: string) => {
		try {
			const employeeData = await employeeApi.getEmployeeDetailsById(
				employeeId
			);
			setEmployeeDetails(employeeData?.data?.data);

			setEmployeeId(employeeData?.data?.data?.employeeId);
		} catch (error: any) {
			let message = 'Something went wrong in fetching employee details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			navigate('/');
			toastText(message, 'error');
		}
	};
	const getSalaryDetails = async (employeeId: string) => {
		try {
			const salaryData = await salaryApi.getEmployeeSalary({
				employeeId,
			});
			if (salaryData?.data?.data?.length > 0) {
				setIsEmployeeSalaryExists(true);
			}
		} catch (error: any) {
			let message =
				'Something went wrong in fetching employee salary details';

			toastText(message, 'error');
		}
	};

	// const fetchEmployeeId = async () => {
	// 	try {
	// 		const users = await getApi('/employee/employeeId');
	// 		setEmployeeId(users.data.employeeId);
	// 	} catch (err: any) {
	// 		toastText('Something went wrong in fetching employee id', 'error');
	// 	}
	// };

	const removeDrawerFromDom = () => {
		setIsEmployeeActivityOpen(false);
	};

	return (
		<>
			<Modal
				open={isAddEmployeeModalOpen}
				width={'100%'}
				onCancel={onCancel}
				className="preview-container"
				closable={false}
				style={{
					borderRadius: 0,
				}}
				footer={[]}
			>
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '90vh',
						}}
					>
						<LoadingOutlined
							style={{ fontSize: '100px', color: '#584495' }}
						/>
					</div>
				) : (
					<div>
						<Row className={styles['preview-container__top']}>
							<div
								className={
									styles['preview-container__top-title']
								}
							>
								<b>{modalTitle}</b>
								{/* <b>Add Employee</b> */}
							</div>
							<div
								className={
									styles['preview-container__top-close']
								}
								onClick={() => {
									setEmployeeId('');
									onCancel();
								}}
							>
								<CloseSvg />
							</div>
						</Row>
						<div className={styles['preview-container__content']}>
							<Row
								// gutter={16}
								className={
									styles[
										'preview-container__content--new-employee-header'
									]
								}
							>
								<Col
									span={20}
									className={
										styles[
											'preview-container__content--new-employee-header-first'
										]
									}
								>
									Employee ID :{' '}
									<span
										className={
											styles[
												'preview-container__content--new-employee-header-first--id'
											]
										}
									>
										{employeeId}
									</span>
								</Col>
								<Col
									span={4}
									className={
										styles[
											'preview-container__content--new-employee-header-second'
										]
									}
								>
									{searchParams.get('employeeId') && (
										<Button
											onClick={() =>
												setIsEmployeeActivityOpen(true)
											}
										>
											<HistoryOutlined />
										</Button>
									)}
								</Col>
							</Row>
							<div
								className={
									styles[
										'preview-container__content--new-employee-header-tabs'
									]
								}
							>
								<Tabs
									defaultActiveKey={activeKey}
									activeKey={activeKey}
									items={items}
									onChange={(key) => changeTab(key)}
									style={{
										fontSize: '18px',
										marginBottom: '40px',
									}}
								/>
							</div>
						</div>
					</div>
				)}
				{/* <ModalWrapper
						handleOk={() => {
							console.log('OK click');
						}}
						handleCancel={handleModalClose}
						isModalOpen={
							isEarningsModalOpen &&
							isEmployeeSalaryExists &&
							searchParams.get('employeeId')
								? true
								: false
						}
						title={'Earnings'}
						width={800}
						onYearChange={onYearChange}
						onMonthChange={onMonthChange}
						year={dayjs().year(year)}
						month={month}
						employeeId={searchParams.get('employeeId') as string}
					>
						<EarningsModal
							year={dayjs().year(year)}
							month={month}
							employeeId={searchParams.get('employeeId') as string}
							onDataChange={(data) => console.log(data)}
							onLoadingChange={(loading) => console.log(loading)}
						/>
					</ModalWrapper>

					<ModalWrapper
						handleOk={() => {
							console.log('OK click');
						}}
						handleCancel={handleModalClose}
						isModalOpen={
							isDeductionsModalOpen &&
							isEmployeeSalaryExists &&
							searchParams.get('employeeId')
								? true
								: false
						}
						title={'Deductions'}
						width={800}
						onYearChange={onYearChange}
						onMonthChange={onMonthChange}
						year={dayjs().year(year)}
						month={month}
						employeeId={searchParams.get('employeeId') as string}
					>
						<DeductionsModal
							year={dayjs().year(year)}
							month={month}
							employeeId={searchParams.get('employeeId') as string}
							onDataChange={(data) => console.log(data)}
							onLoadingChange={(loading) => console.log(loading)}
						/>
					</ModalWrapper> */}
				{isEmployeeActivityOpen && (
					<SideDrawerWrapper
						isOpen={isEmployeeActivityOpen}
						removeDrawerFromDom={removeDrawerFromDom}
						closeDrawerByAnimation={removeDrawerFromDom}
						headerTitle={'Activity'}
						position="right"
						width="half"
					>
						<EmployeeActivityBody />
					</SideDrawerWrapper>
				)}
			</Modal>
		</>
	);
};

export default AddEmployee;
