import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	isLoggedIn: false,
	isErrorMessage: '',
	error: null,
};

const userCheckSlice = createSlice({
	name: 'userCheck',
	initialState,
	reducers: {
		// Update user check state with payload data
		userCheck: (state: any, action) => {
			(state.isLoggedIn = action?.payload?.isLoggedIn);
				(state.isErrorMessage = action?.payload?.isErrorMessage);
		},
	},
});
// Export the userCheck action
export const { userCheck } = userCheckSlice.actions;
export default userCheckSlice;
