
import './index.scss';
import EmployeeComponent from 'components/Settings/Approval/Employee';

export const Employee = () => {
	// const context = useContext(PermissionContext);
	// const check = context.allowedPermissions.includes('Allow_Branches');

	return <div>{ <EmployeeComponent />}</div>;
};
