import { Space, Table } from 'antd';
import {
	ConfigurationSettingSvg,
	DeleteActionSvg,
	EditActionSvg,
	PayrollSettings,
} from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { invalidText } from 'utils/utils';
import Ellipse from 'components/Global/Ellipse';
import { SettingOutlined } from '@ant-design/icons';
import './index.scss';
const { Column } = Table;

type Props = {
	leaveManagementData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	showModal: any;
	isLoading: boolean;
	openConfigRuleModal: (leaveId: string) => void;
};

const LeaveManagementTable = (props: Props) => {
	const {
		leaveManagementData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		setEditData,
		showModal,
		isLoading,
		openConfigRuleModal,
	} = props;

	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Leave_Management'
	);

	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		setEditData(selectedObj);
	};

	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditData(userObject);
		showModal();
	};

	const leaveType = (status: string) => {
		if (status === 'PAID') {
			return { text: 'Paid Leave', className: styles['status-paid'] };
		} else if (status === 'UNPAID') {
			return { text: 'Unpaid Leave', className: styles['status-unpaid'] };
		}
		return { text: status, className: '' };
	};

	const accuralType = (accural: string) => {
		if (accural === 'ANNUALLY') {
			return { text: 'Annually' };
		} else if (accural === 'AS_NEEDED') {
			return { text: 'As Needed' };
		}
		return { text: accural };
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={leaveManagementData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				className="leaveManagementModal"
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Name"
					dataIndex="name"
					key="name"
					sorter={true}
					className="bg-white"
					width={'20%'}
				/>
				<Column
					title="Leave Type"
					dataIndex="type"
					key="type"
					className="bg-white"
					width={'20%'}
					render={(text) => {
						const { text: displayText, className } =
							leaveType(text);
						return <span className={className}>{displayText}</span>;
					}}
				/>
				<Column
					title="Accrual"
					dataIndex="accural"
					key="accural"
					className="bg-white"
					width={'20%'}
					render={(text) => {
						const { text: displayText } = accuralType(text);
						return <span>{displayText}</span>;
					}}
				/>
				<Column
					title="Description"
					dataIndex="description"
					key="description"
					className="bg-white"
					width={'20%'}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Credited Leaves"
					dataIndex="creditedLeaves"
					key="creditedLeaves"
					className="bg-white"
					width={'17%'}
				/>
				<Column
					title="Action"
					key="action"
					width={'10%'}
					className="bg-white"
					render={(_: any, data: any) => (
						<Space size={20}>
							{/* {permissions.allowedPermissions.includes(
								'Edit_Job_Grade'
							) ? (
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => editDataHandler(data)}
								>
									<EditActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed  flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</div>
							)} */}

							{isAdd ? (
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => openConfigRuleModal(data.id)}
								>
									<ConfigurationSettingSvg />
								</div>
							) : (
								<div className="cursor-not-allowed flex align-center justify-center">
									<ConfigurationSettingSvg color="#928F8F" />
								</div>
							)}

							{/* {permissions.allowedPermissions.includes(
								'Delete_Job_Grade'
							) ? (
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => deleteDataHandler(data)}
								>
									<DeleteActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</div>
							)} */}
						</Space>
					)}
				/>
			</Table>
		</div>
	);
};

export default LeaveManagementTable;
