import { WarningOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import Buttons from 'components/Global/Buttons';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const DirectDeposit = () => {
	const [form] = Form.useForm();

	const renderLabel = (label: string, required: boolean) => (
		<span className={styles['heading']}>
			{label} {required && <span className={styles['required']}>*</span>}
		</span>
	);

	const buttons = [
		{
			text: 'Save',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: '',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				console.log('Clicked cancel');
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	return (
		<div className={styles.form}>
			<div className={styles['form-container']}>
				<div className={styles['form-container-title']}>
					{' '}
					{/* <WarningOutlined /> */}
					{/* <span>Direct Deposit</span> */}
				</div>
				<Form
					form={form}
					name="dependencies"
					autoComplete="off"
					layout="vertical"
					requiredMark={false}
				>
					<Form.Item
						label={renderLabel('First Half Password', true)}
						name="firstHalfPassword"
						labelCol={{ className: styles['form-container-label'] }}
						rules={[
							{
								required: true,
								message: 'First Half Password is required',
							},
						]}
					>
						<Input.Password size="large" />
					</Form.Item>

					<Form.Item
						label={renderLabel('Second Half Password', true)}
						name="secondHalfPassword"
						labelCol={{ className: styles['form-container-label'] }}
						rules={[
							{
								required: true,
								message: 'Second Half Password is required',
							},
						]}
					>
						<Input.Password size="large" />
					</Form.Item>

					<Form.Item
						name="select"
						label={renderLabel('Bank Account', true)}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please select your bank account!',
							},
						]}
					>
						<Select size="large" placeholder="Select Bank Account">
							<Option value="1">Bank Account-1</Option>
							<Option value="2">Bank Account-2</Option>
						</Select>
					</Form.Item>
					<p className="required" style={{ color: 'red' }}>
						<WarningOutlined /> This Bank account has been invalid
						account number. <Link to={''}>Edit Bank Account</Link>
					</p>

					<Form.Item
						label={renderLabel('Live Balance', true)}
						name="liveBalance"
						labelCol={{ className: styles['form-container-label'] }}
						rules={[
							{
								required: true,
								message: 'Live Balance is required',
							},
							{
								pattern: /^\d+$/,
								message: 'Live Balance must be a number',
							},
						]}
					>
						<Input size="large" />
					</Form.Item>

					<Form.Item
						label={renderLabel('Secret PIN', true)}
						name="secretPin"
						labelCol={{ className: styles['form-container-label'] }}
						rules={[
							{
								required: true,
								message: 'Secret PIN is required',
							},
							{
								min: 6,
								pattern: /^\d+$/,
								message: 'Secret PIN must be of 6 numbers',
							},
						]}
						style={{ width: '200px' }}
					>
						<Input.Password
							maxLength={6}
							size="large"
							placeholder="● ● ● ● ● ●"
						/>
					</Form.Item>

					<Form.Item
						label={renderLabel('Confirm Secret PIN', true)}
						name="confirmSecretPIN"
						labelCol={{ className: styles['form-container-label'] }}
						rules={[
							{
								required: true,
								message: 'Confirm Secret PIN is required',
							},
							{
								min: 6,
								pattern: /^\d+$/,
								message: 'Secret PIN must be of 6 numbers',
							},
						]}
						style={{ width: '200px' }}
					>
						<Input.Password
							maxLength={6}
							size="large"
							placeholder="● ● ● ● ● ●"
						/>
					</Form.Item>

					<p>
						<b>Note: </b>This is a user-level integration. Other
						users of your organization will not be able to initiate
						payments on behalf of you.
					</p>

					<p>&nbsp;</p>

					<p>
						By clicking Save, you agree to our{' '}
						<Link to={''}>Terms and Conditions</Link>
					</p>

					<p>&nbsp;</p>

					<Form.Item>
						<Buttons buttons={buttons} />
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default DirectDeposit;
