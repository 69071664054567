import React, { useEffect, useState } from 'react';
import TableComponent from './Table';
import GlobalStatutoryHeader from '../GlobalHeader';
import GlobalInformationModal from '../GlobalInformationModal';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { toastText } from 'utils/utils';
import AddCommunicationModal from './AddCommunicationModal';
import UploadRecord from './UploadRecord';
import CalculateNhimaModal from './CalculateNhimaModal';
import { nhimaApi } from 'Api/nhima';

const btnText = 'NHIMA';

const NhimaCalculateComponent = () => {
	const [extraDetailsPopover, setExtraDetailsPopover] = useState(false);
	const [addCommunicationModal, setAddCommunicationModal] = useState(false);
	const [calculateModalOpen, setCalculateModalOpen] = useState(false);
	const [informationModal, setInformationModal] = useState(false);
	const [uploadRecordModal, setUploadRecordModal] = useState(false);

	const [nhimaInformationCurrentPage, setNhimaInformationCurrentPage] =
		useState(1);
	const [nhimaInformationPageSize, setNhimaInformationPageSize] =
		useState(10);
	const [isInformationLoading, setIsInformationLoading] = useState(false);
	const [nhimaInformationTotalRecords, setNhimaInformationTotalRecords] =
		useState(0);
	const [nhimaInformationSortField, setNhimaInformationSortField] =
		useState('');
	const [nhimaInformationSortOrder, setNhimaInformationSortOrder] =
		useState('');
	const [nhimaInformationData, setNhimaInformationData] = useState<any>([]);

	const [calculateNhimaCurrentPage, setCalculateNhimaCurrentPage] =
		useState(1);
	const [calculateNhimaPageSize, setCalculateNhimaPageSize] = useState(10);
	const [calculateNhimaTotalRecords, setCalculateNhimaTotalRecords] =
		useState(0);
	const [calculateNhimaSortField, setCalculateNhimaSortField] = useState('');
	const [calculateNhimaSortOrder, setCalculateNhimaSortOrder] = useState('');
	const [calculateNhimaIsLoading, setCalculateNhimaIsLoading] =
		useState(false);
	const [calculateNhimaData, setCalculateNhimaData] = useState<any>([]);

	const [nhimaRecordId, setNhimaRecordId] = useState<any>(null);

	const openUploadRecordModal = (nhimaRecordId: string) => {
		setUploadRecordModal(true);
		setNhimaRecordId(nhimaRecordId);
	};

	const toggleExtraDetails = () => {
		setExtraDetailsPopover(!extraDetailsPopover);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setNhimaInformationTotalRecords(pagination.total);
		setNhimaInformationCurrentPage(pagination.current);
		setNhimaInformationPageSize(pagination.pageSize);
		setNhimaInformationSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setNhimaInformationSortField(sorter.field);
	};

	const tableChangeHandlerForCalculateNhima = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setCalculateNhimaTotalRecords(pagination.total);
		setCalculateNhimaCurrentPage(pagination.current);
		setCalculateNhimaPageSize(pagination.pageSize);
		setCalculateNhimaSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setCalculateNhimaSortField(sorter.field);
	};

	const openAddCommunicationModal = () => {
		setAddCommunicationModal(true);
		setExtraDetailsPopover(false);
	};

	const closeAllModal = () => {
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setInformationModal(false);
		setExtraDetailsPopover(false);
		setUploadRecordModal(false);
	};

	const openInformationModal = () => {
		setInformationModal(true);
		setExtraDetailsPopover(false);
	};

	const openCalculateModal = () => {
		setCalculateModalOpen(true);
		setExtraDetailsPopover(false);
	};

	async function fetchInformationDetails() {
		try {
			const query = {
				page: nhimaInformationCurrentPage,
				sortBy: nhimaInformationSortField,
				sortOrder: nhimaInformationSortOrder,
				pageSize: nhimaInformationPageSize,
			};

			setIsInformationLoading(true);
			const response = await nhimaApi.getAllCommunication(query);
			setNhimaInformationData(response.data.data);

			setNhimaInformationTotalRecords(response.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching Information',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsInformationLoading(false);
		}
	}

	async function fetchNhimaReportDetails() {
		try {
			const query = {
				page: calculateNhimaCurrentPage,
				sortBy: calculateNhimaSortField,
				sortOrder: calculateNhimaSortOrder,
				pageSize: calculateNhimaPageSize,
			};
			setCalculateNhimaIsLoading(true);
			const allData = await nhimaApi.getNhimaReports(query);

			setCalculateNhimaData(allData.data.data);

			setCalculateNhimaTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching Calculate Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setCalculateNhimaIsLoading(false);
		}
	}
	useEffect(() => {
		fetchInformationDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nhimaInformationPageSize,
		nhimaInformationCurrentPage,
		nhimaInformationSortField,
		nhimaInformationSortOrder,
	]);

	useEffect(() => {
		fetchNhimaReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculateNhimaPageSize,
		calculateNhimaCurrentPage,
		calculateNhimaSortOrder,
		calculateNhimaSortField,
	]);

	useEffect(() => {
		closeAllModal();
	}, []);

	return (
		<>
			<GlobalStatutoryHeader
				btnText={btnText}
				openCalculateModal={openCalculateModal}
				openInformationModal={openInformationModal}
				openAddCommunicationModal={openAddCommunicationModal}
				extraDetailsPopover={extraDetailsPopover}
				toggleExtraDetails={toggleExtraDetails}
			/>

			<TableComponent
				calculateNhimaCurrentPage={calculateNhimaCurrentPage}
				calculateNhimaPageSize={calculateNhimaPageSize}
				calculateNhimaTotalRecords={calculateNhimaTotalRecords}
				calculateNhimaIsLoading={calculateNhimaIsLoading}
				calculateNhimaData={calculateNhimaData}
				tableChangeHandler={tableChangeHandlerForCalculateNhima}
				openUploadRecordModal={openUploadRecordModal}
			/>

			<GlobalInformationModal
				open={informationModal}
				InformationData={nhimaInformationData}
				closeInformationModal={closeAllModal}
				totalRecords={nhimaInformationTotalRecords}
				pageSize={nhimaInformationPageSize}
				loading={isInformationLoading}
				currentPage={nhimaInformationCurrentPage}
				tableChangeHandler={tableChangeHandler}
			/>
			<CalculateNhimaModal
				open={calculateModalOpen}
				cancelCalculateModal={closeAllModal}
				fetchNhimaReportDetails={fetchNhimaReportDetails}
			/>

			<UploadRecord
				open={uploadRecordModal}
				closeUploadRecordModal={closeAllModal}
				fetchNhimaReportDetails={fetchNhimaReportDetails}
				nhimaRecordId={nhimaRecordId}
			/>

			<AddCommunicationModal
				open={addCommunicationModal}
				closeAddCommunicationModal={closeAllModal}
				fetchInformationDetails={fetchInformationDetails}
			/>
		</>
	);
};

export default NhimaCalculateComponent;
