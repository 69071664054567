import { timeLogsApi } from 'Api/timeLogs';
import { Button, Modal, Spin, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { getApi, getApiExcel } from 'apis';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedTimeSheet: any;
	// selectedShift: string;
	// selectedDate: string;
};

const AddModal = (props: Props) => {
	const { isOpen, handleCancel, selectedTimeSheet } = props;

	const [timeActivities, setTimeActivities] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isExportLoading, setIsExportLoading] = useState(false);

	const fetchTimeActivities = async () => {
		const query = {
			timeSheetId: selectedTimeSheet.id,
		};
		setIsLoading(true);
		try {
			const response = await getApi(
				`/timeActivities/get-time-activities`,
				query
			);
			setTimeActivities(response.data.timeActivities);
		} catch (error) {
			console.error('Error fetching time activities:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const exportPdf = async () => {
		try {
			const startDate = moment(selectedTimeSheet.fromDate).format(
				'YYYY-MM-DD'
			);
			const endDate = moment(selectedTimeSheet.toDate).format(
				'YYYY-MM-DD'
			);

			const query = {
				startDate,
				endDate,
				timeSheetId: selectedTimeSheet.id,
			};

			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/timeActivities/export/pdf',
				query
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`-timesheet.pdf`;

			const pdfBlob = new Blob([response.data], {
				type: 'application/pdf',
			});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting pdf report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	useEffect(() => {
		if (selectedTimeSheet) {
			fetchTimeActivities();
		}
	}, [selectedTimeSheet]);

	return (
		<Modal
			open={isOpen}
			style={{
				minWidth: '1200px',
				zIndex: '99999',
			}}
			closable={false}
			onCancel={handleCancel}
			footer={
				<div className={styles['modal-footer']}>
					<Button
						type="primary"
						onClick={exportPdf}
						loading={isExportLoading}
					>
						Export PDF
					</Button>
				</div>
			}
			width={800}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Typography.Title level={4}>
						<span>
							Time Sheets {selectedTimeSheet.timeSheetName}
						</span>
					</Typography.Title>
					<Button
						//className={styles['close-button']}
						icon={<CloseOutlined />}
						onClick={handleCancel}
					/>
				</div>
				<Spin spinning={isLoading}>
					<Table
						dataSource={timeActivities}
						rowKey="id"
						pagination={false}
					>
						<Column
							title="Employee Code"
							dataIndex="optionalEmployeeId"
							key="optionalEmployeeId"
						/>
						<Column
							title="Employee Name"
							key="employeeName"
							render={(text, record: any) =>
								`${record.firstName} ${record.lastName}`
							}
						/>
						<Column
							title="Regular Hours"
							dataIndex="totalRegularHours"
							key="totalRegularHours"
						/>
						<Column
							title="Overtime Hours"
							dataIndex="totalOvertimeHours"
							key="totalOvertimeHours"
						/>
						<Column
							title="Total Hours"
							dataIndex="totalHours"
							key="totalHours"
						/>
						<Column
							title="Shift Time Difference"
							dataIndex="totalTimeShiftHours"
							key="totalTimeShiftHours"
						/>
					</Table>
				</Spin>
			</div>
		</Modal>
	);
};

export default AddModal;
