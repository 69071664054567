import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, CollapseProps, Row, Spin } from 'antd';
import styles from './index.module.scss';
import { useContext, useEffect, useState } from 'react';
import Buttons from 'components/Global/Buttons';
import SelectDropdown from 'components/Global/SelectDropdown';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import InputField from 'components/Global/InputField';
import { deleteApiWithData, getApi, postApi } from 'apis';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { PermissionContext } from 'components/Global/AuthLayout';
import { v4 as uuidv4 } from 'uuid';
import { boolean } from 'mathjs';
import { currencyData } from 'constants/CurrencyData';

type Props = {
	onCancel: () => void;
	changeTab: (key: string) => void;
};

export const BankDetails = (props: Props) => {
	const { onCancel } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [bankDetails, setBankDetails] = useState<any[]>([
		{
			id: '',
			isNew: boolean,
			dbId: '',
			accountName: '',
			accountNumber: '',
			branchName: '',
			branchCode: '',
			sortCode: '',
			currency: null,
			accountType: '',
			swiftCode: '',
		},
	]);

	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
	const [hasError, setHasError] = useState(false);

	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Company_Setup');

	const isDelete = permissionContext.allowedPermissions.includes(
		'Delete_Company_Setup'
	);

	const buttons = [
		{
			text: 'Save & Next',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancelNew',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onCancel();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	const [formErrors, setFormErrors] = useState<any[]>([
		{
			id: false,
			accountType: false,
			accountNumber: false,
			accountName: false,
			branchName: false,
			branchCode: false,
			sortCode: false,
			//	currency: false,
			swiftCode: false,
		},
	]);

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		//	stateName: string,
		setupId: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		setBankDetails((prev) =>
			prev.map((setup: any) =>
				setup.id === setupId
					? {
							...setup,
							[name]: value,
					  }
					: setup
			)
		);

		// OnChange(value, name, stateName, id);
		OnChange(value, name, setupId);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		//	stateName: string,
		id: string
	) => {
		setBankDetails((prev: any) =>
			prev.map((setup: any) =>
				setup.id === id
					? {
							...setup,
							[key]: value,
					  }
					: setup
			)
		);

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formErrors.find((error) => error.id === id) }
		);
		console.log('checkFormError: ', checkFormError);

		setFormErrors((prev) =>
			prev.map((error) =>
				error.id === id
					? {
							...error,
							...checkFormError,
					  }
					: error
			)
		);
	};

	const handleSubmit = async () => {
		let valid = false;

		const updatedErrors = bankDetails.map((setup) => {
			const checkFormError = validateFormData(setup, {
				...formErrors.find((error) => error.id === setup.id),
			});
			if (!hasFormError(checkFormError)) {
				valid = true;
			}
			return checkFormError;
		});
		setFormErrors(updatedErrors);

		if (!valid) {
			return;
		} else {
			setIsLoading(true);
			const result: any = {
				data: bankDetails.map((setup) => ({
					id: setup.id,
					// id: setup.dbId ? setup.dbId : '',
					accountName: setup.accountName,
					accountNumber: setup.accountNumber,
					branchName: setup.branchName,
					branchCode: setup.branchCode,
					sortCode: setup.sortCode,
					currency: setup.currency,
					accountType: setup.accountType,
					swiftCode: setup.swiftCode,
				})),
			};

			try {
				const bankResponse = await postApi(
					'/company/bank-details/',
					result
				);

				const message =
					bankResponse?.data?.message ||
					'Bank details added successfully';
				setBankDetails(bankResponse.data.data);
				toastText(message, 'success');
				props.changeTab('currency');
			} catch (error: any) {
				const message =
					error?.response?.data?.message ||
					'Something went wrong in adding  bank details';
				toastText(message, 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const dropdownOptions = [
		{
			label: 'Checking',
			value: 'Checking',
		},
		{ label: 'Savings', value: 'Savings' },
		{ label: 'Fix Deposit', value: 'FixDeposit' },
	];

	const addBankSetup = () => {
		// const newId = bankDetails.length + 1;
		const newBankSetup = {
			id: uuidv4(),
			isNew: true, // Generate a temporary unique ID
			accountName: '',
			accountNumber: '',
			branchName: '',
			branchCode: '',
			sortCode: '',
			currency: null,
			accountType: '',
			swiftCode: '',
			// Initialize other fields as needed
		};

		setBankDetails([...bankDetails, newBankSetup]);
		setFormErrors((prev) => [
			...prev,
			{
				id: '',
				// id: false,
				accountType: false,
				accountNumber: false,
				accountName: false,
				branchName: false,
				branchCode: false,
				sortCode: false,
				//			currency: false,
				swiftCode: false,
			},
		]);
	};

	const fetchBankDetails = async () => {
		setIsFetching(true);
		try {
			const response = await getApi('/company/bank-details');

			const fetchedSetups = response.data.data.map(
				(setup: any, index: number) => ({
					id: setup.id,
					dbId: setup.id,
					accountNumber: setup.accountNumber,
					accountName: setup.accountName,
					swiftCode: setup.swiftCode,
					branchCode: setup.branchCode,
					branchName: setup.branchName,
					sortCode: setup.sortCode,
					currency: setup.currency,
					accountType: setup.accountType,
				})
			);

			if (fetchedSetups.length === 0) {
				// Add a default item if no bank details are fetched
				fetchedSetups.push({
					id: '1',
					// id: 1,
					dbId: '',
					accountName: '',
					accountNumber: '',
					branchName: '',
					branchCode: '',
					sortCode: '',
					currency: null,
					accountType: '',
					swiftCode: '',
				});
			}

			setBankDetails(fetchedSetups);
			setFormErrors(
				fetchedSetups.map((setup: any) => ({
					id: setup.id,
					accountType: false,
					accountNumber: false,
					accountName: false,
					branchName: false,
					branchCode: false,
					sortCode: false,
					//	currency: false,
					swiftCode: false,
				}))
			);
		} catch (error) {
			console.error('Error fetching currency setups:', error);
			// Handle error, show message to user if needed
		} finally {
			setIsFetching(false);
		}
	};

	useEffect(() => {
		// Fetch predefined values when the modal opens
		fetchBankDetails();
	}, []);

	// const showDeleteModal = (id: string) => {
	// 	setCurrentDeleteId(id);
	// 	setIsModalOpen(true);
	// };
	const showDeleteModal = (id: string) => {
		const bankDetail = bankDetails.find((b) => b.id === id);
		if (bankDetail?.isNew) {
			// Handle removal of new bank detail
			setBankDetails(bankDetails.filter((b) => b.id !== id));
		} else {
			// Proceed with the confirmation modal for existing bank details
			setIsModalOpen(true);
			setCurrentDeleteId(id);
		}
	};

	const handleOk = async () => {
		if (currentDeleteId) {
			setIsDeleteLoading(true);
			try {
				await deleteApiWithData('/company/bank-details', {
					bankId: currentDeleteId,
				});
				await fetchBankDetails();
				toastText('Bank deleted successfully', 'success');
			} catch (error) {
				toastText('Error deleting bank', 'error');
			} finally {
				setIsDeleteLoading(false);
				setIsModalOpen(false);
			}
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const currencyOptions = Object.keys(currencyData).map((key) => ({
		label: `${key} - ${currencyData[key]}`,
		value: key,
	}));

	const items: CollapseProps['items'] = bankDetails.map(
		(setup: any, index: any) => {
			return {
				key: setup.id,
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							fontSize: '1.8rem',
							fontWeight: 500,
						}}
						className="color-purple"
					>
						{`Bank ${index + 1}`}
						{isDelete && (
							<DeleteOutlined
								style={{ color: 'red', cursor: 'pointer' }}
								onClick={() => showDeleteModal(setup.id)}
							/>
						)}
					</div>
				),
				children: (
					<div className={styles['form-container']}>
						<Row
							className={styles['form-container-card']}
							gutter={30}
						>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="accountName"
									value={setup.accountName}
									label="Account Name"
									required={true}
									helperText="Account name required"
									onChange={(value: any) => {
										handleChange(
											value,
											'accountName',
											//	'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountName
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="accountNumber"
									value={setup.accountNumber}
									label="Account Number"
									required={true}
									helperText="Account number required"
									onChange={(value: any) => {
										handleChange(
											value,
											'accountNumber',
											//	'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountNumber
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="branchName"
									value={setup.branchName}
									label="Branch Name"
									required={true}
									helperText="Branch Name required"
									onChange={(value: any) => {
										handleChange(
											value,
											'branchName',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.branchName
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="branchCode"
									value={setup.branchCode}
									label="Branch Code"
									required={true}
									helperText="Branch code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'branchCode',
											//	'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.branchCode
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="sortCode"
									value={setup.sortCode}
									label="Sort Code"
									required={true}
									helperText="Sort code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'sortCode',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.sortCode
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<SelectDropdown
									placeholder="Select Currency"
									options={currencyOptions}
									value={setup.currency || 'ZMW'}
									onChange={(value: any) => {
										handleChange(
											value,
											'currency',
											setup.id,
											true
										);
									}}
									size="large"
									required={true}
									helperText="Currency  is required"
									label="Currency"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.currency
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<SelectDropdown
									placeholder="Account Type"
									options={dropdownOptions}
									value={setup.accountType}
									onChange={(value: any) => {
										handleChange(
											value,
											'accountType',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									size="large"
									required={true}
									helperText="Account Type is required"
									label="Account Type"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountType
									}
								/>
							</Col>
							<Col
								span={6}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="swiftCode"
									value={setup.swiftCode}
									label="SWIFT/BIC code"
									required={true}
									helperText="Shift Code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'swiftCode',
											//			'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.swiftCode
									}
								/>
							</Col>
						</Row>
					</div>
				),
			};
		}
	);

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['form-container']}>
				<div className={styles['company-header']}>
					<Row className={styles['header']} gutter={20}>
						{' '}
						<Col span={24}>
							<h2>Bank Details</h2>{' '}
						</Col>{' '}
					</Row>

					{isAdd && (
						<Button
							icon={<PlusOutlined />}
							type="primary"
							onClick={addBankSetup}
							style={{ minWidth: '12rem' }}
							size="large"
						>
							Add Bank
						</Button>
					)}
				</div>

				{!isFetching ? (
					<Collapse defaultActiveKey={['1']} items={items} />
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: 'calc(100vh - 200px)',
						}}
					>
						<Spin />
					</div>
				)}
				<Buttons buttons={buttons} />
				<ConfirmDelete
					isModalOpen={isModalOpen}
					handleOk={handleOk}
					handleCancel={handleCancel}
					deleteHandler={handleOk}
					isLoading={isDeleteLoading}
				/>
			</div>
		</>
	);
};
