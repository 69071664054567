import { SearchOutlined } from '@ant-design/icons';
import {  Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	dateValue: any;
	handleDatePicker: any;
	supervisorFilterValue: string;
	handleSupervisorFilter: (value: string) => void;
	options: any;
	button: any;
};

const LoanRequestTableHeader = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Loan_Request');
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		handleDatePicker,
		dateValue,
		supervisorFilterValue,
		options,
		handleSupervisorFilter,
		button,
	} = props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="month"
					isError={false}
					onChange={handleDatePicker}
				/>
				<Select
					className={styles['employee-header-item']}
					value={supervisorFilterValue}
					options={options}
					onChange={(value) => {
						handleSupervisorFilter(value);
					}}
					size="large"
					placeholder="Select by supervisor"
				/>
			</Space>
			{isAdd && <Buttons buttons={button} />}
		</div>
	);
};

export default LoanRequestTableHeader;
