import TableActionHeader from 'components/Global/TableActionHeader';
import PayrollSettingsLayout from 'layouts/PayrollSettings';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getAllowedModule } from 'utils/utils';
import { keyWisePayrollSettingsRouteMapping } from 'utils/mappings';
import { PermissionContext } from 'components/Global/AuthLayout';

interface SelectedValue {
	key: string;
}

export default function PayrollSettings() {
	// const context = useContext(PermissionContext);
	const navigate = useNavigate();

	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];
	const context = useContext(PermissionContext);

	const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	useEffect(() => {
		const allowedPayrollSettings = getAllowedModule(context.permissions, [
			'Company Setup',
			'Pay Period',
			'Statutory Components',
			'Pay Schedule',
			'Mid-Month Pay Setup',
			'Payroll History',
			'Deduction Code',
			'Earnings Code',
		]);

		if (lastSegment === 'payroll-settings') {
			if (allowedPayrollSettings) {
				const key =
					keyWisePayrollSettingsRouteMapping[
						allowedPayrollSettings.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
					];
				navigate(`/settings/payroll-settings/${key}`);
			} else {
				navigate(`/`);
			}
		}
		setSelectedSidebar(lastSegment);
	}, [lastSegment]);

	return (
		<>
			<TableActionHeader title={'Payroll Settings'} />
			<div className={styles['payroll-settings']}>
				<PayrollSettingsLayout
					onSideBarChange={sideBarChangeHandler}
					selectedSidebar={selectedSidebar}
				>
					<Outlet />
				</PayrollSettingsLayout>
			</div>
		</>
	);
}
