import { Col, Modal, Row, Tooltip } from 'antd';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import { useEffect, useState } from 'react';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import SelectDropdown from 'components/Global/SelectDropdown';
import { postApi } from 'apis';
import { dateFormats, monthsForCompanySetup } from 'constants/Data';
import { countryStateData } from 'constants/StateCountryData';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { countryPhoneCodes } from 'constants/CurrencyData';

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	editData: any;
};

export const CompanyDetails = (props: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [statutoryModal, setIsStatutoryModal] = useState(false);
	const buttons = [
		{
			text: 'Save & Next',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				setIsStatutoryModal(false);
				onCancel();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	const { changeTab, onCancel } = props;

	const [companyBaseDetails, setCompanyBaseDetails] = useState<any>({
		companyName: '',
		email: '',
		financialYear: '',
		napsa: '',
		nihma: '',
		tpin: '',
		dateFormat: '',
	});

	const [companyLocation, setCompanyLocation] = useState<any>({
		address1: '',
		address2: '',
		city: '',
		state: '',
		country: '',
		postalCode: '',
	});

	const [companyContactDetail, setCompanyContactDetail] = useState<any>({
		name: null,
		title: null,
		phone: null,
	});

	const [formError, setFormError] = useState<any>({
		email: false,
		lastName: false,
		financialYear: false,
		napsa: false,
		nihma: false,
		tpin: false,
		dateFormat: false,
		address1: false,
		address2: false,
		city: false,
		state: false,
		country: false,
		postalCode: false,
	});

	const [countryOptions, setCountryOptions] = useState<any[]>([]);
	const [stateOptions, setStateOptions] = useState<any[]>([]);

	const extractStateOptions = (countryIso2: string) => {
		const country = countryStateData.data.find(
			(c) => c.iso2 === countryIso2
		);
		if (country) {
			return country.states.map((state) => ({
				label: state.name,
				value: state.state_code,
			}));
		}
		return [];
	};

	useEffect(() => {
		setCountryOptions(
			countryStateData.data.map((country) => ({
				label: country.name,
				value: country.iso2,
			}))
		);
	}, []);

	useEffect(() => {
		if (companyLocation.country) {
			const states = extractStateOptions(companyLocation.country);
			setStateOptions(states);
		} else {
			setStateOptions([]);
		}
	}, [companyLocation.country]);

const handleCountryChange = (value: any) => {

	handleChange(value, 'country', 'companyLocation', true);
  handleChange(null, 'state', 'companyLocation', true);
	// Update phone number based on selected country
	const phoneCode = countryPhoneCodes[value];
	if (phoneCode) {
		handleChange(phoneCode, 'phone', 'companyContactDetail', false);
	}
};


	const handleChange = (
		value: string | number | null | string[],
		name: string,
		stateName: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name, stateName);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		stateName: string
	) => {
		switch (stateName) {
			case 'companyBaseDetails':
				setCompanyBaseDetails((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'companyLocation':
				setCompanyLocation((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'companyContactDetail':
				setCompanyContactDetail((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
		}
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);

		setFormError(checkFormError);
	};

	const handleError = (formData: any) => {
		setFormError(formData);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...companyBaseDetails,
				...companyLocation,
				...companyContactDetail,
			},
			{ ...formError }
		);

		checkFormError = { ...checkFormError };

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			if (!statutoryModal) {
				setIsPopupVisible(true);
			} else {
				handleConfirmSubmit();
			}
		}
	};

	const handleConfirmSubmit = async () => {
		setIsPopupVisible(false);

		try {
			const companyDetails = {
				financialYear: parseInt(companyBaseDetails.financialYear, 10),
				napsa: parseInt(companyBaseDetails.napsa, 10),
				nihma: parseInt(companyBaseDetails.nihma, 10),
				dateFormat: companyBaseDetails.dateFormat,
				address1: companyLocation.address1,
				address2: companyLocation.address2,
				city: companyLocation.city,
				state: companyLocation.state,
				country: companyLocation.country,
				postalCode: parseInt(companyLocation.postalCode),
			};

			// Conditionally prepare contactDetails object
			const contactDetails =
				companyContactDetail.name ||
				companyContactDetail.title ||
				companyContactDetail.phone
					? {
							id: props?.editData?.ContactDetails[0]?.id,
							name: companyContactDetail.name || undefined,
							title: companyContactDetail.title || undefined,
							phone: companyContactDetail.phone || undefined,
					  }
					: undefined;

			// Prepare final data object
			const data: any = {
				companyDetails,
				...(contactDetails && { contactDetails }), // Add contactDetails only if it exists
			};

			setIsLoading(true);
			const response = await postApi('/company/company-details', data);

			toastText('Company details updated successfully', 'success');
			props.changeTab('bankDetails');
		} catch (error) {
			toastText('Error updating company', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancelSubmit = () => {
		setIsPopupVisible(false);
	};

	useEffect(() => {
		if (props.editData) {
			setCompanyBaseDetails({
				companyName: props.editData.name,
				email: props.editData.email,
				tpin: props.editData.tpin,
				nihma: props?.editData?.CompanyDetails?.nihma,
				napsa: props?.editData?.CompanyDetails?.napsa,
				financialYear: props?.editData?.CompanyDetails?.financialYear,
				dateFormat: props?.editData?.CompanyDetails?.dateFormat,
			});

			props?.editData?.CompanyDetails?.napsa
				? setIsStatutoryModal(true)
				: setIsStatutoryModal(false);
			setCompanyLocation({
				address1: props?.editData?.CompanyDetails?.address1,
				address2: props?.editData?.CompanyDetails?.address2,
				city: props?.editData?.CompanyDetails?.city,
				state: props?.editData?.CompanyDetails?.state,
				country: props?.editData?.CompanyDetails?.country,
				postalCode: props?.editData?.CompanyDetails?.postalCode,
			});
			setCompanyContactDetail({
				id: props?.editData?.ContactDetails[0]?.id,
				name: props?.editData?.ContactDetails[0]?.name,
				phone: props?.editData?.ContactDetails[0]?.phone,
				title: props?.editData?.ContactDetails[0]?.title,
			});
		}
	}, [props.editData]);

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<Row className={styles['form-container-card']} gutter={20}>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="companyName"
						value={companyBaseDetails.companyName}
						label="Company Name"
						required={true}
						helperText="Company name required"
						onChange={(value: any) => {
							handleChange(
								value,
								'companyName',
								'companyBaseDetails',
								true
							);
						}}
						isError={formError.companyName}
						disabled={true}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="email"
						value={companyBaseDetails.email}
						label="Email"
						required={true}
						helperText="Email required"
						onChange={(value: any) => {
							handleChange(
								value,
								'email',
								'companyBaseDetails',
								true
							);
						}}
						isError={formError.email}
						disabled={true}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder="Financial Year Start"
						options={monthsForCompanySetup}
						value={companyBaseDetails.financialYear}
						onChange={(value: any) => {
							handleChange(
								value,
								'financialYear',
								'companyBaseDetails',
								true
							);
						}}
						size="large"
						required={true}
						helperText="Financial Year is required"
						label="Financial Year Start"
						isError={formError.financialYear}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<Tooltip title="NAPSA stands for the National Pension Scheme Authority, which is a mandatory savings plan for employees.">
						<div>
							<InputField
								name="napsa"
								value={companyBaseDetails.napsa}
								label="NAPSA"
								required={true}
								helperText="Napsa required"
								onChange={(value: any) => {
									handleChange(
										value,
										'napsa',
										'companyBaseDetails',
										true
									);
								}}
								isError={formError.napsa}
								disabled={
									props?.editData?.CompanyDetails?.napsa
								}
							/>
						</div>
					</Tooltip>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<Tooltip title="NHIMA stands for the National Health Insurance Management Authority, which is a mandatory savings plan for employees.">
						<div>
							<InputField
								name="nihma"
								value={companyBaseDetails.nihma}
								label="NHIMA"
								required={true}
								helperText="Nihma required"
								onChange={(value: any) => {
									handleChange(
										value,
										'nihma',
										'companyBaseDetails',
										true
									);
								}}
								isError={formError.nihma}
								disabled={
									props?.editData?.CompanyDetails?.nihma
								}
							/>
						</div>
					</Tooltip>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="tpin"
						value={companyBaseDetails.tpin}
						label="TPIN"
						required={true}
						helperText="Tpin required"
						onChange={(value: any) => {
							handleChange(
								value,
								'tpin',
								'companyBaseDetails',
								true
							);
						}}
						disabled={true}
						isError={formError.tpin}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder="Date Format"
						options={dateFormats}
						value={companyBaseDetails.dateFormat}
						onChange={(value: any) => {
							handleChange(
								value,
								'dateFormat',
								'companyBaseDetails',
								true
							);
						}}
						size="large"
						required={true}
						helperText="Date Format is required"
						label="Date Format"
						isError={formError.dateFormat}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<div
						style={{
							fontSize: '1.8rem',
							fontWeight: '500',
							color: '#584495',
						}}
					>
						Company Location
					</div>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="address1"
						value={companyLocation.address1}
						label="Address 1"
						required={true}
						helperText="address1 required"
						onChange={(value: any) => {
							handleChange(
								value,
								'address1',
								'companyLocation',
								true
							);
						}}
						isError={formError.address1}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="address2"
						value={companyLocation.address2}
						label="Address 2"
						required={true}
						helperText="address2 required"
						onChange={(value: any) => {
							handleChange(
								value,
								'address2',
								'companyLocation',
								true
							);
						}}
						isError={formError.address2}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder="Country"
						options={countryOptions}
						value={companyLocation.country}
						onChange={handleCountryChange}
						// onChange={(value: any) => {
						// 	handleChange(
						// 		value,
						// 		'country',
						// 		'companyLocation',
						// 		true
						// 	);
						// }}
						size="large"
						required={true}
						helperText="Country is required"
						label="Country"
						isError={formError.country}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder=" State "
						options={stateOptions}
						value={companyLocation.state}
						onChange={(value: any) => {
							handleChange(
								value,
								'state',
								'companyLocation',
								true
							);
						}}
						size="large"
						required={true}
						helperText="State is required"
						label="State"
						isError={formError.state}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="city"
						value={companyLocation.city}
						label="City"
						required={true}
						helperText="city required"
						onChange={(value: any) => {
							handleChange(
								value,
								'city',
								'companyLocation',
								true
							);
						}}
						isError={formError.city}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="postalCode"
						value={companyLocation.postalCode}
						label="Postal Code"
						required={true}
						helperText="postal code required"
						onChange={(value: any) => {
							handleChange(
								value,
								'postalCode',
								'companyLocation',
								true
							);
						}}
						isError={formError.postalCode}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<div
						style={{
							fontSize: '1.8rem',
							fontWeight: '500',
							color: '#584495',
						}}
					>
						Company's Contact Person Details
					</div>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="name"
						value={companyContactDetail.name}
						label="Contact Name"
						required={false}
						// helperText="Contact name required"
						onChange={(value: any) => {
							handleChange(
								value,
								'name',
								'companyContactDetail',
								true
							);
						}}
						isError={formError.name}
					/>
				</Col>
				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="title"
						value={companyContactDetail.title}
						label="Title"
						required={false}
						//	helperText="Contact name required"
						onChange={(value: any) => {
							handleChange(
								value,
								'title',
								'companyContactDetail',
								true
							);
						}}
						isError={formError.title}
					/>
				</Col>

				<Col span={6} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="phone"
						value={companyContactDetail.phone}
						label="PhoneNumber"
						required={false}
						onChange={(value: any) => {
							handleChange(
								value,
								'phone',
								'companyContactDetail',
								true
							);
						}}
						isError={formError.phone}
					/>
				</Col>
			</Row>
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					margin: '1rem 0rem ',
					marginTop: '3rem',
				}}
			>
				<Buttons buttons={buttons} />
			</div>
			<Modal
				title={
					<>
						<ExclamationCircleOutlined
							style={{ color: 'red', marginRight: 8 }}
						/>
						Confirm Submission
					</>
				}
				open={isPopupVisible}
				closable={false}
				onOk={handleConfirmSubmit}
				onCancel={handleCancelSubmit}
				okText="Confirm"
				cancelText="Cancel"
			>
				<p>
					Once saved, the <strong>Company Name</strong>,{' '}
					<strong>Email</strong>, <strong>NAPSA</strong>,{' '}
					<strong>NIHMA</strong>, and <strong>TPIN </strong>
					cannot be modified. Are you sure you want to proceed?
				</p>
			</Modal>
		</>
	);
};
