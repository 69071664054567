import { DatePicker, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
	name: string;
	label?: string;
	value: Dayjs; // Change the type of 'value' prop to 'Dayjs'
	required: boolean;
	isError: boolean;
	onChange: (value: Dayjs) => void; // Change the type of 'onChange' callback parameter to 'Dayjs'
	placeholder?: string;
	helperText?: string;
	disabled?: boolean;
	picker?: 'date' | 'month' | 'year';
isViewOnly?: boolean;
};

const TimePickerField = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		required = false,
		onChange,
		disabled = false,
		helperText = 'Time is required',
		isViewOnly=false
	} = props;

	const handleChange = (value: Dayjs) => {
		onChange(value);
	};


	return (
		<div className="input-field">
			{label && (
				<p
					className="label"
					style={{ marginBottom: `${isViewOnly ? '5px' : ''}` }}
				>
					{label}{' '}
					{required && !isViewOnly && <span className="red">*</span>}
				</p>
			)}
			{isViewOnly ? (
				<p>{dayjs(value).format('hh:mm a')}</p>
			) : (
				<div>
					<TimePicker
						name={name}
						value={dayjs(value, 'hh:mm a')}
						size="large"
						onChange={handleChange}
						format={'hh:mm a'}
						disabled={disabled}
						placeholder={placeholder}
						style={{ width: '100%' }}
						status={isError ? 'error' : ''}
						allowClear={false}
					/>
					{isError && (
						<p
							className="red"
							style={{
								fontSize: '12px',
								marginLeft: '2px',
							}}
						>
							{helperText}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default TimePickerField;
