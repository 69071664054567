import { Modal, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allPayPeriodData: any;
	isLoading: boolean;
	fetchAllPayPeriodDetails: any;
};

const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allPayPeriodData,
		isLoading,
	} = props;
	const capitalizeFirstLetter = (text: string) => {
		if (!text) return '';
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	};

	const formatWorkWeek = (workWeek: string[]) => {
		return workWeek.map((day) => capitalizeFirstLetter(day)).join(', ');
	};
	return (
		<>
			<Modal
				title="Pay Period History"
				open={open}
				onCancel={closeModal}
				maskClosable={true}
				width={1000}
				closable={true}
				footer={null}
			>
				<Table
					dataSource={allPayPeriodData}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Work week"
						dataIndex="workWeek"
						key="workWeek"
						className="bg-white"
						width={'15%'}
						render={(text: string[]) => {
							const formattedWorkWeek = formatWorkWeek(text);
							return (
								<Ellipse
									message={formattedWorkWeek}
									maxLength={20}
									key={formattedWorkWeek}
									tooltipMessage={formattedWorkWeek}
									isTooltip={true}
								/>
							);
						}}
					/>
					<Column
						title="Employee Pay Date"
						dataIndex="startPayroll"
						key="startPayroll"
						sorter={true}
						className="bg-white"
						width={'15%'}
						render={(text) => dayjs(text).format('DD')}
					/>
					<Column
						title="Start Date"
						dataIndex="startPayroll"
						key="startPayroll"
						sorter={true}
						className="bg-white"
						width={'15%'}
						render={(text) => dayjs(text).format('MMM,YYYY')}
					/>
					<Column
						title="Pay Date"
						dataIndex="startPayroll"
						key="startPayroll"
						sorter={true}
						className="bg-white"
						width={'15%'}
						render={(text) => dayjs(text).format('DD/MM/YYYY')}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
