import { getApi, postApi } from 'apis';
// import axios from 'axios';
import { LoginLayoutBody } from 'components/Login';
import { FORMDATA } from 'constants/Data';
import { LoginLayout } from 'layouts/Login';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import { fetchProfileAction } from '../../redux/actions/profileAction';
import { PermissionContext } from 'components/Global/AuthLayout';
import { jwtDecode } from 'jwt-decode';

// Login page
const Login = () => {
	// React Hooks

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [isLoading, setIsLoading] = useState(false);

	const [isPasswordValidate, setIsPasswordValidate] = useState(false);

	const context = useContext(PermissionContext);

	const onSubmit = async (values: any) => {
		if (isPasswordValidate) {
			setIsLoading(true);
			try {
				const res = await postApi(`/auth/login`, values);
				const userData: User = jwtDecode(
					res.data.accessToken as string
				);
				localStorage.setItem('accessToken', res.data.accessToken);
				const response = await dispatch(fetchProfileAction());
				// const response = await getApi('/auth/fetch-profile');
				context.permissionHandler(
					response?.payload?.data?.permissions,
					userData?.companyId,
					userData.id,
					userData.isSuperAdmin,
					userData?.isAdmin
				);
				setIsLoading(false);
				navigate('/');
				// navigate('/settings/users');
			} catch (error: any) {
				toastText(error.response.data?.message, 'error');
				setIsLoading(false);
			}
		}
	};

	// JSX for the Login page
	return (
		<LoginLayout>
			<LoginLayoutBody
				title="Log in"
				// description="<p>
				// 			Welcome to <strong> WageWorks Payroll App! </strong>Please Enter your
				// 			Details.
				// 		</p>"
				formData={FORMDATA.loginFields}
				buttonTitle={'Sign in'}
				redirectUrl="/forgot-password"
				redirectText="Forgot password?"
				onSubmit={onSubmit}
				isLoading={isLoading}
				setIsPasswordValidate={setIsPasswordValidate}
			></LoginLayoutBody>
		</LoginLayout>
	);
};

export default Login;
interface User {
	email: string;
	exp: number;
	iat: number;
	id: string;
	isAdmin: boolean;
	type: string;
	permissions: any;
	isSuperAdmin: boolean;
	companyId: string;
}
