import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';

const { Column, ColumnGroup } = Table;

type Props = {
	salaryData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	isLoading: boolean;
};

const SalaryTable = (props: Props) => {
	const {
		salaryData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
	} = props;

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={salaryData}
				// scroll={{ y: 'calc(100vh - 200px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Monthly Salary"
					dataIndex="monthlySalary"
					key="monthlySalary"
					// sorter={true}
				/>
				<Column
					title="Hourly Salary"
					dataIndex="hourlySalary"
					key="hourlySalary"
					// sorter={true}
				/>
				<Column
					title="Effective Start Date"
					dataIndex="effectiveStartDate"
					key="effectiveStartDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					// sorter={true}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					// sorter={true}
					render={(value: string) => (value ? value : '-')}
				/>
			</Table>
		</div>
	);
};

export default SalaryTable;
