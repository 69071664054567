import { postApi, getApi } from 'apis';
const createSkillLevy = async (data: any) => {
	return await postApi('/skillLevy/create', data, true);
};

const getLatestSkillLevy = async () => {
	return await getApi('/skillLevy/latest');
};

const getSkillLevyHistory = async (params: any) => {
	return await getApi(`/skillLevy/history`, params);
};

export const skillLevyApi = {
	createSkillLevy,
	getLatestSkillLevy,
	getSkillLevyHistory,
};
