import { putApi } from 'apis';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddSvg, CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import LeaveRequestHeader from './Header';
import LeaveRequestTable from './LeaveRequestTable';
import AddLeaveRequestModal from './AddLeaveRequestModal';
import { leaveRequestApi } from 'Api/leaveRequest';

const LeaveRequestComponent = (props: any) => {
	const navigate = useNavigate();
	const [LeaveRequestData, setLeaveRequestData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deleteLeaveRequestId, setDeleteLeaveRequestId] = useState<
		string | null
	>(null);

	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isAddLeaveRequestModalOpen, setIsAddLeaveRequestModalOpen] =
		useState(false);
	const [edit, setEdit] = useState<any>(null);
	const [searchText, setSearchText] = useState('');
	const [monthDate, setMonthDate] = useState<any>(dayjs().month() + 1);
	const [yearDate, setYearDate] = useState<any>(dayjs());

	const buttons = [
		{
			text: 'Add Leave Request',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsAddLeaveRequestModalOpen(true);
			},
			disabled: false,
		},
	];
	const showDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const deleteHandler = async () => {
		if (deleteLeaveRequestId) {
			try {
				setIsDeleteLoading(true);
				await leaveRequestApi.deleteLeaveRequest(deleteLeaveRequestId);
					toastText('Leave Request Deleted successfully', 'success');
				cancelDeleteModal();
				fetchLeaveRequest();

			} catch (error) {
				toastText(
					'Something went wrong in delete Leave Request',
					'error'
				);
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};
	const handleEdit = (leaveObj: any) => {
		setIsAddLeaveRequestModalOpen(true);
		setEdit(leaveObj);
	};
	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};
	const fetchLeaveRequest = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: searchText,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				year: dayjs(yearDate).format('YYYY'),
				month: monthDate,
			};
			setIsLoading(true);
			const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			setLeaveRequestData(leaveRequest.data.data);
			setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleSearch = (value: string) => {
		setSearchText(value);
	};

	const handleMonthDatePicker = (value: any) => {
		setMonthDate(value);
	};

	const handleYearDatePicker = (value: any) => {
		setYearDate(value);
	};

	const handleSearchApi = async (value: string) => {
		console.log('🚀 ~ handleSearchApi ~ value:', value);
	};

	const handleCancel = () => {
		setIsAddLeaveRequestModalOpen(false);
	};

	const cancelDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setDeleteLeaveRequestId(null);
	};

	const handleOk = () => {
		setIsDeleteModalOpen(false);
		setDeleteLeaveRequestId(null);
	};

	useEffect(() => {
		fetchLeaveRequest();
	}, [
		currentPage,
		sortField,
		sortOrder,
		pageSize,
		searchText,
		monthDate,
		yearDate,
	]);

	return (
		<div className="modal-animation">
			<div className={styles['time-logs']}>
				<div className={styles['time-logs__header']}>
					<div className={styles['time-logs__header__title']}>
						Leave Request
					</div>
					<div
						className={styles['time-logs__header__actions']}
						onClick={() => {
							navigate('/');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['time-logs__table']}>
					<LeaveRequestHeader
						searchText={searchText}
						handleSearch={handleSearch}
						handleSearchApi={handleSearchApi}
						button={buttons}
						handleMonthDatePicker={handleMonthDatePicker}
						monthValue={monthDate}
						handleYearDatePicker={handleYearDatePicker}
						yearValue={yearDate}
					/>
					<LeaveRequestTable
						tableData={LeaveRequestData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						isLoading={isLoading}
						tableChangeHandler={tableChangeHandler}
						showDeleteModal={showDeleteModal}
						setDeleteLeaveRequestId={setDeleteLeaveRequestId}
						handleEdit={handleEdit}
					/>
				</div>
			</div>

			<AddLeaveRequestModal
				isAddLeaveRequestModalOpen={isAddLeaveRequestModalOpen}
				handleCancel={handleCancel}
				fetchLeaveRequest={fetchLeaveRequest}
				edit={edit}
				setEdit={setEdit}
			/>
			<ConfirmDelete
				isModalOpen={isDeleteModalOpen}
				handleCancel={cancelDeleteModal}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
				handleOk={handleOk}
			/>
		</div>
	);
};

export default LeaveRequestComponent;
