import { deleteApi, getApi, postApi, putApi, getApiCSV } from 'apis';

const getAllHolidays = async (params: any) => {
	return await getApi('/holiday', params);
};

const getHolidaysForCurrentYear = async (currentYear: number) => {
	return await getApi(`/holiday/${currentYear}`);
};

const createHolidayManually = async (data: any) => {
	return await postApi('/holiday', data);
};

const updateHoliday = async (holidayId: string, data: any) => {
	return await putApi(`/holiday/${holidayId}`, data);
};

const deleteHoliday = async (holidayId: string) => {
	return await deleteApi(`/holiday/${holidayId}`);
};
const getCsvTemplate = async () => {
	return await getApiCSV('/holiday/csv-download');
};

const uploadCsv = async (data: any) => {
	return await postApi('/holiday/csv-upload', data);
};

export const holidayApi = {
	getAllHolidays,
	createHolidayManually,
	updateHoliday,
	deleteHoliday,
	getCsvTemplate,
	uploadCsv,
	getHolidaysForCurrentYear,
};
