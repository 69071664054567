import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllPayPoints } from 'Api/masters/pay-point';

export const fetchPayPointAction = createAsyncThunk(
	'payPoint',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getAllPayPoints(params);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
