import { Col, Form, Row, Button, Select, Radio } from 'antd';
import { FC, useEffect, useState } from 'react';
import Buttons from 'components/Global/Buttons';
import { formatToDateOnly, toastText } from 'utils/utils';
import './index.scss';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import { payPeriodApi } from 'Api/payPeriod';

const AddPayPeriodSideDrawer: FC<SideDrawerBodyProps> = (props) => {
	const {
		closeDrawerByAnimation,
		fetchLatestPayPeriodData,
		fetchAllPayPeriodDetails,
	} = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [date, setDate] = useState<any>(dayjs());
	const [selectedDays, setSelectedDays] = useState<string[]>([
		'Mon',
		'Tue',
		'Wed',
		'Thur',
		'Fri',
		'Sat',
	]);
	const [specificSelectedDay, setSpecificSelectedDay] = useState<
		number | undefined
	>();
	const [selectedOption, setSelectedOption] =
		useState<string>('payOnLastDay');
	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
	const [payDate, setPayDate] = useState<any>(null);
	const [startDate, setStartDate] = useState<any>(dayjs());
	const [payPeriod, setPayPeriod] = useState<string>('');
	const [dropdownDays, setDropdownDays] = useState<number[]>([]);

	useEffect(() => {
		if (startDate) {
			{
				setPayPeriod(`(Pay Period: ${startDate.format('MMMM-YYYY')})`);
			}

			let nextMonthDate = startDate.startOf('month');
			if (selectedOption === 'payOnLastDay') {
				setPayDate(getPayOnLastDayOfMonth(nextMonthDate));
			} else if (
				selectedOption === 'payOnSpecificDay' &&
				specificSelectedDay !== undefined
			) {
				setPayDate(
					getDateOfNextMonth(nextMonthDate, specificSelectedDay)
				);
			}
			const daysInMonth = nextMonthDate.daysInMonth();
			setDropdownDays(
				Array.from({ length: daysInMonth - 1 }, (_, i) => i + 1)
			);

			if (specificSelectedDay && specificSelectedDay > daysInMonth) {
				setSpecificSelectedDay(undefined);
				form.setFieldsValue({ specificDay: undefined });
			}
		}
	}, [selectedOption, specificSelectedDay, startDate]);

	const buttons = [
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
	];

	const [form] = Form.useForm();

	const handleDate = (value: any) => {
		setDate(value);
	};

	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
	const handleRadioChange = (e: any) => {
		const newOption = e.target.value;
		setSelectedOption(newOption);
		if (newOption !== 'payOnSpecificDay') {
			setDropdownVisible(false);
			setSpecificSelectedDay(undefined);
			form.setFieldsValue({ specificDay: undefined });
		}
		form.validateFields(['payOption']);
	};

	const getPayOnLastDayOfMonth = (date: dayjs.Dayjs) => {
		let lastDay = date.endOf('month');
		return lastDay;
	};

	const getDateOfNextMonth = (date: dayjs.Dayjs, day: number) => {
		return dayjs(date).date(day);
	};

	const handleStartDateChange = (date: any) => {
		setStartDate(date);
		setSpecificSelectedDay(undefined);
		form.setFieldsValue({ specificDay: undefined });

		setSelectedOption('payOnLastDay');
		form.setFieldsValue({ payOption: 'payOnLastDay' });
		form.validateFields(['payOption']);
	};

	const handleDropdownChange = (value: number) => {
		setSpecificSelectedDay(value);
		form.validateFields(['payOption']);
	};

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const handleDaySelect = (day: string) => {
		setSelectedDays((prev) =>
			prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
		);
		form.validateFields(['workWeek']);
	};
	const handleSubmit = async (values: any) => {
		setLoading(true);

		const payDateObj = new Date(payDate);
		const month = payDateObj.getMonth() + 1;
		const year = payDateObj.getFullYear();

		const workWeek = selectedDays;
		const payOnLastDay = selectedOption === 'payOnLastDay';
		const payOnSpecificDay = selectedOption === 'payOnSpecificDay';

		const payPeriodFormData = {
			workWeek,
			payOnLastDay,
			payOnSpecificDay,
			month,
			year,
			specificDay: specificSelectedDay || null,
		};

		try {
			await payPeriodApi.createPayPeriod(payPeriodFormData);
			toastText('Pay Period details saved successfully', 'success');
			closeDrawerByAnimation(true);
			fetchLatestPayPeriodData();
			fetchAllPayPeriodDetails();
		} catch (error: any) {
			let errorMessage = 'Failed to save Pay Period details';
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				errorMessage = error.response.data.message;
			}

			toastText(errorMessage, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 24 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={{ startPayroll: date }}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item
							name="workWeek"
							rules={[
								{
									validator: (_, value) =>
										selectedDays.length > 0
											? Promise.resolve()
											: Promise.reject(
													new Error(
														'Please select at least one day'
													)
											  ),
								},
							]}
							validateTrigger="onChange"
						>
							<div className={styles['field-container']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Select your work week'}{' '}
									<span className="required-color">*</span>
								</label>
								<span
									className={
										styles['side-drawer-form__notes']
									}
								>
									The days worked in a calendar week{' '}
								</span>
								<div className={styles['day-button-container']}>
									<Row gutter={24}>
										{daysOfWeek.map((day) => (
											<Col key={day}>
												<Button
													size="small"
													className="day-button"
													type={
														selectedDays.includes(
															day
														)
															? 'primary'
															: 'default'
													}
													onClick={() =>
														handleDaySelect(day)
													}
												>
													{day}
												</Button>
											</Col>
										))}
									</Row>
								</div>
							</div>
						</Form.Item>
						<div className="radio-container">
							<Form.Item
								name="payOption"
								rules={[
									{
										validator: (_, value) => {
											if (
												selectedOption ===
													'payOnSpecificDay' &&
												!specificSelectedDay
											) {
												return Promise.reject(
													new Error(
														'Please select a specific day'
													)
												);
											}
											return Promise.resolve();
										},
									},
								]}
								validateTrigger="onChange"
							>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Pay your employee on'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<Radio.Group
										onChange={handleRadioChange}
										value={selectedOption}
									>
										<Row>
											<Col span={24}>
												<Radio
													id="payOnLastDay"
													className={
														styles['pay-radio']
													}
													value="payOnLastDay"
												>
													The last working day of
													every month
												</Radio>
											</Col>
											<Col span={24}>
												<Radio
													id="payOnSpecificDay"
													className={
														styles['pay-radio']
													}
													value="payOnSpecificDay"
												>
													Day{' '}
													<Select
														id="specificDay"
														value={
															specificSelectedDay
														}
														onChange={
															handleDropdownChange
														}
														open={
															selectedOption ===
															'payOnSpecificDay'
																? dropdownVisible
																: false
														}
														onClick={toggleDropdown}
														style={{
															width: 80,
															marginLeft: 8,
															display:
																'inline-block',
														}}
													>
														{dropdownDays.map(
															(day) => (
																<Select.Option
																	key={day}
																	value={day}
																>
																	{day}
																</Select.Option>
															)
														)}
													</Select>{' '}
													of the following month
												</Radio>
											</Col>
										</Row>
									</Radio.Group>
									<br />
								</div>
							</Form.Item>
						</div>
						<span className={styles['side-drawer-form__notes']}>
							Note: When payday falls on a non-working day or a
							holiday, employees will get paid on the previous
							working day.
						</span>

						<div className={styles['field-container']}>
							<div className={styles['field-container-label']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Start your first payroll from'}{' '}
									<span className="required-color">*</span>
								</label>
							</div>
							<DatePickerField
								onChange={handleStartDateChange}
								value={startDate}
								isError={false}
								disabled={false}
								placeholder="Select Date"
								required
								name="startPayroll"
								disabledBeforeDates={dayjs().startOf('day')}
								picker="month"
							/>
						</div>
						<div className={styles['field-container']}>
							<div className={styles['field-container-label']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Selected pay date for your first payroll'}

									{/* <span className="required-color">*</span> */}
								</label>
								<p style={{ color: '#584495' }}>{payPeriod}</p>
							</div>
							<DatePickerField
								onChange={handleDate}
								value={payDate}
								isError={false}
								disabled={true}
								placeholder="Selected Pay Date"
								required
								name="startPayroll"
							/>
						</div>
					</div>

					<div className={styles['side-drawer-form__buttons']}>
						<Buttons buttons={buttons} />
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddPayPeriodSideDrawer;
