import { postApi, getApi } from 'apis';
const createPersonalLevy = async (data: any) => {
	return await postApi('/personalLevy/create', data, true);
};

const getLatestPersonalLevyDetails = async () => {
	return await getApi('/personalLevy/latest');
};

const getAllPersonalLevyDetails = async (params: any) => {
	return await getApi(`/personalLevy/history`, params);
};

export const personalLevyApi = {
	createPersonalLevy,
	getAllPersonalLevyDetails,
	getLatestPersonalLevyDetails,
};
