import { deleteApiWithData, getApi, postApi, putApi } from 'apis';

export const getAllDepartments = async (params: any) => {
	return await getApi('/department', params);
};

export const createDepartment = async (data: any) => {
	return await postApi('/department', data);
};

export const updateDepartment = async (data: any) => {
	return await putApi('/department', data);
};

export const deleteDepartment = async (data: any) => {
	return await deleteApiWithData('/department', data);
};
