import { Input, Typography } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { ChangeEvent, useState } from 'react';
import { invalidText } from 'utils/utils';

type Props = {
	name: string;
	label: string;
	value: string | number;
	placeholder?: string;
	required: boolean;
	isError: boolean;
	onChange: (value: string) => void;
	helperText?: string;
	prefix?: any;
	suffix?: any;
	regex?: string;
	disabled?: boolean;
	type?: string;
	size?: SizeType;
	showLabel?: boolean;
	style?: any;
	width?: any;
	rows?: number;
	isViewOnly?: boolean;
};

const InputField = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		prefix = null,
		suffix = null,
		regex,
		required = false,
		onChange,
		disabled = false,
		type = 'text',
		helperText = 'Invalid field',
		size = 'large',
		showLabel = true,
		style,
		width,
		rows,
		isViewOnly=false,
	} = props;

	const [hasError, setHasError] = useState(false);

	const handleChange = (value: string) => {
		if (required) {
			setHasError(invalidText(value));
		}

		// if (regex) {
		// 	const _regex = new RegExp(regex);
		// 	setHasError(!_regex.test(value));
		// }

		onChange(value);
	};

	const handleBlur = (value: string) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
	};

	return (
		<div className="input-field">
			{showLabel && label && (
				<p
					className="label"
					style={{ marginBottom: `${isViewOnly ? '5px' : ''}` }}
				>
					{label}{' '}
					{required && !isViewOnly && <span className="red">*</span>}
				</p>
			)}
			{isViewOnly ? (
				<p>{value}</p>
			) : (
				<div>
					{!rows ? (
						<Input
							name={name}
							status={isError || hasError ? 'error' : ''}
							placeholder={placeholder}
							prefix={prefix}
							suffix={suffix}
							width={width}
							value={value}
							required={required}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleChange(e.target.value)
							}
							onBlur={(e: ChangeEvent<HTMLInputElement>) =>
								handleBlur(e.target.value)
							}
							type={type}
							size={size}
							disabled={disabled}
							style={style}
						/>
					) : (
						<Input.TextArea
							name={name}
							status={isError || hasError ? 'error' : ''}
							placeholder={placeholder}
							rows={rows}
							value={value}
							required={required}
							onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
								handleChange(e.target.value)
							}
							onBlur={(e: ChangeEvent<HTMLTextAreaElement>) =>
								handleBlur(e.target.value)
							}
							size={size}
							disabled={disabled}
							style={style}
						/>
					)}
					{(isError || hasError) && (
						<p
							className="red"
							style={{
								fontSize: '12px',
								marginLeft: '2px',
							}}
						>
							{helperText}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default InputField;
