import { useContext, useEffect } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import LeaveRequestComponent from 'components/LeaveRequest';

export const LeaveRequest = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Leave_Request');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <LeaveRequestComponent />}</div>;
};
