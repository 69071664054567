import { Space } from 'antd';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	changeYear: (value: any) => void;
	selectedYear: string;
	button: any;
};

const SalaryDetailHeader = (props: Props) => {
	const { button, changeYear, selectedYear } = props;

	const permissionsContext = useContext(PermissionContext);

	const isAdd =
		permissionsContext.allowedPermissions.includes('Add_Salary');

	return (
		<div className={styles['employee-header']}>
			<Space className={styles['employee-header-left']}>
				Salary Details
			</Space>
			<Space className={styles['employee-header-right']}>
				<DatePickerField
					label=""
					name="year"
					onChange={changeYear}
					value={selectedYear}
					disabled={false}
					placeholder="Year"
					required={false}
					isError={false}
					picker="year"
				/>
				{isAdd && <Buttons buttons={button} />}
			</Space>
		</div>
	);
};

export default SalaryDetailHeader;
