import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { shiftsApi } from 'Api/shifts';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import TimePickerField from 'components/Global/TimePicker';
import InputHoursField from 'components/InputHoursField';
import dayjs, { Dayjs } from 'dayjs';
import { ShiftDetailsInterface } from 'interfaces/employee.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
type Props = {
	closeModal: () => void;
	isModalOpen: boolean;
	editShift: any;
	fetchShifts: () => void;
	isViewOnly: boolean;
};

const CreateShiftModal = (props: Props) => {
	const {
		isModalOpen,
		closeModal,
		editShift,
		fetchShifts,
		isViewOnly = false,
	} = props;

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	// const [constantDropdownOptions, setConstantDropdownOptions] = useState<any>(
	// 	[]
	// );

	const [shiftDetails, setShiftDetails] = useState<ShiftDetailsInterface>({
		startDate: '',
		endDate: '',
		startTime: dayjs(new Date()),
		endTime: dayjs(new Date()),
		branchId: '',
		shiftName: '',
		employeeIds: [],
		supervisorId: '',
		notes: null,
		defaultRegularHours: '08:00',
		defaultOverTimeHours: '02:00',
	});
	const [hasError, setHasError] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [formError, setFormError] = useState<any>({
		startDate: false,
		endDate: false,
		startTime: false,
		endTime: false,
		branchId: false,
		shiftName: false,
		employeeIds: false,
		supervisorId: false,
		notes: false,
	});

	const handleCloseModal = async () => {
		setShiftDetails({
			startDate: '',
			endDate: '',
			startTime: dayjs(new Date()),
			endTime: dayjs(new Date()),
			branchId: '',
			shiftName: '',
			employeeIds: [],
			supervisorId: '',
			notes: null,
			defaultRegularHours: '08:00',
			defaultOverTimeHours: '02:00',
		});
		setFormError({
			startDate: false,
			endDate: false,
			startTime: false,
			endTime: false,
			branchId: false,
			shiftName: false,
			employeeIds: false,
			supervisorId: false,
			notes: false,
			defaultRegularHours: false,
			defaultOverTimeHours: false,
		});
		setHasError(false);
		closeModal();
	};

	const handleChange = (
		value: string | number | null | string[] | Dayjs | any,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}
		if (name === 'branchId') {
			setShiftDetails((prev) => {
				return {
					...prev,
					employeeIds: [],
				};
			});
		}
		if (name === 'startDate') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev) => {
				return {
					...prev,
					startTime: dayjs(value as string),
				};
			});
		}
		if (name === 'endDate') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev) => {
				return {
					...prev,
					endTime: dayjs(value as string),
				};
			});
		}
		if (name === 'defaultRegularHours') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev: any) => {
				return {
					...prev,
					defaultRegularHours: value,
				};
			});
		}
		if (name === 'defaultRegularHours') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev: any) => {
				return {
					...prev,
					defaultRegularHours: value,
				};
			});
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | string[] | Dayjs,
		key: string
	) => {
		setShiftDetails((prev) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...shiftDetails,
			},
			{ ...formError }
		);
		checkFormError = {
			...checkFormError,
			employeeIds: shiftDetails.employeeIds.length < 1 ? true : false,
			notes: false,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			let _data: any = {
				...shiftDetails,
				startDate: formatToDateOnly(shiftDetails.startDate),
				endDate: formatToDateOnly(shiftDetails.endDate),
				defaultRegularHours: timeStringToMinutes(
					shiftDetails.defaultRegularHours
				),
				defaultOverTimeHours: timeStringToMinutes(
					shiftDetails.defaultOverTimeHours
				),
			};
			if (!_data.notes) {
				delete _data['notes'];
			}

			setIsSubmitLoading(true);
			if (!editShift) {
				try {
					const apiRes = await shiftsApi.createShifts(_data);
					const successMessage =
						apiRes?.data?.message || 'Shift created successfully';
					toastText(successMessage, 'success');
					fetchShifts();
					handleCloseModal();
				} catch (error: any) {
					let message =
						'Something went wrong in fetching employee details';

					if (error.response.data.message) {
						message = error.response.data.message;
					}
					toastText(message, 'error');
				}
			} else {
				try {
					const apiRes = await shiftsApi.updateShift(
						editShift.id,
						_data
					);
					const successMessage =
						apiRes?.data?.message || 'Shift edit successfully';
					toastText(successMessage, 'success');
					fetchShifts();
					handleCloseModal();
				} catch (error: any) {
					const message =
						error?.response?.data?.message ||
						'Something went wrong in editing shift';
					toastText(message, 'error');
				}
			}
			setIsSubmitLoading(false);
		}
	};

	const buttons = [
		{
			text: `${editShift ? 'Edit' : 'Save'}`,
			isLoading: isSubmitLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleCloseModal();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	useEffect(() => {
		if (isModalOpen) {
			if (editShift) {
				setShiftDetails({
					...editShift,
					startDate: dayjs(editShift.startDate),
					endDate: dayjs(editShift.endDate),
					startTime: dayjs(editShift.startTime),
					endTime: dayjs(editShift.endTime),
					defaultRegularHours: minutesToTimeString(
						editShift.defaultRegularHours
					),
					defaultOverTimeHours: minutesToTimeString(
						editShift.defaultOverTimeHours
					),
				});
			} else {
				setShiftDetails({
					startDate: '',
					endDate: '',
					startTime: dayjs(new Date()),
					endTime: dayjs(new Date()),
					branchId: '',
					shiftName: '',
					employeeIds: [],
					supervisorId: '',
					notes: null,
					defaultRegularHours: '08:00',
					defaultOverTimeHours: '02:00',
				});
			}
			setFormError({
				startDate: false,
				endDate: false,
				startTime: false,
				endTime: false,
				branchId: false,
				shiftName: false,
				employeeIds: false,
				supervisorId: false,
				notes: false,
				defaultRegularHours: false,
				defaultOverTimeHours: false,
			});
		}
		setIsDisabled(false);
		setHasError(false);
	}, [isModalOpen]);
	return (
		<>
			<Modal
				open={isModalOpen}
				// onOk={handleSubmit}
				onCancel={handleCloseModal}
				okText={'Save'}
				closable={false}
				footer={null}
				className="document__popup"
				width="1000px"
				maskClosable={isViewOnly ? true : false}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>
							{editShift && isViewOnly ? (
								<span>{`Shift Details`}</span>
							) : (
								<span>{`${
									editShift ? 'Edit' : 'Add'
								} Shift`}</span>
							)}
						</Title>
						<div className={styles['modal-header-filter']}></div>
					</div>
					<div>
						<Row
							className={styles['form-container-card']}
							gutter={50}
						>
							{isViewOnly && (
								<hr
									style={{
										width: '100%',
										marginBottom: '17px',
									}}
								/>
							)}
							<Col span={12} className={styles['col']}>
								<DatePickerField
									name="startDate"
									value={shiftDetails.startDate}
									label="Shift Start Date"
									required={true}
									helperText="Shift start date required"
									onChange={(value) => {
										handleChange(value, 'startDate', true);
									}}
									isError={formError.startDate}
									disabled={isDisabled}
									disabledBeforeDates={dayjs().startOf('day')}
									disabledAfterDates={
										shiftDetails?.endDate
											? shiftDetails.endDate
											: ''
									}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<DatePickerField
									name="endDate"
									value={shiftDetails.endDate}
									label="Shift End Date"
									required={true}
									helperText="Shift end date required"
									onChange={(value) => {
										handleChange(value, 'endDate', true);
									}}
									isError={formError.endDate}
									disabled={
										shiftDetails.startDate ? false : true
									}
									disabledBeforeDates={shiftDetails.startDate}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<TimePickerField
									name="startTime"
									value={dayjs(shiftDetails.startTime)}
									label="Shift Schedule Start Time"
									required={true}
									helperText="Shift schedule start time required"
									onChange={(value) => {
										handleChange(
											dayjs(value),
											'startTime',
											true
										);
									}}
									isError={formError.startTime}
									disabled={
										!shiftDetails.startDate || isDisabled
									}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<TimePickerField
									name="endTime"
									value={dayjs(shiftDetails.endTime)}
									label="Shift Schedule End Time"
									required={true}
									helperText="Shift schedule end time required"
									onChange={(value) => {
										handleChange(
											dayjs(value),
											'endTime',
											true
										);
									}}
									isError={formError.endTime}
									disabled={
										!shiftDetails.endDate || isDisabled
									}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<SelectDropdown
									placeholder="Select Branch"
									options={
										constantDropdownOptions?.branchCode
									}
									value={shiftDetails.branchId}
									onChange={(value: any) => {
										handleChange(value, 'branchId', true);
									}}
									size="large"
									required={true}
									helperText="Branch is required"
									label="Branch"
									disabled={isDisabled}
									isError={formError.branchId}
									isViewOnly={isViewOnly}
								/>
							</Col>

							<Col span={12} className={styles['col']}>
								<InputField
									name="shiftName"
									value={shiftDetails.shiftName}
									label="Shift Name"
									required={true}
									helperText="Shift name required"
									onChange={(value) => {
										handleChange(value, 'shiftName', true);
									}}
									isError={formError.shiftName}
									disabled={isDisabled}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<SelectDropdown
									placeholder="Select Employee"
									options={
										shiftDetails.branchId
											? constantDropdownOptions?.employees.filter(
													(item: any) =>
														item.employeeBranchId ===
														shiftDetails.branchId
											  )
											: []
									}
									mode="multiple"
									value={shiftDetails.employeeIds}
									onChange={(value: any) => {
										handleChange(
											value,
											'employeeIds',
											true
										);
									}}
									size="large"
									required={true}
									helperText="Atleast 1 employee required"
									label="Employees"
									disabled={isDisabled}
									isError={formError.employeeIds}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={12} className={styles['col']}>
								<SelectDropdown
									placeholder="Select Supervisor"
									options={constantDropdownOptions?.supervisors?.filter(
										(item: any) => item.label !== 'All'
									)}
									value={shiftDetails.supervisorId}
									onChange={(value: any) => {
										handleChange(
											value,
											'supervisorId',
											true
										);
									}}
									size="large"
									required={true}
									helperText="Supervisor required"
									label="Supervisor"
									disabled={isDisabled}
									isError={formError.supervisorId}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={8} className={styles['col']}>
								<InputHoursField
									value={shiftDetails.defaultRegularHours}
									onChange={(value) => {
										handleChange(
											value,
											'defaultRegularHours',
											true
										);
									}}
									label="Standard Regular Hours"
									showLabel={true}
									required={true}
									name="defaultRegularHours"
									size="large"
									style={{
										width: '100px',
									}}
									isError={false}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={8} className={styles['col']}>
								<InputHoursField
									value={shiftDetails.defaultOverTimeHours}
									onChange={(value) => {
										handleChange(
											value,
											'defaultOverTimeHours',
											true
										);
									}}
									label="Standard Over Time Hours"
									showLabel={true}
									required={true}
									name="defaultOverTimeHours"
									size="large"
									style={{
										width: '100px',
									}}
									isError={false}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={8} className={styles['col']}>
								<InputHoursField
									value={minutesToTimeString(
										timeStringToMinutes(
											shiftDetails.defaultRegularHours
										) +
											timeStringToMinutes(
												shiftDetails.defaultOverTimeHours
											)
									)}
									onChange={(value) => {}}
									disabled={true}
									label="Standard Total Hours"
									showLabel={true}
									required={false}
									name=""
									size="large"
									style={{
										width: '100px',
									}}
									isError={false}
									isViewOnly={isViewOnly}
								/>
							</Col>
							<Col span={24} className={styles['col']}>
								<InputField
									name="notes"
									value={shiftDetails.notes as string}
									label="Notes"
									required={false}
									helperText="Notes required"
									onChange={(value) => {
										handleChange(value, 'notes', false);
									}}
									isError={formError.notes}
									disabled={isDisabled}
									rows={2}
									isViewOnly={isViewOnly}
								/>
							</Col>
						</Row>

						{!isViewOnly && (
							<Row className={styles['modal-buttons']}>
								<Buttons buttons={buttons} />
							</Row>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CreateShiftModal;
