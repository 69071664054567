import { configureStore } from '@reduxjs/toolkit';
import profileSlice from './slices/profileSlice';
import AuthSlice from './slices/costCenterSlice';
import userCheckSlice from './slices/userCheckSlice';
import DepartmentSlice from './slices/departmentSlice';
import CategorySlice from './slices/categorySlice';
import BranchCodeSlice from './slices/branchCodeSlice';
import CostCenterSlice from './slices/costCenterSlice';
import JobGradeSlice from './slices/jobGradeSlice';
import PayPointSlice from './slices/payPointSlice';
import ConstantDropdownSlice from './slices/constantDropdown';

const store = configureStore({
	reducer: {
		userProfile: profileSlice.reducer,
		auth: AuthSlice.reducer,
		userCheck: userCheckSlice.reducer,
		departments: DepartmentSlice.reducer,
		categories: CategorySlice.reducer,
		branches: BranchCodeSlice.reducer,
		costCenters: CostCenterSlice.reducer,
		jobGrades: JobGradeSlice.reducer,
		payPoints: PayPointSlice.reducer,
		constantDropdown: ConstantDropdownSlice.reducer,
	},
});

export default store;
export type AppDispatch = typeof store.dispatch;
