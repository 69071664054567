import { createSlice } from '@reduxjs/toolkit';
import { fetchCategoryAction } from '../actions/categoryAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCategoryAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchCategoryAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(fetchCategoryAction.rejected, (state, action: any) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default CategorySlice;
