import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Image, Row, Select, Tooltip } from 'antd';
import { postApi } from 'apis';
import NotificationModal from 'components/Notification';
import UserProfileModal from 'components/Profile';
import { configurationModules, payrollModules } from 'constants/Data';
import { jwtDecode } from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationSvg, NotificationSvgRed } from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import { logoutAction } from '../../../redux/actions/logoutAction';
import { clearProfileRedux } from '../../../redux/slices/profileSlice';
import { PermissionContext } from '../AuthLayout';
import UserNameBox from '../UserNameBox';
import styles from './index.module.scss';
import './index.scss';
import { notificationApi } from 'Api/notification';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const permissionContext = useContext(PermissionContext);
	const context = useContext(PermissionContext);

	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
	const [notifications, setNotifications] = useState(0);
	const [isNotificationModalOpen, setNotificationModalOpen] =
		useState<boolean>(false);
	const [isLogoutLoading, setIsLogoutLoading] = useState(false);

	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

	const { data: userData } = useSelector((state: any) => state?.userProfile);

	const logoutData = {
		email: userData?.email,
		accessToken: userData?.accessToken,
	};

	// Logout Handler
	const logoutHandler = async () => {
		setIsLogoutLoading(true);
		setIsLogoutLoading(false);
		try {
			const data = { logoutData };
			const response = await dispatch(logoutAction(data) as any);

			dispatch(clearProfileRedux());
			context.permissionHandler('', '', '', false, false, true);
			toastText(response?.payload?.message, 'success');
			navigate('/login');
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
			localStorage.clear();
		} finally {
			localStorage.removeItem('accessToken');
		}
	};

	// My Profile Handler
	const myProfileHandler = () => {
		setProfileModalOpen(true);
	};

	const profileCancel = () => {
		setProfileModalOpen(false);
	};

	const handleNavigatePermissionWise = (currentPath: string) => {
		const targetPath = currentPath.includes('settings') ? '/' : '/settings';
		navigate(targetPath);
	};

	const companyHandler = async (id: string) => {
		try {
			const company = await postApi('/company', {
				companyId: id,
			});
			localStorage.setItem('accessToken', company.data.accessToken);
			setSelectedCompany(id);
			window.location.reload();
		} catch (err) {
			console.log('ERRR: ', err);
		}
	};

	useEffect(() => {
		if (userData && userData.companies.length > 0) {
			setCompanies(userData.companies);
			const tokenData: {
				id: string;
				email: string;
				companyId: string;
			} = jwtDecode(localStorage.getItem('accessToken') as string);
			setSelectedCompany(tokenData.companyId);
		}
	}, [userData]);

	const [isUserPermission, setIsUserPermission] = useState(true);
	const [isRolePermission, setIsRolePermission] = useState(true);
	const [isConfigurationPermission, setIsConfigurationPermission] =
		useState(true);
	const [isPayrollSettingsPermission, setIsPayrollSettingsPermission] =
		useState(true);

	useEffect(() => {
		const _isUserPermission = checkPermission(
			permissionContext.permissions,
			{
				permissionName: 'Users',
				permission: ['add', 'view', 'edit', 'delete'],
			}
		);

		const _isRolePermission = checkPermission(
			permissionContext.permissions,
			{
				permissionName: 'Roles',
				permission: ['add', 'view', 'edit', 'delete'],
			}
		);

		const _configuration = configurationModules.map((module) => {
			return checkPermission(permissionContext.permissions, {
				permissionName: module,
				permission: ['add', 'view', 'edit', 'delete'],
			});
		});

		const _configurationPermission = _configuration.some(
			(configuration) => configuration === true
		);

		const _payroll = payrollModules.map((module) => {
			return checkPermission(permissionContext.permissions, {
				permissionName: module,
				permission: ['add', 'view', 'edit', 'delete'],
			});
		});

		const __payrollPermission = _payroll.some(
			(payroll) => payroll === true
		);

		setIsConfigurationPermission(_configurationPermission);
		setIsPayrollSettingsPermission(__payrollPermission);
		setIsUserPermission(_isUserPermission);
		setIsRolePermission(_isRolePermission);
	}, [permissionContext]);

	useEffect(() => {
		getNotificationsLength();
	}, []);

	const getNotificationsLength = async () => {
		const notificationList = await notificationApi.getNotificationLength();
		console.log(
			'🚀 ~ getNotificationsLength ~ notificationList:',
			notificationList
		);
		setNotifications(notificationList?.data?.data as number);
	};

	// JSX
	return (
		<>
			<header className={styles['header']}>
				<Row
					className={styles['header__wrapper']}
					align={'middle'}
					justify={'space-between'}
				>
					<Col className={styles['header__details-left']}>
						<div className={styles['header__details-left--logo']}>
							<Image
								src="/assets/images/WageWorks.png"
								preview={false}
								alt="group"
								onClick={() => {
									navigate('/');
								}}
								style={{ cursor: 'pointer' }}
							/>
						</div>
					</Col>

					<Col className={styles['header__details-right']}>
						{companies?.length > 0 && (
							<Select
								placeholder="Select Organization"
								className={
									styles[
										'header__details-right--organization'
									]
								}
								value={selectedCompany}
								onChange={(id: string) => companyHandler(id)}
								disabled={companies?.length == 1}
							>
								{companies?.map((company: any, key: number) => (
									<Select.Option
										value={company?.id}
										key={key}
									>
										{company?.name}
									</Select.Option>
								))}
							</Select>
						)}

						<div
							style={{
								display: 'flex',
								gap: '2rem',
							}}
						>
							{(isUserPermission ||
								isRolePermission ||
								isPayrollSettingsPermission ||
								isConfigurationPermission) && (
								<Tooltip placement="bottom" title={`Settings`}>
									<SettingOutlined
										style={{ fontSize: 24 }}
										onClick={() =>
											handleNavigatePermissionWise(
												window.location.pathname
											)
										}
									/>
								</Tooltip>
							)}
							{/* {(context.isAdmin || context.isSuperAdmin) && (
								<Popover
									placement="bottomRight"
									trigger={'click'}
									content={<Notifications />}
								> */}
							{!permissionContext.isSuperAdmin && (
								<span
									onClick={() => {
										setNotificationModalOpen(true);
									}}
									className="pointer"
								>
									{notifications > 0 ? (
										<NotificationSvgRed />
									) : (
										<NotificationSvg />
									)}
								</span>
							)}
							{/* </Popover>
							)} */}
						</div>

						<Tooltip placement="bottom" title={`Profile`}>
							<div
								className={
									styles['header__details-right--user']
								}
								onClick={myProfileHandler}
							>
								<div
									className={
										styles[
											'header__details-right--user-logo'
										]
									}
								>
									<UserNameBox
										name={`${userData?.firstName} ${userData?.lastName}`}
									/>
								</div>

								<div
									className={
										styles[
											'header__details-right--user-details'
										]
									}
								>
									<p
										className={
											styles[
												'header__details-right--user-name'
											]
										}
									>
										{userData?.firstName}{' '}
										{userData?.lastName}
									</p>
									<p
										className={
											styles[
												'header__details-right--user-profile'
											]
										}
									>
										My Profile
									</p>
								</div>
							</div>
						</Tooltip>
						<div
							className={
								styles['header__details-right--user-logout']
							}
						>
							<Tooltip placement="bottom" title={`Logout`}>
								<LogoutOutlined
									style={{
										cursor: isLogoutLoading
											? 'not-allowed'
											: 'pointer',
										pointerEvents: isLogoutLoading
											? 'none'
											: 'auto',
										fontSize: 23,
										color: '#727272',
									}}
									onClick={logoutHandler}
								/>
							</Tooltip>
						</div>
					</Col>
				</Row>
			</header>

			{isProfileModalOpen && (
				<UserProfileModal
					isProfileModalOpen={isProfileModalOpen}
					handleCancel={profileCancel}
				/>
			)}

			{isNotificationModalOpen && (
				<NotificationModal
					isNotificationModalOpen={isNotificationModalOpen}
					handleCancel={() => {
						setNotificationModalOpen(false);
					}}
				/>
			)}
		</>
	);
};

export default Header;
