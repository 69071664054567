import { postApi, getApi } from 'apis';
const createPayPeriod = async (data: any) => {
	return await postApi('/payPeriod/create', data);
};

const getLatestPayPeriod = async () => {
	return await getApi('/payPeriod/latest');
};

const getPayPeriodHistory = async (params: any) => {
	return await getApi(`/payPeriod/history`, params);
};

export const payPeriodApi = {
	createPayPeriod,
	getLatestPayPeriod,
	getPayPeriodHistory,
};
