export const currencyData: { [key: string]: string } = {
	AED: 'United Arab Emirates Dirham',
	AFN: 'Afghan Afghani',
	ALL: 'Albanian Lek',
	AMD: 'Armenian Dram',
	ANG: 'Netherlands Antillean Guilder',
	AOA: 'Angolan Kwanza',
	ARS: 'Argentine Peso',
	AUD: 'Australian Dollar',
	AWG: 'Aruban Florin',
	AZN: 'Azerbaijani Manat',
	BAM: 'Bosnia-Herzegovina Convertible Mark',
	BBD: 'Barbadian Dollar',
	BDT: 'Bangladeshi Taka',
	BGN: 'Bulgarian Lev',
	BHD: 'Bahraini Dinar',
	BIF: 'Burundian Franc',
	BMD: 'Bermudan Dollar',
	BND: 'Brunei Dollar',
	BOB: 'Bolivian Boliviano',
	BRL: 'Brazilian Real',
	BSD: 'Bahamian Dollar',
	BTC: 'Bitcoin',
	BTN: 'Bhutanese Ngultrum',
	BWP: 'Botswanan Pula',
	BYN: 'Belarusian Ruble',
	BZD: 'Belize Dollar',
	CAD: 'Canadian Dollar',
	CDF: 'Congolese Franc',
	CHF: 'Swiss Franc',
	CLF: 'Chilean Unit of Account (UF)',
	CLP: 'Chilean Peso',
	CNH: 'Chinese Yuan (Offshore)',
	CNY: 'Chinese Yuan',
	COP: 'Colombian Peso',
	CRC: 'Costa Rican Colón',
	CUC: 'Cuban Convertible Peso',
	CUP: 'Cuban Peso',
	CVE: 'Cape Verdean Escudo',
	CZK: 'Czech Republic Koruna',
	DJF: 'Djiboutian Franc',
	DKK: 'Danish Krone',
	DOP: 'Dominican Peso',
	DZD: 'Algerian Dinar',
	EGP: 'Egyptian Pound',
	ERN: 'Eritrean Nakfa',
	ETB: 'Ethiopian Birr',
	EUR: 'Euro',
	FJD: 'Fijian Dollar',
	FKP: 'Falkland Islands Pound',
	GBP: 'British Pound Sterling',
	GEL: 'Georgian Lari',
	GGP: 'Guernsey Pound',
	GHS: 'Ghanaian Cedi',
	GIP: 'Gibraltar Pound',
	GMD: 'Gambian Dalasi',
	GNF: 'Guinean Franc',
	GTQ: 'Guatemalan Quetzal',
	GYD: 'Guyanaese Dollar',
	HKD: 'Hong Kong Dollar',
	HNL: 'Honduran Lempira',
	HRK: 'Croatian Kuna',
	HTG: 'Haitian Gourde',
	HUF: 'Hungarian Forint',
	IDR: 'Indonesian Rupiah',
	ILS: 'Israeli New Sheqel',
	IMP: 'Manx pound',
	INR: 'Indian Rupee',
	IQD: 'Iraqi Dinar',
	IRR: 'Iranian Rial',
	ISK: 'Icelandic Króna',
	JEP: 'Jersey Pound',
	JMD: 'Jamaican Dollar',
	JOD: 'Jordanian Dinar',
	JPY: 'Japanese Yen',
	KES: 'Kenyan Shilling',
	KGS: 'Kyrgystani Som',
	KHR: 'Cambodian Riel',
	KMF: 'Comorian Franc',
	KPW: 'North Korean Won',
	KRW: 'South Korean Won',
	KWD: 'Kuwaiti Dinar',
	KYD: 'Cayman Islands Dollar',
	KZT: 'Kazakhstani Tenge',
	LAK: 'Laotian Kip',
	LBP: 'Lebanese Pound',
	LKR: 'Sri Lankan Rupee',
	LRD: 'Liberian Dollar',
	LSL: 'Lesotho Loti',
	LYD: 'Libyan Dinar',
	MAD: 'Moroccan Dirham',
	MDL: 'Moldovan Leu',
	MGA: 'Malagasy Ariary',
	MKD: 'Macedonian Denar',
	MMK: 'Myanma Kyat',
	MNT: 'Mongolian Tugrik',
	MOP: 'Macanese Pataca',
	MRU: 'Mauritanian Ouguiya',
	MUR: 'Mauritian Rupee',
	MVR: 'Maldivian Rufiyaa',
	MWK: 'Malawian Kwacha',
	MXN: 'Mexican Peso',
	MYR: 'Malaysian Ringgit',
	MZN: 'Mozambican Metical',
	NAD: 'Namibian Dollar',
	NGN: 'Nigerian Naira',
	NIO: 'Nicaraguan Córdoba',
	NOK: 'Norwegian Krone',
	NPR: 'Nepalese Rupee',
	NZD: 'New Zealand Dollar',
	OMR: 'Omani Rial',
	PAB: 'Panamanian Balboa',
	PEN: 'Peruvian Nuevo Sol',
	PGK: 'Papua New Guinean Kina',
	PHP: 'Philippine Peso',
	PKR: 'Pakistani Rupee',
	PLN: 'Polish Zloty',
	PYG: 'Paraguayan Guarani',
	QAR: 'Qatari Rial',
	RON: 'Romanian Leu',
	RSD: 'Serbian Dinar',
	RUB: 'Russian Ruble',
	RWF: 'Rwandan Franc',
	SAR: 'Saudi Riyal',
	SBD: 'Solomon Islands Dollar',
	SCR: 'Seychellois Rupee',
	SDG: 'Sudanese Pound',
	SEK: 'Swedish Krona',
	SGD: 'Singapore Dollar',
	SHP: 'Saint Helena Pound',
	SLL: 'Sierra Leonean Leone',
	SOS: 'Somali Shilling',
	SRD: 'Surinamese Dollar',
	SSP: 'South Sudanese Pound',
	STD: 'São Tomé and Príncipe Dobra (pre-2018)',
	STN: 'São Tomé and Príncipe Dobra',
	SVC: 'Salvadoran Colón',
	SYP: 'Syrian Pound',
	SZL: 'Swazi Lilangeni',
	THB: 'Thai Baht',
	TJS: 'Tajikistani Somoni',
	TMT: 'Turkmenistani Manat',
	TND: 'Tunisian Dinar',
	TOP: "Tongan Pa'anga",
	TRY: 'Turkish Lira',
	TTD: 'Trinidad and Tobago Dollar',
	TWD: 'New Taiwan Dollar',
	TZS: 'Tanzanian Shilling',
	UAH: 'Ukrainian Hryvnia',
	UGX: 'Ugandan Shilling',
	USD: 'United States Dollar',
	UYU: 'Uruguayan Peso',
	UZS: 'Uzbekistan Som',
	VEF: 'Venezuelan Bolívar Fuerte (Old)',
	VES: 'Venezuelan Bolívar Soberano',
	VND: 'Vietnamese Dong',
	VUV: 'Vanuatu Vatu',
	WST: 'Samoan Tala',
	XAF: 'CFA Franc BEAC',
	XAG: 'Silver Ounce',
	XAU: 'Gold Ounce',
	XCD: 'East Caribbean Dollar',
	XDR: 'Special Drawing Rights',
	XOF: 'CFA Franc BCEAO',
	XPD: 'Palladium Ounce',
	XPF: 'CFP Franc',
	XPT: 'Platinum Ounce',
	YER: 'Yemeni Rial',
	ZAR: 'South African Rand',
	ZMW: 'Zambian Kwacha',
	ZWL: 'Zimbabwean Dollar',
};

// export const currencySymbols: { [key: string]: string } = {
// 	USD: '$',
// 	EUR: '€',
// 	INR: '₹',
// 	ZMW: 'ZK',
// };
export const currencySymbols: { [key: string]: string } = {
	AED: 'د.إ', // United Arab Emirates Dirham
	AFN: '؋', // Afghan Afghani
	ALL: 'L', // Albanian Lek
	AMD: '֏', // Armenian Dram
	ANG: 'ƒ', // Netherlands Antillean Guilder
	AOA: 'Kz', // Angolan Kwanza
	ARS: '$', // Argentine Peso
	AUD: 'A$', // Australian Dollar
	AWG: 'ƒ', // Aruban Florin
	AZN: '₼', // Azerbaijani Manat
	BAM: 'KM', // Bosnia-Herzegovina Convertible Mark
	BBD: 'Bds$', // Barbadian Dollar
	BDT: '৳', // Bangladeshi Taka
	BGN: 'лв', // Bulgarian Lev
	BHD: '.د.ب', // Bahraini Dinar
	BIF: 'FBu', // Burundian Franc
	BMD: 'BD$', // Bermudan Dollar
	BND: 'B$', // Brunei Dollar
	BOB: 'Bs.', // Bolivian Boliviano
	BRL: 'R$', // Brazilian Real
	BSD: 'B$', // Bahamian Dollar
	BTC: '₿', // Bitcoin
	BTN: 'Nu.', // Bhutanese Ngultrum
	BWP: 'P', // Botswanan Pula
	BYN: 'Br', // Belarusian Ruble
	BZD: 'BZ$', // Belize Dollar
	CAD: 'C$', // Canadian Dollar
	CDF: 'FC', // Congolese Franc
	CHF: 'CHF', // Swiss Franc
	CLF: 'UF', // Chilean Unit of Account (UF)
	CLP: 'CLP$', // Chilean Peso
	CNH: '¥', // Chinese Yuan (Offshore)
	CNY: '¥', // Chinese Yuan
	COP: 'COL$', // Colombian Peso
	CRC: '₡', // Costa Rican Colón
	CUC: '$', // Cuban Convertible Peso
	CUP: '₱', // Cuban Peso
	CVE: 'Esc', // Cape Verdean Escudo
	CZK: 'Kč', // Czech Republic Koruna
	DJF: 'Fdj', // Djiboutian Franc
	DKK: 'kr', // Danish Krone
	DOP: 'RD$', // Dominican Peso
	DZD: 'دج', // Algerian Dinar
	EGP: '£', // Egyptian Pound
	ERN: 'Nfk', // Eritrean Nakfa
	ETB: 'Br', // Ethiopian Birr
	EUR: '€', // Euro
	FJD: 'FJ$', // Fijian Dollar
	FKP: '£', // Falkland Islands Pound
	GBP: '£', // British Pound Sterling
	GEL: '₾', // Georgian Lari
	GGP: '£', // Guernsey Pound
	GHS: 'GH₵', // Ghanaian Cedi
	GIP: '£', // Gibraltar Pound
	GMD: 'D', // Gambian Dalasi
	GNF: 'FG', // Guinean Franc
	GTQ: 'Q', // Guatemalan Quetzal
	GYD: 'G$', // Guyanaese Dollar
	HKD: 'HK$', // Hong Kong Dollar
	HNL: 'L', // Honduran Lempira
	HRK: 'kn', // Croatian Kuna
	HTG: 'G', // Haitian Gourde
	HUF: 'Ft', // Hungarian Forint
	IDR: 'Rp', // Indonesian Rupiah
	ILS: '₪', // Israeli New Sheqel
	IMP: '£', // Manx pound
	INR: '₹', // Indian Rupee
	IQD: 'ع.د', // Iraqi Dinar
	IRR: '﷼', // Iranian Rial
	ISK: 'kr', // Icelandic Króna
	JEP: '£', // Jersey Pound
	JMD: 'J$', // Jamaican Dollar
	JOD: 'د.ا', // Jordanian Dinar
	JPY: '¥', // Japanese Yen
	KES: 'KSh', // Kenyan Shilling
	KGS: 'лв', // Kyrgystani Som
	KHR: '៛', // Cambodian Riel
	KMF: 'CF', // Comorian Franc
	KPW: '₩', // North Korean Won
	KRW: '₩', // South Korean Won
	KWD: 'د.ك', // Kuwaiti Dinar
	KYD: 'CI$', // Cayman Islands Dollar
	KZT: '₸', // Kazakhstani Tenge
	LAK: '₭', // Laotian Kip
	LBP: 'ل.ل', // Lebanese Pound
	LKR: 'Rs', // Sri Lankan Rupee
	LRD: 'L$', // Liberian Dollar
	LSL: 'M', // Lesotho Loti
	LYD: 'ل.د', // Libyan Dinar
	MAD: 'MAD', // Moroccan Dirham
	MDL: 'L', // Moldovan Leu
	MGA: 'Ar', // Malagasy Ariary
	MKD: 'ден', // Macedonian Denar
	MMK: 'Ks', // Myanma Kyat
	MNT: '₮', // Mongolian Tugrik
	MOP: 'MOP$', // Macanese Pataca
	MRU: 'UM', // Mauritanian Ouguiya
	MUR: '₨', // Mauritian Rupee
	MVR: 'Rf', // Maldivian Rufiyaa
	MWK: 'MK', // Malawian Kwacha
	MXN: 'Mex$', // Mexican Peso
	MYR: 'RM', // Malaysian Ringgit
	MZN: 'MT', // Mozambican Metical
	NAD: 'N$', // Namibian Dollar
	NGN: '₦', // Nigerian Naira
	NIO: 'C$', // Nicaraguan Córdoba
	NOK: 'kr', // Norwegian Krone
	NPR: '₨', // Nepalese Rupee
	NZD: 'NZ$', // New Zealand Dollar
	OMR: 'ر.ع.', // Omani Rial
	PAB: 'B/.', // Panamanian Balboa
	PEN: 'S/.', // Peruvian Nuevo Sol
	PGK: 'K', // Papua New Guinean Kina
	PHP: '₱', // Philippine Peso
	PKR: '₨', // Pakistani Rupee
	PLN: 'zł', // Polish Zloty
	PYG: '₲', // Paraguayan Guarani
	QAR: '﷼', // Qatari Rial
	RON: 'lei', // Romanian Leu
	RSD: 'дин', // Serbian Dinar
	RUB: '₽', // Russian Ruble
	RWF: 'RF', // Rwandan Franc
	SAR: '﷼', // Saudi Riyal
	SBD: 'SI$', // Solomon Islands Dollar
	SCR: '₨', // Seychellois Rupee
	SDG: 'ج.س.', // Sudanese Pound
	SEK: 'kr', // Swedish Krona
	SGD: 'S$', // Singapore Dollar
	SHP: '£', // Saint Helena Pound
	SLL: 'Le', // Sierra Leonean Leone
	SOS: 'S', // Somali Shilling
	SRD: 'SR$', // Surinamese Dollar
	SSP: '£', // South Sudanese Pound
	STD: 'Db', // São Tomé and Príncipe Dobra (pre-2018)
	STN: 'Db', // São Tomé and Príncipe Dobra
	SVC: '$', // Salvadoran Colón
	SYP: '£', // Syrian Pound
	SZL: 'E', // Swazi Lilangeni
	THB: '฿', // Thai Baht
	TJS: 'ЅМ', // Tajikistani Somoni
	TMT: 'T', // Turkmenistani Manat
	TND: 'د.ت', // Tunisian Dinar
	TOP: 'T$', // Tongan Pa'anga
	TRY: '₺', // Turkish Lira
	TTD: 'TT$', // Trinidad and Tobago Dollar
	TWD: 'NT$', // New Taiwan Dollar
	TZS: 'TSh', // Tanzanian Shilling
	UAH: '₴', // Ukrainian Hryvnia
	UGX: 'USh', // Ugandan Shilling
	USD: '$', // United States Dollar
	UYU: '$U', // Uruguayan Peso
	UZS: 'лв', // Uzbekistan Som
	VEF: 'Bs.F', // Venezuelan Bolívar Fuerte (Old)
	VES: 'Bs.S', // Venezuelan Bolívar Soberano
	VND: '₫', // Vietnamese Dong
	VUV: 'VT', // Vanuatu Vatu
	WST: 'WS$', // Samoan Tala
	XAF: 'FCFA', // CFA Franc BEAC
	XAG: 'XAG', // Silver Ounce
	XAU: 'XAU', // Gold Ounce
	XCD: 'EC$', // East Caribbean Dollar
	XDR: 'XDR', // Special Drawing Rights
	XOF: 'CFA', // CFA Franc BCEAO
	XPD: 'XPD', // Palladium Ounce
	XPF: 'CFP', // CFP Franc
	XPT: 'XPT', // Platinum Ounce
	YER: '﷼', // Yemeni Rial
	ZAR: 'R', // South African Rand
	ZMW: 'ZK', // Zambian Kwacha
	ZWL: 'Z$', // Zimbabwean Dollar
};



export const countryPhoneCodes: { [key: string]: string } = {
	US: '+1',
	AF: '+93',
	UK: '+44',
	IN: '+91',
	ZM: '+260',
	AX: '+358',
	AL: '+355',
	DZ: '+213',
	CA: '+1', // Canada
	AU: '+61', // Australia
	CN: '+86', // China
	DE: '+49', // Germany
	FR: '+33', // France
	IT: '+39', // Italy
	JP: '+81', // Japan
	MX: '+52', // Mexico
	BR: '+55', // Brazil
	RU: '+7', // Russia
	SA: '+966', // Saudi Arabia
	ZA: '+27', // South Africa
	KR: '+82', // South Korea
	NG: '+234', // Nigeria
	AE: '+971', // United Arab Emirates
	GB: '+44', // United Kingdom (alternate code for UK)
	ES: '+34', // Spain
	NL: '+31', // Netherlands
	SE: '+46', // Sweden
	CH: '+41', // Switzerland
	NZ: '+64', // New Zealand
	SG: '+65', // Singapore
	MY: '+60', // Malaysia
	TH: '+66', // Thailand
	ID: '+62', // Indonesia
	PH: '+63', // Philippines
	PK: '+92', // Pakistan
	BD: '+880', // Bangladesh
	VN: '+84', // Vietnam
	EG: '+20', // Egypt
	KE: '+254', // Kenya
	AR: '+54', // Argentina
	CL: '+56', // Chile
	CO: '+57', // Colombia
	PE: '+51', // Peru
	VE: '+58', // Venezuela
	TR: '+90', // Turkey
	IR: '+98', // Iran
	IQ: '+964', // Iraq
	IL: '+972', // Israel
	NO: '+47', // Norway
	FI: '+358', // Finland
	DK: '+45', // Denmark
	AT: '+43', // Austria
	BE: '+32', // Belgium
	PL: '+48', // Poland
	CZ: '+420', // Czech Republic
	GR: '+30', // Greece
	PT: '+351', // Portugal
};