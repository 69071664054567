import { Button, Select, Space } from 'antd';
import DatePickerField from 'components/Global/DatePicker';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import moment from 'moment';
import { useState } from 'react';
import { getApiExcel, getApiPDF } from 'apis';

type Props = {
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	options: any;
	handleDatePicker: any;
	date: any;
	employeeCount: number;
	dropdownOptionLoading: boolean;
};

const EmployeeHeader = (props: Props) => {
	const {
		statusFilterValue,
		handleStatusFilter,
		options,
		date,
		handleDatePicker,
		employeeCount,
		dropdownOptionLoading,
	} = props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				{options?.length ? (
					<Space>
						<span style={{ fontSize: '1.5rem', color: 'gray' }}>
							{' '}
							Supervisor{' '}
						</span>
						<Select
							className={styles['employee-header-item']}
							value={statusFilterValue}
							options={options}
							onChange={(value) => {
								handleStatusFilter(value);
							}}
							size="large"
							placeholder="Select Status"
							style={{ width: ' 170px' }}
							loading={dropdownOptionLoading}
							disabled={dropdownOptionLoading}
						/>
					</Space>
				) : null}
				<Space
					style={{
						fontSize: '1.5rem',
						color: 'gray',
						marginLeft: '20px',
					}}
				>
					<span>
						{' '}
						Number of Employees: <br />
						<span style={{ color: 'black' }}>
							{employeeCount < 9
								? `0${employeeCount}`
								: employeeCount}
						</span>
					</span>
				</Space>
			</Space>
			<Space>
				{/* <Button
					type="primary"
					size='large'
					onClick={exportPdf}
					loading={isExportLoading}
					style={{ marginRight: '16px' }}
				>
					Export PDF
				</Button> */}
				<DatePickerField
					name={'date'}
					value={date}
					required={false}
					isError={false}
					onChange={handleDatePicker}
					picker="month"
				/>
			</Space>
		</div>
	);
};

export default EmployeeHeader;
