import { createSlice } from '@reduxjs/toolkit';
import { fetchDepartmentAction } from '../actions/departmentAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const DepartmentSlice = createSlice({
	name: 'department',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDepartmentAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchDepartmentAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(
			fetchDepartmentAction.rejected,
			(state, action: any) => {
				state.isLoading = false;
				state.error = action.payload;
			}
		);
	},
});

export default DepartmentSlice;
