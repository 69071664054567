import { Table, Typography } from 'antd';
import { FC, useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { PermissionDetailsSvg, SortSvgBottom, SortSvgTop } from 'utils/svgs';
import { checkPermission } from 'utils/utils';
import SearchAndFilter from '../SearchAndFilter/index';
import styles from './index.module.scss';
import './index.scss';
import { DynamicTableProps } from './types';
const { Text } = Typography;

const DynamicTable: FC<DynamicTableProps> = (props) => {
	const { Column } = Table;
	const {
		roleDataSource,
		performSearchHandler,
		performFilterHandler,
		searchValue,
		filterValue,
		setDrawerInfoHandler,
		openPermissionsHandler,
		fetchRolePermissions,
		paginationChangeHandler,
		totalRecords,
		currentPage,
		performSortHandler,
		PageSize,
		modifyPageSize,
		sortDirection,
		isLoading,
	} = props;

	const context = useContext(PermissionContext);

	// Handle permissions action
	const permissionsHandler = () => {
		openPermissionsHandler();
		setDrawerInfoHandler('Permission Details');
	};

	const isEditPermission = checkPermission(context.permissions, {
		permissionName: 'Roles',
		permission: ['edit'],
	});

	// For handle the table change click
	const tableChangeHandler = (d1: any, d2: any, data: any) => {
		performSortHandler && performSortHandler(data.order, d1.current);
	};

	// JSX
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={roleDataSource}
				pagination={false}
				loading={isLoading}
				className="table-global"
				onChange={tableChangeHandler}
			>
				<Column
					showSorterTooltip={{ title: '' }}
					defaultSortOrder={sortDirection}
					title={() => {
						return <>Role Name</>;
					}}
					dataIndex="roleName"
					key="roleName"
					width={'15%'}
					sortDirections={['ascend', 'descend', 'ascend']}
					className="bg-white"
				/>
				<Column
					title="Description"
					dataIndex="roleDescription"
					key="roleDescription"
					width={'25%'}
					className="bg-white"
				/>
				<Column
					title="Permissions"
					dataIndex="permissions"
					key="permissions"
					className="bg-white"
					width={'20%'}
					render={(_, data: any) => (
						<>
							{!isEditPermission || data.isAdmin ? (
								<div
									className={
										styles['dynamic-table__no-permissions']
									}
									onClick={() => fetchRolePermissions!(data)}
								>
									<PermissionDetailsSvg />
									<Text
										underline
										className={`${styles['dynamic-table__no-permissions--margin-left']}`}
									>
										Permissions Details
									</Text>
								</div>
							) : (
								<div
									onClick={() => fetchRolePermissions!(data)}
								>
									<PermissionDetailsSvg />
									<Text
										underline
										onClick={permissionsHandler}
										className={
											styles['dynamic-table__permissions']
										}
									>
										Permissions Details
									</Text>
								</div>
							)}
						</>
					)}
				/>
			</Table>
		</div>
	);
};

export default DynamicTable;
