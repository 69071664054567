import { PermissionContext } from 'components/Global/AuthLayout';
import RoleTable from 'components/Settings/Role';
import { useContext } from 'react';

export const Roles = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Roles');

	return <div>{check && <RoleTable />}</div>;
};
