import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import ReportPopOver from '../../ReportPopOver';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type tableProps = {
	openUploadRecordModal: (payeRecordId: string) => void;
	calculatePayeCurrentPage: number;
	calculatePayePageSize: number;
	calculatePayeTotalRecords: number;
	calculatePayeIsLoading: boolean;
	calculatePayeData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const TableComponent = (props: tableProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const {
		openUploadRecordModal,
		calculatePayeCurrentPage,
		calculatePayePageSize,
		calculatePayeTotalRecords,
		calculatePayeIsLoading,
		calculatePayeData,
		tableChangeHandler,
	} = props;

	return (
		<Table
			dataSource={calculatePayeData}
			rowKey={(record: any) => record.id}
			loading={calculatePayeIsLoading}
			pagination={{
				total: calculatePayeTotalRecords,
				current: calculatePayeCurrentPage,
				pageSize: calculatePayePageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
			/>
			<Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				width={'10%'}
			/>
			<Column title="Month" dataIndex="month" key="month" width={'10%'} />

			<Column title="Year" dataIndex="year" key="year" width={'10%'} />

			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				render={(text: any) => text}
				width={'12%'}
			/>
			<Column
				title="PAYE Reports"
				dataIndex="payeReport"
				key="payeReport"
				width={'15%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.payeReport}
						reportType="PAYE"
					/>
				)}
			/>
			<Column
				title="PAYE Records"
				dataIndex=""
				key="docName"
				width={'28%'}
				render={(_: any, record: any) => (
					<div
						style={{
							display: 'flex',
							gap: '2rem',
							alignItems: 'center',
						}}
					>
						{isAdd && (
							<Buttons
								buttons={[
									{
										text: 'Upload',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled: false,
										onclick: () => {
											openUploadRecordModal(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						)}
						{record.recordUrl ? (
							<a
								href={record.recordUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Ellipse
									message={record.recordName}
									maxLength={20}
									key={record.recordName}
									tooltipMessage={record.recordName}
									isTooltip={true}
								/>
							</a>
						) : (
							<></>
						)}
					</div>
				)}
			/>
		</Table>
	);
};

export default TableComponent;
